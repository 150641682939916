/* This example requires Tailwind CSS v2.0+ */
import { XIcon } from '@heroicons/react/outline';
import { useLayout } from '../../../context/layout.context';
import HeaderBannerContract from '../../../service/contracts/banner/header-banner.contract';

export interface HeaderBannerProps {
  banner: HeaderBannerContract | undefined;
}

const HeaderBanner: React.FC<HeaderBannerProps> = (props) => {
  const { resetBanner } = useLayout();

  if (!props.banner) return <span />;

  return (
    <div className="bg-elbwalker">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2 rounded-lg bg-elbwalker-800">
              {props.banner.icon}
            </span>
            <p className="ml-3 font-medium text-white truncate">
              <span className="inline">{props.banner.text}</span>
            </p>
          </div>
          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <button
              type="button"
              className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-elbwalker-600 bg-white hover:bg-gray-50"
              onClick={props.banner.buttonOnClick}
            >
              {props.banner.buttonLabel}
            </button>
          </div>
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              type="button"
              className="-mr-1 flex p-2 rounded-md hover:bg-elbwalker focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              onClick={() => resetBanner()}
            >
              <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

HeaderBanner.defaultProps = {};

export default HeaderBanner;
