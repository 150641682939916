import { useState } from 'react';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthContextProps, useAuth } from '../../context/auth.context';
import { Paths } from '../../../routes';
import LoginProcess from '../templates/auth-layout.component';
import Alert from '../molecules/alert.component';
import ButtonPrimary from '../atoms/buttons/button-primary.component';
import FormLabeledInput from '../molecules/forms/form-labeled-input.component';

export interface PasswordResetProps {
  className?: string;
}

export const PasswordReset: React.FC<PasswordResetProps> = () => {
  const history = useHistory();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const { resetPassword } = useAuth() as AuthContextProps;

  const [email, changeEmail] = useState<string>('');

  const handleKeyDown = async (e: any) => {
    if (e.keyCode === 13 || e?.key === 'Enter') {
      await handleResetPassword();
    }
  };

  async function handleResetPassword() {
    if (!email) return setError('Email required');

    try {
      setError('');
      setLoading(true);
      await resetPassword(email);
      history.push(Paths.password.reset_confirmation);
    } catch {
      setError('Failed to reset password');
      setLoading(false);
    }
  }

  const renderContent = () => {
    return (
      <div>
        <div className="mb-6">
          <div className="relative">
            <div className="relative flex justify-center text-xl font-medium">
              <span className="px-2 bg-white text-gray-700">
                Forgot your password?
              </span>
            </div>
            <div className="relative flex justify-center text-xl font-medium">
              <span className="px-2 bg-white text-gray-700">
                Please enter your email ...
              </span>
            </div>
          </div>
        </div>

        <div className="space-y-6">
          <div>
            <FormLabeledInput
              id={'email'}
              type={'text'}
              title={'Email'}
              placeholder={'you@yourcompany.com'}
              value={email}
              required={true}
              onChange={(value) => changeEmail(value)}
              onKeyDown={async (e: any) => await handleKeyDown(e)}
              loading={false}
            />
          </div>
          <div>
            <ButtonPrimary
              label="Reset Password"
              disabled={loading}
              loading={loading}
              wide={true}
              textSize="text-base"
              onClick={async () => await handleResetPassword()}
            ></ButtonPrimary>
          </div>
          {error && (
            <Alert errorMessage={error} onClick={() => setError('')}></Alert>
          )}
        </div>

        <div className="absolute inset-x-0 bottom-0 mt-6 py-8">
          <p className="mt-2 text-center text-sm text-gray-600 max-w">
            Don&apos;t have an account yet?&nbsp;
            <Link
              to={Paths.signup}
              className="ml-1 font-medium text-elbwalker hover:text-elbwalker-600 focus:outline-none focus:underline transition ease-in-out duration-150"
            >
              Sign up
            </Link>
          </p>
        </div>
      </div>
    );
  };

  return <LoginProcess content={renderContent()}></LoginProcess>;
};

PasswordReset.defaultProps = {};

export default PasswordReset;
