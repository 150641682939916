import * as React from 'react';
import LabelInput from '../../atoms/labels/label-input.component';
import SkeletonRectangle from '../../atoms/skeletons/skeleton-rectangle.component';
import AutoCompletion from '../dropdown/autocompletion.component';

export interface FormLabeledAutocompletionProps {
  id: string;
  title: string;
  value: string | undefined;
  placeholder?: string;
  required: boolean;
  onChange: (value: string) => void;
  loading: boolean;
  values: string[];
  className?: string;
  disabled?: boolean;
}

export const FormLabeledAutocompletion: React.FC<FormLabeledAutocompletionProps> =
  ({ loading = false, ...props }) => {
    return (
      <div className={`${props.className ? props.className : ''}`}>
        {loading ? (
          <SkeletonRectangle height="5" width="1/6" />
        ) : (
          <LabelInput id={props.id} value={props.title} />
        )}

        <div className="mt-1">
          {loading ? (
            <SkeletonRectangle height="10" width="full" />
          ) : (
            <AutoCompletion
              id={props.id}
              values={props.values}
              placeholder={props.placeholder}
              onChange={props.onChange}
              value={props.value}
              disabled={props.disabled}
            />
          )}
        </div>
      </div>
    );
  };

export default FormLabeledAutocompletion;
