import * as React from 'react';
import UserContract from '../../../user/service/contracts/user.contract';
import DestinationRow from '../molecules/tables/destinations/destination-row.component';
import Layout from '../../../core/components/templates/layout.component';
import {
  BrowseProjectDestinationsContract,
  ProjectDestination,
  ProjectDestinationEventMapping,
  ProjectDestinationTypes,
} from '../../service/contracts/project-destination.contract';
import ProjectDestinationsRepository from '../../service/api/project-destinations.repository';
import SkeletonRectangle from '../../../core/components/atoms/skeletons/skeleton-rectangle.component';
import { useHistory, useParams } from 'react-router-dom';
import { useLayout } from '../../../core/context/layout.context';
import CollectDestinationModal from '../organisms/modals/destinations/collect-destination-modal.component';
import ButtonPrimary from '../../../core/components/atoms/buttons/button-primary.component';
import { DocumentAddIcon } from '@heroicons/react/outline';
import { PlusIcon } from '@heroicons/react/solid';
import { Paths } from '../../../routes';
import { routeTo } from '../../../core/service/route/route.service';
import SimpleModal from '../organisms/modals/simple-modal.component';
import GoogleTagmanagerDestinationModal from '../organisms/modals/destinations/google_tagmanager-destination-modal.component';
import GoogleUADestinationModal from '../organisms/modals/destinations/google_ua-destination-modal.component';
import GoogleGA4DestinationModal from '../organisms/modals/destinations/google_ga4-destination-modal.component';
import MatomoDestinationModal from '../organisms/modals/destinations/matomo-destination-modal.component';
import FacebookPixelDestinationModal from '../organisms/modals/destinations/facebook_pixel-destination-modal.component';
import { ProjectEventsContract } from '../../service/contracts/event.contract';

export interface ProjectDestinationsProps {
  currentUser: UserContract;
  projectId: string;
  className?: string;
  loadingDestinations: boolean;
}

export interface ProjectDestinationModalProps {
  projectId: string;
  destination: ProjectDestination;
  events?: ProjectEventsContract;
  onEventClick: (
    mapping: ProjectDestinationEventMapping,
    target: EventTarget & HTMLInputElement,
  ) => ProjectDestinationEventMapping;
  onCancelClick: () => void;
  onSaveClick: (
    config: ProjectDestination['config'],
    activate?: boolean,
  ) => void;
  loadingPrimary: boolean;
  loadingSecondary: boolean;
}

const ProjectDestinations: React.FC<ProjectDestinationsProps> = (props) => {
  const history = useHistory();
  const {
    handleError,
    handleSuccess,
    handleProjectId,
    projectEvents,
    projectDestinations,
    changeDestinations,
  } = useLayout();
  const { projectId } = useParams<{ projectId: string }>();
  const [loadingDestinations, setLoadingDestinations] = React.useState(
    props.loadingDestinations,
  );
  const [selectedDestination, setSelectedDestination] = React.useState<
    string | false
  >(false);
  const [loadingDeleteDestination, setLoadingDeleteDestination] =
    React.useState(false);
  const [loadingPrimary, setLoadingPrimary] = React.useState(false);
  const [loadingSecondary, setLoadingSecondary] = React.useState(false);
  const [confirmDeleteDestination, setConfirmDeleteDestination] =
    React.useState<string | false>(false);

  const editDestination = async (
    projectId: string,
    destinationId: string,
    destination: Partial<ProjectDestination>,
  ) => {
    handleError('');

    await ProjectDestinationsRepository.edit(
      projectId,
      destinationId,
      destination,
    )
      .then((response) => {
        changeDestinations(response);
        handleSuccess('Your changes have been saved');
      })
      .catch(() => {
        handleError('Failed to change status');
      })
      .finally(() => {
        setLoadingPrimary(false);
        setLoadingSecondary(false);
      });
  };

  const deleteDestination = async (
    projectId: string,
    destinationId: string,
  ) => {
    handleError('');
    setLoadingDeleteDestination(true);

    await ProjectDestinationsRepository.delete(projectId, destinationId)
      .then((response) => {
        changeDestinations(response);
        handleSuccess('Destination removed successfully');
      })
      .catch(() => {
        handleError('Failed to delete destination');
      })
      .finally(() => {
        setConfirmDeleteDestination(false);
        setLoadingDeleteDestination(false);
      });
  };

  React.useEffect(() => {
    if (!projectEvents || !projectDestinations)
      return setLoadingDestinations(true);

    changeDestinations(projectDestinations);
    setLoadingDestinations(false);
  }, [projectEvents, projectDestinations]);

  React.useEffect(() => {
    handleProjectId(projectId);
  }, []);

  async function onSaveClick(
    destinationId: string,
    config: ProjectDestination['config'],
    activate?: boolean,
  ) {
    const destination: Partial<ProjectDestination> = { config };
    if (activate) {
      destination.active = true;
      setLoadingPrimary(true);
    } else {
      setLoadingSecondary(true);
    }

    await editDestination(projectId, destinationId, destination);
    setSelectedDestination(false);
  }

  function onEventClick(
    mapping: ProjectDestinationEventMapping,
    event: EventTarget & HTMLInputElement,
  ): ProjectDestinationEventMapping {
    const [entity, action] = event.name.split('_');
    const activate = event.checked;

    if (activate) {
      if (!mapping[entity]) mapping[entity] = {};
      mapping[entity][action] = activate;
    } else {
      if (!mapping[entity]) return mapping;
      delete mapping[entity][action];
      if (!Object.keys(mapping[entity]).length) delete mapping[entity];
    }

    return mapping;
  }

  function renderCollectModal(
    destinationId: string,
    destination: ProjectDestination,
  ) {
    return (
      <CollectDestinationModal
        projectId={projectId}
        destination={destination}
        events={projectEvents}
        onEventClick={onEventClick}
        onSaveClick={(config, activate) =>
          onSaveClick(destinationId, config, activate)
        }
        onCancelClick={() => setSelectedDestination(false)}
        loadingPrimary={loadingPrimary}
        loadingSecondary={loadingSecondary}
      />
    );
  }

  function renderFacebookPixelModal(
    destinationId: string,
    destination: ProjectDestination,
  ) {
    return (
      <FacebookPixelDestinationModal
        projectId={projectId}
        destination={destination}
        events={projectEvents}
        onEventClick={onEventClick}
        onSaveClick={(config, activate) =>
          onSaveClick(destinationId, config, activate)
        }
        onCancelClick={() => setSelectedDestination(false)}
        loadingPrimary={loadingPrimary}
        loadingSecondary={loadingSecondary}
      />
    );
  }

  function renderGoogleTagmanagerModal(
    destinationId: string,
    destination: ProjectDestination,
  ) {
    return (
      <GoogleTagmanagerDestinationModal
        projectId={projectId}
        destination={destination}
        events={projectEvents}
        onEventClick={onEventClick}
        onSaveClick={(config, activate) =>
          onSaveClick(destinationId, config, activate)
        }
        onCancelClick={() => setSelectedDestination(false)}
        loadingPrimary={loadingPrimary}
        loadingSecondary={loadingSecondary}
      />
    );
  }

  function renderGoogleUAModal(
    destinationId: string,
    destination: ProjectDestination,
  ) {
    return (
      <GoogleUADestinationModal
        projectId={projectId}
        destination={destination}
        events={projectEvents}
        onEventClick={onEventClick}
        onSaveClick={(config, activate) =>
          onSaveClick(destinationId, config, activate)
        }
        onCancelClick={() => setSelectedDestination(false)}
        loadingPrimary={loadingPrimary}
        loadingSecondary={loadingSecondary}
      />
    );
  }

  function renderGoogleGA4Modal(
    destinationId: string,
    destination: ProjectDestination,
  ) {
    return (
      <GoogleGA4DestinationModal
        projectId={projectId}
        destination={destination}
        events={projectEvents}
        onEventClick={onEventClick}
        onSaveClick={(config, activate) =>
          onSaveClick(destinationId, config, activate)
        }
        onCancelClick={() => setSelectedDestination(false)}
        loadingPrimary={loadingPrimary}
        loadingSecondary={loadingSecondary}
      />
    );
  }

  function renderMatomoModal(
    destinationId: string,
    destination: ProjectDestination,
  ) {
    return (
      <MatomoDestinationModal
        projectId={projectId}
        destination={destination}
        events={projectEvents}
        onEventClick={onEventClick}
        onSaveClick={(config, activate) =>
          onSaveClick(destinationId, config, activate)
        }
        onCancelClick={() => setSelectedDestination(false)}
        loadingPrimary={loadingPrimary}
        loadingSecondary={loadingSecondary}
      />
    );
  }

  function renderDestinationModal(destinationId: string | false) {
    if (!destinationId || !projectDestinations) return undefined;

    const destination = projectDestinations[destinationId];
    if (!destination) return undefined;

    switch (destination.type) {
      case ProjectDestinationTypes.Collect:
        return renderCollectModal(destinationId, destination);
      case ProjectDestinationTypes.FacebookPixel:
        return renderFacebookPixelModal(destinationId, destination);
      case ProjectDestinationTypes.GoogleTagManager:
        return renderGoogleTagmanagerModal(destinationId, destination);
      case ProjectDestinationTypes.GoogleUA:
        return renderGoogleUAModal(destinationId, destination);
      case ProjectDestinationTypes.GoogleGA4:
        return renderGoogleGA4Modal(destinationId, destination);
      case ProjectDestinationTypes.Matomo:
        return renderMatomoModal(destinationId, destination);
      default:
        return undefined;
    }
  }

  const renderDeleteDestination = () => {
    if (!confirmDeleteDestination) return undefined;

    return (
      <SimpleModal
        title={'Delete destination'}
        text={'Are you sure you want to delete this destination?'}
        ButtonPrimaryLabel={'Delete'}
        red={true}
        ButtonSecondaryLabel={'Cancel'}
        loading={loadingDeleteDestination}
        ButtonPrimaryOnClick={() => {
          deleteDestination(projectId, confirmDeleteDestination);
        }}
        ButtonSecondaryOnClick={() => {
          setConfirmDeleteDestination(false);
        }}
      ></SimpleModal>
    );
  };

  const renderDestinations = (
    destinations?: BrowseProjectDestinationsContract,
  ) => {
    if (!destinations) return null;

    const rows = Object.entries(destinations);

    if (!rows.length) {
      return (
        <tr>
          <td colSpan={7} className="px-6 py-20">
            <div className="text-center">
              <DocumentAddIcon className="mx-auto h-12 w-12 text-gray-400"></DocumentAddIcon>
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No destinations
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Get started by creating a new destination.
              </p>
              <div className="mt-6">
                <ButtonPrimary
                  label="New destination"
                  loading={false}
                  margin="ml-3"
                  className="h-10.5"
                  icon={renderPlusIcon()}
                  onClick={() =>
                    history.push(
                      routeTo(Paths.projects.destinations.create, {
                        projectId: projectId,
                      }),
                    )
                  }
                />
              </div>
            </div>
          </td>
        </tr>
      );
    }

    return Object.entries(destinations)
      .sort()
      .map(([destinationId, destination]) => {
        return (
          <DestinationRow
            onEdit={() => setSelectedDestination(destinationId)}
            onDelete={() => setConfirmDeleteDestination(destinationId)}
            key={destinationId}
            currentUser={props.currentUser}
            projectId={projectId}
            destinationId={destinationId}
            destination={destination}
            onToggleActive={() => {
              editDestination(projectId, destinationId, {
                active: !destination.active,
              });
            }}
          />
        );
      });
  };

  const renderPlusIcon = () => {
    return (
      <PlusIcon className={'h-4.5 w-4.5 align-middle items-center mr-2'} />
    );
  };

  const content = (
    <div>
      {renderDeleteDestination()}
      <div className="flex flex-row-reverse mb-3">
        <ButtonPrimary
          label="New destination"
          loading={false}
          margin="ml-3"
          className="h-10.5"
          icon={renderPlusIcon()}
          onClick={() =>
            history.push(
              routeTo(Paths.projects.destinations.create, {
                projectId: projectId,
              }),
            )
          }
        />
      </div>
      <div className="flex flex-col">
        <div className="-my-2 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow border-b border-gray-200 sm:rounded-lg">
              <table className="rounded-lg bg-white table-fixed w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="w-6/20 px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider rounded-tl-lg"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="w-3/20 px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Category
                    </th>
                    <th
                      scope="col"
                      className="w-16/100 px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Step-by-step guide
                    </th>
                    <th
                      scope="col"
                      className="w-3/20 px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Mode
                    </th>
                    <th
                      scope="col"
                      className="w-3/20 px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th className="w-9/100 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider rounded-tr-lg">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {loadingDestinations ? (
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <SkeletonRectangle width="full" />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <SkeletonRectangle width="2/3" />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <SkeletonRectangle width="1/4" />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <SkeletonRectangle width="1/2" />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <SkeletonRectangle width="1/4" />
                      </td>
                      <td />
                    </tr>
                  ) : (
                    renderDestinations(projectDestinations)
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {renderDestinationModal(selectedDestination)}
    </div>
  );

  return (
    <Layout title="Destinations" currentUser={props.currentUser}>
      {content}
    </Layout>
  );
};

ProjectDestinations.defaultProps = {};

ProjectDestinations.defaultProps = {
  loadingDestinations: true,
};

export default ProjectDestinations;
