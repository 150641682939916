import * as React from 'react';
import { useState } from 'react';
import { classNames } from '../../../../core/service/css/class-names.service';
import { getIcon } from '../../../../core/service/icons/icon.service';
import { ProjectDestinationTypes } from '../../../service/contracts/project-destination.contract';

export interface ProjectCreationDetailsProps {
  initialValue: ProjectDestinationTypes | false;
  onChange: (type: ProjectDestinationTypes | false) => void;
  onCanBeCompleted: (canBeCompleted: boolean) => void;
  onRequestCompletion: () => void;
}

const DestinationCreationType: React.FC<ProjectCreationDetailsProps> = (
  props,
) => {
  const [selectedDestinationType, setSelectedDestinationType] = useState<
    ProjectDestinationTypes | false
  >(props.initialValue);

  React.useEffect(() => {
    props.onChange(selectedDestinationType);
    props.onCanBeCompleted(validate());
  }, [selectedDestinationType]);

  function validate() {
    return !!selectedDestinationType;
  }

  const destinations = [
    {
      type: ProjectDestinationTypes.Collect,
      name: 'Google BigQuery (managed)',
      badge: 'Server',
    },
    {
      type: ProjectDestinationTypes.GoogleGA4,
      name: 'Google Analytics 4',
      badge: 'Web',
    },
    {
      type: ProjectDestinationTypes.GoogleUA,
      name: 'Google Universal Analytics',
      badge: 'Web',
    },
    {
      type: ProjectDestinationTypes.Matomo,
      name: 'Matomo',
      badge: 'Web',
    },
    {
      type: ProjectDestinationTypes.FacebookPixel,
      name: 'Facebook Pixel',
      badge: 'Web',
    },
    {
      type: ProjectDestinationTypes.GoogleTagManager,
      name: 'Google Tag Manager',
      badge: 'Web',
    },
  ];

  const renderButtons = () => {
    return destinations.map((destination) => {
      const bg =
        destination.type === selectedDestinationType
          ? 'border-2 border-elbwalker'
          : 'border-2 border-gray-100 hover:border-gray-200';

      return (
        <button
          type="button"
          key={destination.type}
          onClick={() => {
            setSelectedDestinationType(destination.type);
          }}
          className={classNames(
            'relative rounded-lg px-6 py-5 shadow-sm flex items-stretch space-x-3 cursor-pointer',
            bg,
          )}
        >
          <div className="min-w-full flex flex-col items-start">
            <div className="min-w-full mb-3 flex-grow-0">
              <div className="flex justify-center">
                <img
                  className="h-14 w-14"
                  src={getIcon(destination.type || '')}
                  alt=""
                />
              </div>
            </div>

            <div className="w-full mb-4 flex-grow flex items-center">
              <div className="w-full flex justify-center">
                <div className="text-sm truncate">{destination.name}</div>
              </div>
            </div>

            <div className="min-w-full flex-grow-0">
              <div className="flex justify-center">
                <span
                  className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-semibold ${
                    destination.badge == 'Server'
                      ? 'bg-table-blue-2 text-elbwalker'
                      : 'bg-gray-100 text-gray-800'
                  }`}
                >
                  {destination.badge}
                </span>
              </div>
            </div>
          </div>
        </button>
      );
    });
  };

  return (
    <div>
      <form>
        <div>
          <div>
            <div className="mt-8">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-4">
                {renderButtons()}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

DestinationCreationType.defaultProps = {};

export default DestinationCreationType;
