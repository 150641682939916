import {
  ExclamationIcon,
  CodeIcon,
  DocumentAddIcon,
} from '@heroicons/react/outline';
import * as React from 'react';
import ButtonPrimary from '../../../../core/components/atoms/buttons/button-primary.component';
import ButtonSecondary from '../../../../core/components/atoms/buttons/button-secondary.component';

export interface SimpleModalProps {
  title: string;
  text: string | JSX.Element;
  loading: boolean;
  ButtonPrimaryLabel?: string;
  ButtonSecondaryLabel?: string;
  ButtonPrimaryOnClick?: any;
  ButtonSecondaryOnClick?: any;
  red: boolean;
  icon?: string;
  wide?: boolean;
}

const SimpleModal: React.FC<SimpleModalProps> = (props) => {
  const icon = (icon: string, red: boolean) => {
    switch (icon) {
      case 'ExclamationIcon':
        return (
          <ExclamationIcon
            className={`h-6 w-6 ${red ? 'text-red-600' : 'text-elbwalker'}`}
          />
        );
      case 'CodeIcon':
        return (
          <CodeIcon
            className={`h-6 w-6 ${red ? 'text-red-600' : 'text-elbwalker'}`}
          />
        );
      case 'DocumentAdd':
        return (
          <DocumentAddIcon
            className={`h-6 w-6 ${red ? 'text-red-600' : 'text-elbwalker'}`}
          />
        );
    }
  };

  return (
    <div>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </div>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          ></span>

          <div
            className={`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${
              props.wide ? 'sm:max-w-2xl' : 'sm:max-w-lg'
            } sm:w-full sm:p-6`}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="sm:flex sm:items-start">
              <div
                className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${
                  props.red ? 'bg-red-100' : 'bg-table-blue-1'
                } sm:mx-0 sm:h-10 sm:w-10`}
              >
                {props.icon
                  ? icon(props.icon, props.red)
                  : icon('ExclamationIcon', props.red)}
              </div>
              <div className="mt-3 flex-grow text-center sm:mt-0 sm:ml-4 sm:text-left pt-2">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {props.title}
                </h3>
                <div className="mt-4">
                  <p className="text-sm text-gray-500">{props.text}</p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              {props.ButtonPrimaryLabel && props.ButtonPrimaryOnClick ? (
                <ButtonPrimary
                  label={props.ButtonPrimaryLabel}
                  onClick={props.ButtonPrimaryOnClick}
                  red={props.red}
                  margin="ml-3"
                  loading={props.loading}
                ></ButtonPrimary>
              ) : (
                ''
              )}
              {/* @TODO: add function */}
              {props.ButtonSecondaryLabel && props.ButtonSecondaryOnClick ? (
                <ButtonSecondary
                  label={props.ButtonSecondaryLabel}
                  onClick={props.ButtonSecondaryOnClick}
                ></ButtonSecondary>
              ) : (
                ''
              )}
              {/* @TODO: add function */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SimpleModal.defaultProps = {};

export default SimpleModal;
