import * as React from 'react';
import { useParams } from 'react-router-dom';
import LayoutContainer from '../../../core/components/templates/layout-container.component';
import { Paths } from '../../../routes';
import ButtonPrimary from '../../../core/components/atoms/buttons/button-primary.component';
import ButtonSecondary from '../../../core/components/atoms/buttons/button-secondary.component';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from '../../../core/components/molecules/alert.component';
import ProjectRepository from '../../service/api/project.repository';
import WalkerSnippet from '../organisms/walker-snippet.component';
import UserContract from '../../../user/service/contracts/user.contract';
import SkeletonRectangle from '../../../core/components/atoms/skeletons/skeleton-rectangle.component';
import { routeTo } from '../../../core/service/route/route.service';

export interface ProjectSettingsProps {
  currentUser: UserContract;
  name: string;
  domain: string;
  projectId: string;
  loadingProjects: boolean;
  loadingChanges: boolean;
}

const ProjectSettings: React.FC<ProjectSettingsProps> = (props) => {
  const { projectId } = useParams<{ projectId: string }>();
  const history = useHistory();
  const [error, setError] = useState('');
  const [loadingChanges, setLoadingChanges] = useState(props.loadingChanges);

  const [name, changeName] = useState<string>(props.name);
  const [domain, changeDomain] = useState<string>(props.domain);

  const [loadingProjects, setLoadingProjects] = useState(props.loadingProjects);

  const prefillData = async (projectId: string) => {
    setError('');
    setLoadingProjects(true);
    await ProjectRepository.read(projectId)
      .then((response) => {
        changeName(response.name);
        changeDomain(response.domain);
      })
      .catch(() => {
        setError('Failed to load current project data');
        // TODO Differentiation between error messages.
      })
      .finally(() => {
        setLoadingProjects(false);
      });
  };

  useEffect(() => {
    // weird useEffect ft. async pattern...
    async function init() {
      await prefillData(projectId);
    }
    init();
  }, [projectId]);

  const handleProjectSettings = async () => {
    setError('');

    if (!name) return setError('Enter a project name');
    if (!domain) return setError('Enter a project domain');

    setLoadingChanges(true);

    await ProjectRepository.edit(projectId, { name, domain })
      .then(() => {
        history.push(routeTo(Paths.home, {}));
      })
      .catch(() => {
        setError('Failed to edit project');
      })
      .finally(() => {
        setLoadingChanges(false);
      });
  };

  const handleKeyDown = async (e: any) => {
    if (e.keyCode === 13 || e?.key === 'Enter') {
      await handleProjectSettings();
    }
  };

  const renderContent = () => {
    return (
      <form>
        <div>
          <div>
            <div className="mt-8 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                {loadingProjects ? (
                  <SkeletonRectangle width={'1/12'} height={'5.25'} />
                ) : (
                  <label
                    htmlFor="projectName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name
                  </label>
                )}

                <div className="mt-1">
                  {loadingProjects ? (
                    <SkeletonRectangle width={'full'} height={'10.5'} />
                  ) : (
                    <input
                      id="projectName"
                      name="projectName"
                      type="text"
                      placeholder="e.g. name of your website"
                      value={name}
                      onChange={(event) => changeName(event.target.value)}
                      onKeyDown={async (e: any) => await handleKeyDown(e)}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-elbwalker focus:border-elbwalker sm:text-sm"
                    />
                  )}
                </div>
              </div>
              <div className="sm:col-span-6">
                {loadingProjects ? (
                  <SkeletonRectangle width={'1/12'} height={'5.25'} />
                ) : (
                  <label
                    htmlFor="domain"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Domain
                  </label>
                )}

                {loadingProjects ? (
                  <SkeletonRectangle
                    width={'full'}
                    height={'10.5'}
                    className={'mt-1'}
                  />
                ) : (
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                      https://
                    </span>
                    <input
                      id="domain"
                      name="domain"
                      type="url"
                      placeholder="yourshop.com"
                      value={domain}
                      onChange={(event) => changeDomain(event.target.value)}
                      onKeyDown={async (e: any) => await handleKeyDown(e)}
                      required
                      className="appearance-none px-3 py-2 shadow-sm flex-1 border focus:ring-elbwalker focus:border-elbwalker block w-full min-w-0 rounded-none rounded-r-md sm:text-sm focus:outline-none placeholder-gray-400 border-gray-300"
                    />
                  </div>
                )}
              </div>
              <div className="sm:col-span-6">
                {loadingProjects ? (
                  <SkeletonRectangle width={'1/6'} height={'5.25'} />
                ) : (
                  <label className="block text-sm font-medium text-gray-700">
                    Your elbwalker script
                  </label>
                )}

                {loadingProjects ? (
                  <SkeletonRectangle
                    width={'full'}
                    height={'13.5'}
                    className={'mt-1'}
                  />
                ) : (
                  <WalkerSnippet projectId={projectId} />
                )}

                {loadingProjects ? (
                  <SkeletonRectangle
                    width={'1/2'}
                    height={'5.25'}
                    className={'mt-4'}
                  />
                ) : (
                  <p className="mt-4 text-sm text-gray-600">
                    Use our walker to track custom events within seconds. Go
                    to&nbsp;
                    <a
                      href={Paths.docs.home}
                      className="text-sm text-gray-600 underline"
                    >
                      documentation
                    </a>
                    .
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        {error && (
          <div className="pt-5">
            <Alert errorMessage={error} onClick={() => setError('')} />
          </div>
        )}
        <div className="pt-5">
          <div className="flex justify-end">
            <ButtonSecondary
              label={'Cancel'}
              onClick={() => history.push(routeTo(Paths.home, {}))}
            />
            <ButtonPrimary
              label={'Save changes'}
              loading={loadingChanges}
              disabled={loadingChanges || loadingProjects}
              onClick={() => handleProjectSettings()}
              className={'ml-3'}
            />
          </div>
        </div>
      </form>
    );
  };

  return (
    <LayoutContainer title="Project settings" currentUser={props.currentUser}>
      {renderContent()}
    </LayoutContainer>
  );
};

ProjectSettings.defaultProps = { loadingProjects: true, loadingChanges: false };

export default ProjectSettings;
