import axios from 'axios';
import API from '../../../core/service/api/api.service';
import {
  BrowseProjectEventContract,
  ProjectEvent,
  ProjectEventLite,
} from '../contracts/event.contract';

export default class ProjectEventsRepository {
  public static async browse(
    projectId: string,
  ): Promise<BrowseProjectEventContract> {
    return (await axios.get(`${API.baseUrl}/projects/${projectId}/events`))
      .data;
  }

  public static async edit(
    projectId: string,
    event: ProjectEventLite,
  ): Promise<BrowseProjectEventContract> {
    return (
      await axios.put(
        `${API.baseUrl}/projects/${projectId}/events/${event.entity}/${event.action}`,
        event,
      )
    ).data;
  }

  public static async add(
    projectId: string,
    event: ProjectEventLite,
  ): Promise<BrowseProjectEventContract> {
    return (
      await axios.post(
        `${API.baseUrl}/projects/${projectId}/events/${event.entity}/${event.action}`,
        event,
      )
    ).data;
  }

  public static async delete(
    projectId: string,
    event: ProjectEvent,
  ): Promise<BrowseProjectEventContract> {
    return (
      await axios.delete(
        `${API.baseUrl}/projects/${projectId}/events/${event.entity}/${event.action}`,
      )
    ).data;
  }
}
