import * as React from 'react';
import UserContract from '../../../user/service/contracts/user.contract';
import { classNames } from '../../service/css/class-names.service';
import Layout from './layout.component';

export interface LayoutContainerProps {
  currentUser: UserContract;
  title: string;
  className?: string;
}

const LayoutContainer: React.FC<LayoutContainerProps> = (props) => {
  return (
    <Layout title={props.title} currentUser={props.currentUser}>
      <div className="flex flex-col justify-center">
        <div className="sm:mx-auto sm:w-full">
          <div
            className={classNames(
              'bg-white py-8 px-4 sm:rounded-lg sm:px-10',
              props.className,
            )}
          >
            {props.children}
          </div>
        </div>
      </div>
    </Layout>
  );
};

LayoutContainer.defaultProps = {};

export default LayoutContainer;
