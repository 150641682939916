import * as React from 'react';
import AlertSuccess from '../components/molecules/alerts/alert-success.component';
import AlertError from '../components/molecules/alerts/alert-error.component';
import HeaderBanner from '../components/molecules/alerts/header-banner.component';
import HeaderBannerContract from '../service/contracts/banner/header-banner.contract';
import { BrowseProjectEventContract } from '../../project/service/contracts/event.contract';
import ProjectEventsRepository from '../../project/service/api/project-events.repository';
import { BrowseProjectDestinationsContract } from '../../project/service/contracts/project-destination.contract';
import ProjectDestinationsRepository from '../../project/service/api/project-destinations.repository';

const LayoutContext = React.createContext<LayoutContextProps>(
  {} as LayoutContextProps,
);

export interface LayoutContextProps {
  handleError: (text?: string) => void;
  handleSuccess: (text?: string) => void;
  resetAlerts: () => void;
  handleBanner: (banner: HeaderBannerContract) => void;
  resetBanner: () => void;
  handleProjectId: (projectId: string) => void;
  projectEvents: BrowseProjectEventContract | undefined;
  changeEvents: (events: BrowseProjectEventContract) => void;
  projectDestinations: BrowseProjectDestinationsContract | undefined;
  changeDestinations: (destinations: BrowseProjectDestinationsContract) => void;
}

export function useLayout() {
  return React.useContext(LayoutContext);
}

interface LayoutProviderProps {
  children: React.ReactNode;
}

export function LayoutProvider({ children }: LayoutProviderProps) {
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');
  const [banner, setBanner] = React.useState<HeaderBannerContract | undefined>(
    undefined,
  );

  const [projectId, setProjectId] = React.useState<string | undefined>('');

  const [projectEvents, changeEvents] = React.useState<
    BrowseProjectEventContract | undefined
  >(undefined);

  const [projectDestinations, changeDestinations] = React.useState<
    BrowseProjectDestinationsContract | undefined
  >(undefined);

  function handleError(text?: string) {
    setError(text || '');
  }

  function handleSuccess(text?: string) {
    setSuccess(text || '');
  }

  function resetAlerts() {
    handleError();
    handleSuccess();
  }

  function handleBanner(banner: HeaderBannerContract) {
    setBanner(banner);
  }

  function resetBanner() {
    setBanner(undefined);
  }

  function handleProjectId(newProjectId?: string) {
    if (newProjectId === projectId) return;

    setProjectId(newProjectId);
  }

  const loadEvents = async (projectId: string) => {
    await ProjectEventsRepository.browse(projectId)
      .then((response) => {
        changeEvents(response);
      })
      .catch(() => {
        handleError('Failed to load events');
        // TODO Differentiation between error messages.
      });
  };

  const loadDestinations = async (projectId: string) => {
    await ProjectDestinationsRepository.browse(projectId)
      .then((response) => {
        changeDestinations(response);
      })
      .catch(() => {
        handleError('Failed to load projects');
        // TODO Differentiation between error messages.
      });
  };

  React.useEffect(() => {
    if (!projectId) return;

    changeEvents(undefined);
    changeDestinations(undefined);

    // weird useEffect ft. async pattern...
    async function init(projectId: string) {
      handleError('');
      await Promise.all([loadEvents(projectId), loadDestinations(projectId)]);
    }
    init(projectId);
  }, [projectId]);

  const value = {
    handleError,
    handleSuccess,
    resetAlerts,
    handleBanner,
    resetBanner,
    handleProjectId,
    projectEvents,
    changeEvents,
    projectDestinations,
    changeDestinations,
  };

  return (
    <LayoutContext.Provider value={value}>
      {banner && <HeaderBanner banner={banner} />}
      {children}
      {success && <AlertSuccess text={success} />}
      {error && <AlertError text={error} />}
    </LayoutContext.Provider>
  );
}
