import * as React from 'react';
import LoginProcess from '../templates/auth-layout.component';

export interface PasswordResetConfirmationProps {
  className?: string;
}

const PasswordResetConfirmation: React.FC<PasswordResetConfirmationProps> = () => {
  const renderContent = () => {
    return (
      <div>
        <div className="mb-6">
          <div className="relative">
            <div className="relative text-center flex justify-center text-xl font-medium">
              <span className="px-2 bg-white text-gray-700">
                Please check your email inbox to reset your password ...
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <LoginProcess content={renderContent()}></LoginProcess>;
};

PasswordResetConfirmation.defaultProps = {};

export default PasswordResetConfirmation;
