import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Layout from '../../../core/components/templates/layout.component';
import Filters from '../../../core/service/util/filters';
import DashboardRepository from '../../service/api/dashboard.repository';
import { DashboardContract } from '../../service/contracts/dashboard.contract';
import {
  DimensionFilter,
  StatsFilterContract,
} from '../../service/contracts/stats/filters/stats.filter.contract';
import WidgetRenderContract from '../../service/contracts/widget-render.contract';
import WidgetContract from '../../service/contracts/widget.contract';
import HighlightTable from '../molecules/highlight-table/highlight-table.component';
import TabbedLineChart from '../organisms/tabbed-line-chart.component';
import DatePicker from '../molecules/date-picker.component';
import DateFilterContract from '../../service/contracts/stats/filters/date.filter.contract';
import { useEffect, useState } from 'react';
import UserContract from '../../../user/service/contracts/user.contract';
import { useLayout } from '../../../core/context/layout.context';
import { SpeakerphoneIcon } from '@heroicons/react/outline';
import { routeTo } from '../../../core/service/route/route.service';
import { Paths } from '../../../routes';
import {
  BrowseProjectDestinationsContract,
  ProjectDestination,
  ProjectDestinationTypes,
} from '../../../project/service/contracts/project-destination.contract';

export interface DashboardProps {
  currentUser: UserContract;
  className?: string;
  selectedDateFilter: DateFilterContract;
  selectedDimensionFilters: StatsFilterContract;
  id: any;
}

export interface WidgetRegistryContract {
  [key: string]: React.FC<WidgetRenderContract>;
}

const widgetRegistry: WidgetRegistryContract = {
  'tabbed-line-chart': TabbedLineChart,
  'highlight-table': HighlightTable,
};

const Dashboard: React.FC<DashboardProps> = (props) => {
  const history = useHistory();
  const { handleError, handleBanner, projectDestinations } = useLayout();
  const [dashboard, changeDashboard] = useState<DashboardContract | null>(null);
  const [selectedDateFilter, selectDateFilter] = useState<DateFilterContract>({
    date_from: '',
    date_to: '',
  });
  const [selectedDimensionFilters, changeDimensionFilters] =
    useState<StatsFilterContract>({});

  const { projectId } = useParams<{
    projectId: string;
    dashboardId: string;
  }>();

  const showActivateBigQueryBanner = (
    projectDestinations: BrowseProjectDestinationsContract,
  ) => {
    const destinationsTypes = Array.from(
      Object.values(projectDestinations),
      (projectDestination: ProjectDestination) => projectDestination.type,
    );

    const positionCollect = destinationsTypes.indexOf(
      ProjectDestinationTypes.Collect,
    );

    if (positionCollect != -1) return;

    handleBanner({
      text: 'To see data in the dashboard please activate the Google BigQuery Data Warehouse destination.',
      buttonLabel: 'Activate destination',
      buttonOnClick: () =>
        history.push(
          routeTo(Paths.projects.destinations.create, { projectId }),
        ),
      icon: (
        <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
      ),
    });
  };

  const fetchDashboard = async () => {
    handleError('');

    try {
      changeDashboard(DashboardRepository.read());
    } catch (error) {
      handleError(
        'Oops an error occurred. Please reload the page and try again.”',
      );
    }
  };

  useEffect(() => {
    if (!projectDestinations) return;
    showActivateBigQueryBanner(projectDestinations);
  }, [projectDestinations]);

  useEffect(() => {
    // weird useEffect ft. async pattern...
    async function init() {
      await fetchDashboard();
    }
    init();
  }, [selectedDateFilter]);

  const getSQLDate = (d: Date) => {
    return d.getFullYear() + '-' + (1 + d.getMonth()) + '-' + d.getDate();
  };

  const onChangedDate = (from: Date, to: Date) => {
    selectDateFilter({
      date_from: getSQLDate(from),
      date_to: getSQLDate(to),
    });
  };

  const addDimensionFilter = (filter: DimensionFilter) => {
    const dimensionFilters = selectedDimensionFilters;
    dimensionFilters[filter.dimension] = filter.title;

    changeDimensionFilters((selectedDimensionFilters) => ({
      ...selectedDimensionFilters,
      ...dimensionFilters,
    }));
  };

  const deleteDimensionFilter = (key: string) => {
    const dimensionFilters = selectedDimensionFilters;
    delete dimensionFilters[key];

    changeDimensionFilters((selectedDimensionFilters) => ({
      ...selectedDimensionFilters,
      ...dimensionFilters,
    }));
  };

  if (dashboard === null) {
    return <span></span>;
  }

  const renderWidgets = () => {
    return dashboard.widgets.map((widget: WidgetContract, index) => {
      return React.createElement<WidgetRenderContract>(
        widgetRegistry[widget.type],
        {
          projectId,
          selectedDateFilter: selectedDateFilter,
          selectedDimensionFilters: selectedDimensionFilters,
          addDimensionFilter: addDimensionFilter,
          id: widget.id,
          type: widget.type,
          key: index,
          width: widget.width,
          config: widget.config,
          question: widget.question,
          title: widget.title,
          content: widget.content,
        },
      );
    });
  };

  const renderDimensionFilters = () => {
    return Object.keys(selectedDimensionFilters).map(
      (key: string, index: number) => {
        const value = selectedDimensionFilters[key];
        return (
          <span
            key={index}
            className="inline-flex items-center mr-3 px-3 py-3 rounded-full text-xs font-medium leading-4 bg-table-blue-2 text-elbwalker"
          >
            {Filters.revertAttributeName(key)}:
            <span className="ml-1 mr-2 font-semibold">
              {Filters.revertAttributeName(value)}
            </span>
            <button
              type="button"
              className="flex-shrink-0 ml-1.5 inline-flex text-elbwalker focus:outline-none focus:text-table-blue-2"
              aria-label="Remove small badge"
              onClick={() => deleteDimensionFilter(key)}
            >
              <svg
                className="h-2 w-2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 8 8"
              >
                <path
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  d="M1 1l6 6m0-6L1 7"
                />
              </svg>
            </button>
          </span>
        );
      },
    );
  };

  const content = (
    <div className={props.className}>
      {/* active filters */}
      <div className="mb-4">{renderDimensionFilters()}</div>

      {/* grid */}
      <div className="w-full grid grid-cols-4 gap-10">{renderWidgets()}</div>
    </div>
  );

  return (
    <Layout
      title={dashboard.name}
      currentUser={props.currentUser}
      titleAddition={
        <div>
          <DatePicker onChangedDate={onChangedDate} />
        </div>
      }
    >
      {content}
    </Layout>
  );
};

Dashboard.defaultProps = {};

export default Dashboard;
