import { Role } from '../../../core/service/contracts/auth.contract';
import { ProjectDestinations } from './project-destination.contract';

export interface ProjectContract {
  id: string;
  name: string;
  domain: string;
  timezone: string;
  status: ProjectStatus;
  members: Record<string, Role>;
  destinations: ProjectDestinations;
  tagging: string;
}

export type BrowseProjectMembersContract = ProjectMember[];

export interface ProjectMember {
  id: string;
  name: string;
  email: string;
  openInvite: boolean;
}

export interface ProjectLiteContract {
  id: string;
  name: string;
  domain: string;
}

export type BrowseProjectContract = ProjectLiteContract[];

export interface AddProjectContract {
  name: string;
  domain: string;
  industry: ProjectIndustryType;
}

export interface EditProjectContract {
  name?: string;
  domain?: string;
  tagging?: string;
}

export enum ProjectStatus {
  Active = 'active',
  Deleted = 'deleted',
}

export enum ProjectIndustryType {
  Ecom = 'ecom',
  Saas = 'saas',
  Job = 'job',
  Content = 'content',
  Other = 'other',
}
