import { ChatAltIcon } from '@heroicons/react/solid';
import { Paths } from '../../../../routes';

export interface FooterProps {
  link?: string;
}

const Footer: React.FC<FooterProps> = () => {
  return (
    <nav className="absolute w-full bottom-0 shadow-sm">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-center h-10 text-xs font-medium text-gray-500">
          <div>© 2021 elbwalker</div>
          <div className="mx-2">|</div>
          <a target="_blank" rel="noreferrer" href={Paths.terms}>
            Terms of Service
          </a>
          <div className="mx-2">|</div>
          <a target="_blank" rel="noreferrer" href={Paths.privacy}>
            Privacy Policy
          </a>
          <div className="mx-2">|</div>
          <a target="_blank" rel="noreferrer" href={Paths.docs.home}>
            Help Center
          </a>
          <div className="mx-2">|</div>
          <a
            target="_blank"
            rel="noreferrer"
            href="mailto:hello@elbwalker.com"
            className="inline-flex justify-center items-center text-xs font-medium text-elbwalker focus:outline-none"
          >
            <ChatAltIcon
              className={`h-4.5 w-4.5 align-middle items-center mr-2`}
            />
            Feedback
          </a>
        </div>
      </div>
    </nav>
  );
};

Footer.defaultProps = {};

export default Footer;
