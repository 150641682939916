import * as React from 'react';
import Alert from '../../../../core/components/molecules/alert.component';

export interface ProjectCreationDetailsProps {
  initialValue: ProjectDetails;
  onChange: (details: ProjectDetails) => void;
  onCanBeCompleted: (canBeCompleted: boolean) => void;
  onRequestCompletion: () => void;
}

export interface ProjectDetails {
  name?: string;
  domain?: string;
}

const ProjectCreationDetails: React.FC<ProjectCreationDetailsProps> = (
  props,
) => {
  const [error, setError] = React.useState<string>('');
  const [name, changeName] = React.useState<string>(
    props.initialValue.name ?? '',
  );
  const [domain, changeDomain] = React.useState<string>(
    props.initialValue.domain ?? '',
  );

  React.useEffect(() => {
    props.onChange({ name, domain });
    props.onCanBeCompleted(validationSuccessful());
  }, [name, domain]);

  // TODO Adjust Validation (e.g. URL Validation)
  function validationSuccessful() {
    return domain !== '' && name !== '';
  }

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13 || e?.key === 'Enter') {
      if (name === '') {
        return setError('Enter a project name');
      }

      if (domain === '') {
        return setError('Enter a project domain');
      }

      props.onRequestCompletion();
    }
  };

  return (
    <div>
      <form>
        <div>
          <div>
            <div className="mt-8 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label
                  htmlFor="projectName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <div className="mt-1">
                  <input
                    id="projectName"
                    name="projectName"
                    type="text"
                    placeholder="e.g. name of your website"
                    value={name}
                    onChange={(event) => changeName(event.target.value)}
                    onKeyDown={(e: any) => handleKeyDown(e)}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-elbwalker focus:border-elbwalker sm:text-sm"
                  />
                </div>
              </div>
              <div className="sm:col-span-6">
                <label
                  htmlFor="domain"
                  className="block text-sm font-medium text-gray-700"
                >
                  Domain
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                    https://
                  </span>
                  <input
                    id="domain"
                    name="domain"
                    type="text"
                    placeholder="yourshop.com"
                    value={domain}
                    onChange={(event) => changeDomain(event.target.value)}
                    onKeyDown={(e: any) => handleKeyDown(e)}
                    required
                    className="appearance-none px-3 py-2 shadow-sm flex-1 border focus:ring-elbwalker focus:border-elbwalker block w-full min-w-0 rounded-none rounded-r-md sm:text-sm focus:outline-none placeholder-gray-400 border-gray-300"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {error && (
          //TODO Weird connection between onClick and useEffect here.
          <div className="pt-5">
            <Alert errorMessage={error} />
          </div>
        )}
      </form>
    </div>
  );
};

ProjectCreationDetails.defaultProps = {};

export default ProjectCreationDetails;
