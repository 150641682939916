import { DashboardContract } from '../contracts/dashboard.contract';

export default class DashboardRepository {
  public static read(): DashboardContract {
    return {
      id: 'overview',
      name: 'Dashboard',
      widgets: [
        {
          id: 'timeseries',
          statsType: 'time-series',
          question: undefined,
          type: 'tabbed-line-chart',
          width: 4,
          config: { kpi: ['visits', 'pageviews', 'events'] },
          title: undefined,
        },
        {
          id: 'content',
          statsType: 'dimensional-facts',
          title: 'Top Content',
          config: {
            dimension: 'content',
            facta: ['visits'],
            dimension_name_column: 'id',
            direction: 'desc',
            sort: 'view',
          },
          type: 'highlight-table',
          question: 'Which pages get the most views?',
          width: 2,
        },
        {
          id: 'events',
          config: {
            dimension: 'event',
            dimension_name_column: 'entity',
            facta: ['total_events'],
            sort: 'views',
            direction: 'desc',
          },
          question: 'What are the top events?',
          type: 'highlight-table',
          width: 2,
          statsType: 'dimensional-facts',
          title: 'Top Events',
        },
        {
          id: 'referrer',
          config: {
            dimension: 'referrer',
            dimension_name_column: 'name',
            facta: ['unique-visits'],
            sort: 'views',
            direction: 'desc',
          },
          question: 'Where do users come from?',
          type: 'highlight-table',
          width: 2,
          statsType: 'dimensional-facts',
          title: 'Top Referrer',
        },
        {
          id: 'sources',
          title: 'Top Sources',
          width: 2,
          statsType: 'dimensional-facts',
          type: 'highlight-table',
          config: {
            facta: ['unique-visits'],
            direction: 'desc',
            dimension: 'source',
            dimension_name_column: 'name',
            sort: 'views',
          },
          question: 'What are your top sources (UTM)?',
        },
        {
          id: 'medium',
          type: 'highlight-table',
          title: 'Top Mediums',
          width: 2,
          config: {
            dimension: 'medium',
            direction: 'desc',
            sort: 'views',
            facta: ['unique-visits'],
            dimension_name_column: 'name',
          },
          statsType: 'dimensional-facts',
          question: 'What are your top mediums (UTM)?',
        },
        {
          id: 'campaigns',
          title: 'Top Campaigns',
          type: 'highlight-table',
          config: {
            sort: 'views',
            dimension_name_column: 'name',
            dimension: 'campaign',
            facta: ['unique-visits'],
            direction: 'desc',
          },
          question: 'What are your top campaigns (UTM)?',
          statsType: 'dimensional-facts',
          width: 2,
        },
        {
          id: 'device',
          config: {
            dimension: 'device',
            facta: ['unique-visits'],
            direction: 'desc',
            dimension_name_column: 'name',
            sort: 'views',
          },
          title: 'Top Devices',
          type: 'highlight-table',
          question: 'Whats the grouping of users by device?',
          statsType: 'dimensional-facts',
          width: 2,
        },
        {
          id: 'countries',
          title: 'Top Countries',
          statsType: 'dimensional-facts',
          question: 'Where are users visiting from?',
          type: 'highlight-table',
          width: 2,
          config: {
            dimension: 'country',
            sort: 'views',
            direction: 'desc',
            facta: ['unique-visits'],
            dimension_name_column: 'name',
          },
        },
      ],
    };
  }
}
