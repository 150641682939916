import * as React from 'react';
import { Input, InputType } from '../../atoms/inputs/input.component';
import LabelInput from '../../atoms/labels/label-input.component';
import SkeletonRectangle from '../../atoms/skeletons/skeleton-rectangle.component';

export interface FormLabeledInputProps {
  id: string;
  type: InputType;
  title: string;
  description?: string;
  value: string;
  placeholder?: string;
  autoComplete?: string;
  required: boolean;
  onChange: (value: string) => void;
  onKeyDown?: (event: any) => void;
  loading: boolean;
  className?: string;
  disabled?: boolean;
}

export const FormLabeledInput: React.FC<FormLabeledInputProps> = ({
  id = 'id',
  type = 'text',
  loading = false,
  disabled = false,
  ...props
}) => {
  return (
    <div className={`${props.className ? props.className : ''}`}>
      {loading ? (
        <SkeletonRectangle height="5" width="1/6" />
      ) : (
        <LabelInput id={id} value={props.title} />
      )}

      <div className="mt-1">
        {props.description && (
          <p className="text-sm text-gray-600 max-w">{props.description}</p>
        )}
        {loading ? (
          <SkeletonRectangle height="10" width="full" />
        ) : (
          <Input
            id={id}
            type={type}
            value={props.value}
            placeholder={props.placeholder}
            autoComplete={props.autoComplete}
            required={props.required}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

export default FormLabeledInput;
