import axios from 'axios';
import API from '../../../core/service/api/api.service';
import { BrowseProjectEventContract } from '../contracts/event.contract';
import {
  BrowseProjectEventsRecommendationContract,
  ProjectEventRecommendation,
} from '../contracts/recommendation-event.contract';

export default class ProjectEventsRecommendationRepository {
  public static async browse(
    projectId: string,
  ): Promise<BrowseProjectEventsRecommendationContract> {
    return (
      await axios.get(
        `${API.baseUrl}/projects/${projectId}/events/recommendation`,
      )
    ).data;
  }

  public static async add(
    projectId: string,
    recommendation: ProjectEventRecommendation,
  ): Promise<BrowseProjectEventContract> {
    return (
      await axios.post(
        `${API.baseUrl}/projects/${projectId}/events/recommendation/${recommendation.entity}/${recommendation.action}`,
        recommendation,
      )
    ).data;
  }
}
