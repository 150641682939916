import * as React from 'react';
import { useParams } from 'react-router-dom';
import LayoutContainer from '../../../core/components/templates/layout-container.component';
import ButtonPrimary from '../../../core/components/atoms/buttons/button-primary.component';
import { useEffect, useState } from 'react';
import UserContract from '../../../user/service/contracts/user.contract';
import SkeletonRectangle from '../../../core/components/atoms/skeletons/skeleton-rectangle.component';
import ProjectRepository from '../../service/api/project.repository';
import { useLayout } from '../../../core/context/layout.context';

export interface RemoteTaggingProps {
  currentUser: UserContract;
  projectId: string;
  tagging: string;
  loadingConfig: boolean;
  loadingChanges: boolean;
}

const RemoteTagging: React.FC<RemoteTaggingProps> = (props) => {
  const { projectId } = useParams<{ projectId: string }>();
  const { handleError, handleSuccess } = useLayout();
  const [tagging, changeTagging] = useState<string>(props.tagging);
  const [loadingConfig, setLoadingConfig] = useState(props.loadingConfig);
  const [loadingChanges, setLoadingChanges] = useState(props.loadingChanges);

  const prefillData = async (projectId: string) => {
    setLoadingConfig(true);

    await ProjectRepository.read(projectId)
      .then((response) => {
        changeTagging(response.tagging);
      })
      .catch(() => {
        handleError('Failed to load current project data');
      })
      .finally(() => {
        setLoadingConfig(false);
      });
  };

  useEffect(() => {
    // weird useEffect ft. async pattern...
    async function init() {
      await prefillData(projectId);
    }
    init();
  }, [projectId]);

  const handleCustomConfig = async () => {
    setLoadingChanges(true);

    await ProjectRepository.edit(projectId, { tagging: tagging || '' })
      .then(() => {
        handleSuccess('Tagging successfully saved');
      })
      .catch(() => {
        handleError('Failed to save remote tagging');
      })
      .finally(() => {
        setLoadingChanges(false);
      });
  };

  const renderContent = () => {
    return (
      <form>
        <div className="mt-8 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6">
            {loadingConfig ? (
              <SkeletonRectangle width={'1/6'} height={'5.25'} />
            ) : (
              <label
                htmlFor="remoteTagging"
                className="block text-sm font-medium text-gray-700"
              >
                Insert remote tagging
              </label>
            )}
            <div className="mt-1">
              {loadingConfig ? (
                <SkeletonRectangle width={'full'} height={'64'} />
              ) : (
                <textarea
                  id="remoteTagging"
                  name="remoteTagging"
                  placeholder="(function(){ })()"
                  rows={11}
                  value={tagging}
                  onChange={(event) => changeTagging(event.target.value)}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-elbwalker focus:border-elbwalker sm:text-sm"
                />
              )}
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <ButtonPrimary
              label={'Save'}
              loading={loadingChanges}
              disabled={loadingChanges || loadingConfig}
              onClick={() => handleCustomConfig()}
              className={'ml-3'}
            />
          </div>
        </div>
      </form>
    );
  };

  return (
    <LayoutContainer title="Remote tagging" currentUser={props.currentUser}>
      {renderContent()}
    </LayoutContainer>
  );
};

RemoteTagging.defaultProps = { loadingConfig: true, loadingChanges: false };

export default RemoteTagging;
