import { Meta } from '../../../core/service/contracts/meta.contract';

export enum ProjectEventTrigger {
  Click = 'click',
  Load = 'load',
  Submit = 'submit',
  Visible = 'visible',
}

export type ProjectEventType = 'text' | 'number' | 'currency';
export enum ProjectEventImplementationType {
  Auto = 'auto',
  Precision = 'precision',
  Visual = 'visual',
  Remote = 'remote',
}

export interface ProjectEventPropertySettings {
  type: ProjectEventType;
  example: string;
}
export type PartialProjectEventPropertySettings =
  Partial<ProjectEventPropertySettings>;

export interface ProjectEventProperties {
  [property: string]: ProjectEventPropertySettings;
}

export interface ProjectEventSettings extends Partial<Meta> {
  trigger: ProjectEventTrigger;
  rules?: ProjectEventRules;
  properties: ProjectEventProperties;
  implementation: ProjectEventImplementationType;
  updatedAt: number;
  createdAt: number;
}
export type PartialProjectEventSettings = Partial<ProjectEventSettings>;

// Event object as { entity: { action: { ... } }}
export interface ProjectEventsContract {
  [entity: string]: { [action: string]: ProjectEventSettings };
}

export interface ProjectEvent extends ProjectEventSettings {
  entity: string;
  action: string;
}

export interface ProjectEventProperty extends ProjectEvent {
  property?: string;
}

export interface ProjectEventLite {
  entity: string;
  action: string;
  trigger: string;
  rules?: ProjectEventRules;
}

export interface ProjectEventRules {
  selector?: string;
  allPages?: boolean;
  pagepath?: string;
}

export interface ProjectEventPropertyLite {
  entity: string;
  action: string;
  property: string;
  type: string;
  example: string;
}

export type BrowseProjectEventContract = ProjectEventsContract;
