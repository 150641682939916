import * as React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Paths } from '../../../../routes';
import logo from '../../../assets/elbwalker_logo_centred_blue_white.svg';
import { classNames } from '../../../service/css/class-names.service';
import { routeTo } from '../../../service/route/route.service';
import UserContract from '../../../../user/service/contracts/user.contract';
import ProjectMenu from './project-menu.component';
import SettingsMenu from './settings-menu.component';
import ButtonPrimary from '../../atoms/buttons/button-primary.component';
import { UserAddIcon } from '@heroicons/react/solid';

export interface HeaderProps {
  currentUser: UserContract;
}

const Header: React.FC<HeaderProps> = (props) => {
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();

  const renderProjectsLink = (projectId?: string) => {
    if (!projectId) return;

    return (
      <div className="hidden sm:ml-6 space-x-8 sm:flex">
        <Link
          to={Paths.home}
          className={classNames(
            'inline-flex items-center px-1 pt-1  text-sm font-medium leading-5 text-gray-900 focus:outline-none focus:border-elbwalker-700 transition duration-150 ease-in-out',
            'border-b-2 border-white hover:border-elbwalker',
          )}
        >
          Projects
        </Link>
      </div>
    );
  };

  const renderEventsLink = (projectId?: string) => {
    if (!projectId) return;

    return (
      <div className="hidden sm:ml-6 space-x-8 sm:flex">
        <Link
          to={routeTo(Paths.projects.events, { projectId })}
          className={classNames(
            'inline-flex items-center px-1 pt-1  text-sm font-medium leading-5 text-gray-900 focus:outline-none focus:border-elbwalker-700 transition duration-150 ease-in-out',
            'border-b-2 border-white hover:border-elbwalker',
          )}
        >
          Events
        </Link>
      </div>
    );
  };

  const renderDashboardLink = (projectId?: string) => {
    if (!projectId) return;

    return (
      <div className="hidden sm:ml-6 space-x-8 sm:flex">
        <Link
          to={routeTo(Paths.projects.dashboards.detail, {
            projectId,
            dashboardId: 'overview',
          })}
          className={classNames(
            'inline-flex items-center px-1 pt-1  text-sm font-medium leading-5 text-gray-900 focus:outline-none focus:border-elbwalker-700 transition duration-150 ease-in-out',
            'border-b-2 border-white hover:border-elbwalker',
          )}
        >
          Dashboard
        </Link>
      </div>
    );
  };

  const renderDestinationsLink = (projectId?: string) => {
    if (!projectId) return;

    return (
      <div className="hidden sm:ml-6 space-x-8 sm:flex">
        <Link
          to={routeTo(Paths.projects.destinations.overview, {
            projectId,
          })}
          className={classNames(
            'inline-flex items-center px-1 pt-1  text-sm font-medium leading-5 text-gray-900 focus:outline-none focus:border-elbwalker-700 transition duration-150 ease-in-out',
            window.location.pathname.startsWith(
              Paths.projects.destinations.overview,
            ) //@TODO find a better solution via variable
              ? 'border-b-2 border-elbwalker'
              : 'border-b-2 border-white hover:border-elbwalker',
          )}
        >
          Destinations
        </Link>
      </div>
    );
  };

  const renderAddMembersButton = (projectId: string) => {
    if (!projectId) return;
    return (
      <ButtonPrimary
        label={'Add members'}
        loading={false}
        onClick={() =>
          history.push(routeTo(Paths.projects.members, { projectId }))
        }
        icon={
          <UserAddIcon
            className={`h-4.5 w-4.5 align-middle items-center mr-2`}
          />
        }
      />
    );
  };

  const renderProjectButton = (
    currentUser: UserContract,
    projectId: string,
  ) => {
    if (!projectId) {
      return (
        <ButtonPrimary
          label={'Create new project'}
          loading={false}
          onClick={() => history.push(Paths.projects.create)}
        />
      );
    } else {
      return <ProjectMenu currentUser={currentUser} projectId={projectId} />;
    }
  };

  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <Link to={Paths.home}>
                <img
                  className="block lg:block h-8 w-auto"
                  src={logo}
                  alt="elbwalker Logo"
                />
              </Link>
            </div>
            {renderProjectsLink(projectId)}
            {renderEventsLink(projectId)}
            {renderDestinationsLink(projectId)}
            {renderDashboardLink(projectId)}
          </div>

          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            {renderAddMembersButton(projectId)}
            {/* {renderProjectSettingsButton(projectId)} */}
            {renderProjectButton(props.currentUser, projectId)}
            <SettingsMenu currentUser={props.currentUser} />
          </div>
          <div className="-mr-2 flex items-center sm:hidden">
            <button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
              <svg
                className="block h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>

              <svg
                className="hidden h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

Header.defaultProps = {};

export default Header;
