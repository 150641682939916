import * as React from 'react';
import Alert from '../../atoms/alerts/alert.component';
import { XCircleIcon } from '@heroicons/react/solid';

export interface AlertErrorProps {
  text: string;
  delay?: number;
}

const AlertError: React.FC<AlertErrorProps> = (props) => {
  return (
    <Alert
      text={props.text}
      delay={props.delay}
      backgroundColor="bg-red-50"
      textColor="text-red-800"
      buttonColor="bg-red-50 text-red-600 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600"
      icon={<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
    />
  );
};

AlertError.defaultProps = {};

export default AlertError;
