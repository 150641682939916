import * as React from 'react';

export interface StepsProps {
  currentStep: number;
  stepCount: number;
  onCompletedStepSelected?: (step: number) => void;
  className?: string;
}

interface Step {
  status: StepsStatus;
  stepIndex: number;
  name: string;
  href: string;
}

type StepsStatus = 'completed' | 'current' | 'upcoming';

/**
 * Steps (Bullets)
 * @link https://tailwindui.com/components/application-ui/navigation/steps)
 */
const Steps: React.FC<StepsProps> = (props) => {
  const steps: Step[] = [];
  let stepIndex: number;
  for (stepIndex = 0; stepIndex < props.stepCount; stepIndex++) {
    let status: StepsStatus;

    if (stepIndex === props.currentStep) {
      status = 'current';
    } else if (stepIndex >= props.currentStep) {
      status = 'upcoming';
    } else {
      status = 'completed';
    }

    steps.push({
      status,
      stepIndex,
      name: `Step ${stepIndex + 1}`,
      href: '#',
    });
  }

  return (
    <nav
      className={`flex items-center justify-center align-middle h-full`}
      aria-label="Progress"
    >
      <ol className="ml-8 flex items-center space-x-5">
        {steps.map((step) => (
          <li key={step.name}>
            {step.status === 'completed' ? (
              <a
                href={step.href}
                onClick={() => props.onCompletedStepSelected?.(step.stepIndex)}
                className="block w-3 h-3 bg-elbwalker-600 rounded-full hover:bg-elbwalker"
              >
                <span className="sr-only">{step.name}</span>
              </a>
            ) : step.status === 'current' ? (
              <a
                className="relative flex items-center justify-center"
                aria-current="step"
              >
                <span className="absolute w-5 h-5 p-px flex" aria-hidden="true">
                  <span className="w-full h-full rounded-full bg-elbwalker" />
                </span>
                <span
                  className="relative block w-3 h-3 bg-elbwalker rounded-full"
                  aria-hidden="true"
                />
                <span className="sr-only">{step.name}</span>
              </a>
            ) : (
              <a className="block w-3 h-3 bg-gray-200 rounded-full">
                <span className="sr-only">{step.name}</span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

Steps.defaultProps = {};

export default Steps;
