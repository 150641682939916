import * as React from 'react';

export interface DestinationCreationDetailsProps {
  initialValue: string;
  onChange: (name: string) => void;
  onCanBeCompleted: (canBeCompleted: boolean) => void;
  onStepsCompleted: () => Promise<void>;
}

const DestinationCreationName: React.FC<DestinationCreationDetailsProps> = (
  props,
) => {
  const [name, setDestinationName] = React.useState<string>(props.initialValue);

  React.useEffect(() => {
    props.onChange(name);
    props.onCanBeCompleted(validate());
  }, [name]);

  function validate() {
    return name !== '';
  }

  async function onComplete() {
    // Prevent unmounted state update
    await props.onStepsCompleted();
  }

  const handleKeyDown = async (e: any) => {
    if (e.keyCode === 13 || e?.key === 'Enter') {
      e.preventDefault();
      if (name === '') return;

      await onComplete();
    }
  };

  return (
    <div>
      <form>
        <div className="mt-8 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <label
              htmlFor="destinationName"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <div className="mt-1">
              <input
                id="destinationName"
                name="destinationName"
                type="text"
                placeholder="e.g. name of the tool"
                value={name}
                onChange={(event) => setDestinationName(event.target.value)}
                onKeyDown={async (e: any) => await handleKeyDown(e)}
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-elbwalker focus:border-elbwalker sm:text-sm"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

DestinationCreationName.defaultProps = {};

export default DestinationCreationName;
