import {
  CogIcon,
  DotsVerticalIcon,
  ExternalLinkIcon,
  PencilAltIcon,
  TrashIcon,
} from '@heroicons/react/solid';
import * as React from 'react';
import Toggle from '../../../../../core/components/atoms/toggle.component';
import UserContract from '../../../../../user/service/contracts/user.contract';
import { getProjectDestinationInfo } from '../../../../service/api/project-destinations.repository';
import {
  ProjectDestination,
  ProjectDestinationTypes,
} from '../../../../service/contracts/project-destination.contract';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { classNames } from '../../../../../core/service/css/class-names.service';
import Badge from '../../../../../core/components/atoms/badges/badges.component';
import ButtonSecondary from '../../../../../core/components/atoms/buttons/button-secondary.component';

export interface DestinationRowProps {
  key: string;
  currentUser: UserContract;
  projectId: string;
  destinationId: string;
  className?: string;
  destination: ProjectDestination;
  onEdit: () => void;
  onDelete: (destinationId: string) => void;
  onToggleActive: (
    projectId: string,
    destinationId: string,
    status: boolean,
  ) => void;
}

const renderDestinationMode = (type: ProjectDestinationTypes) => {
  let title = 'Web';
  let color = 'gray';

  switch (type) {
    case ProjectDestinationTypes.Collect:
      title = 'Server';
      color = 'blue';
      break;
  }

  return (
    <div className="flex justify-between">
      <Badge title={title} color={color}></Badge>
    </div>
  );
};

const renderDestinationStatus = (
  destination: ProjectDestination,
  onToggleActive: () => void,
  onEdit: () => void,
) => {
  if (destination.isValid)
    return (
      <Toggle
        disabled={false}
        active={destination.active}
        onSetActive={() => {
          onToggleActive();
        }}
      />
    );

  return (
    <ButtonSecondary
      label="Configure"
      className="justify-self-end"
      onClick={onEdit}
      disabled={false}
      loading={false}
      icon={
        <CogIcon className={`h-4.5 w-4.5 align-middle items-center mr-2`} />
      }
    />
  );
};

const DestinationRow: React.FC<DestinationRowProps> = (props) => {
  const destination = props.destination;
  const destinationInfo = getProjectDestinationInfo(destination.type);

  if (!destinationInfo) return null;

  return (
    <tr className="items-center align-middle">
      <td className="flex flex-row px-5 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        <div className="flex flex-wrap content-center mr-3 min-w-2">
          <img
            className="h-8 w-8"
            src={destinationInfo.logo}
            alt={destination.name}
          />
        </div>
        <div className="flex content-center items-center align-middle min-w-0">
          <p className="text-base font-medium text-gray-900 truncate">
            {destination.name}
          </p>
        </div>
      </td>
      <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
        {destinationInfo.category}
      </td>
      <td className="px-5 py-4 whitespace-nowrap text-sm font-medium">
        <a
          href={destinationInfo.docs}
          target="_blank"
          rel="noreferrer"
          className="text-elbwalker underline"
        >
          <ExternalLinkIcon
            className="h-5 w-5 mr-3 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </a>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {renderDestinationMode(destination.type)}
      </td>
      <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
        {renderDestinationStatus(
          destination,
          () => {
            props.onToggleActive(
              props.projectId,
              props.destinationId,
              !destination.active,
            );
          },
          props.onEdit,
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center text-sm">
        <Menu as="div" className="relative inline-block text-left">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-elbwalker">
                  <span className="sr-only">Open options</span>
                  <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="whitespace-nowrap bg-white z-50 overflow-hidden origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg ring-1 ring-gray-300 ring-opacity-5 focus:outline-none divide-y divide-gray-200"
                >
                  <div>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={props.onEdit}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'group whitespace-nowrap flex w-full items-center px-4 py-3 text-sm outline-none focus:outline-none',
                          )}
                        >
                          <PencilAltIcon
                            className="h-5 w-5 mr-3 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          Edit
                        </button>
                      )}
                    </Menu.Item>

                    {destination.type == 'collect' && (
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href={'https://console.cloud.google.com/bigquery'}
                            target="_blank"
                            rel="noreferrer"
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'group whitespace-nowrap flex w-full items-center px-4 py-3 text-sm outline-none focus:outline-none',
                            )}
                          >
                            <ExternalLinkIcon
                              className="h-5 w-5 mr-3 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Access Raw Data
                          </a>
                        )}
                      </Menu.Item>
                    )}
                  </div>
                  <div>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => props.onDelete(props.destinationId)}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-red-500'
                              : 'text-red-400',
                            'group whitespace-nowrap flex w-full items-center px-4 py-3 text-sm outline-none focus:outline-none',
                          )}
                        >
                          <TrashIcon
                            className="h-5 w-5 mr-3 text-red-400 group-hover:text-red-500"
                            aria-hidden="true"
                          />
                          Delete
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </td>
    </tr>
  );
};

DestinationRow.defaultProps = {};

export default DestinationRow;
