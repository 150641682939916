import * as React from 'react';
import { XIcon } from '@heroicons/react/solid';
import { useLayout } from '../../../context/layout.context';

export interface AlertProps {
  text: string;
  delay?: number;
  icon?: React.ReactElement;
  backgroundColor?: string;
  textColor?: string;
  buttonColor?: string;
}

const Alert: React.FC<AlertProps> = (props) => {
  const { resetAlerts } = useLayout();

  if (props.delay) {
    setTimeout(() => {
      resetAlerts();
    }, props.delay);
  }

  if (!props.text) return <span />;

  return (
    <div className="fixed z-10 inset-x-0 bottom-4 grid justify-items-center">
      <div className={`rounded-md p-4 w-auto ${props.backgroundColor}`}>
        <div className="flex">
          {props.icon ? <div className="flex-shrink-0">{props.icon}</div> : ''}
          <div className="ml-3">
            <p className={`text-sm font-medium ${props.textColor}`}>
              {props.text}
            </p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className={`inline-flex rounded-md p-1.5
              focus:outline-none focus:ring-2 focus:ring-offset-2
              ${props.buttonColor}
              `}
                onClick={() => resetAlerts()}
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Alert.defaultProps = {
  delay: 5000,
  backgroundColor: 'bg-green-50',
  textColor: 'text-green-800',
  buttonColor:
    'bg-green-50 text-green-500 hover:bg-green-100  focus:ring-offset-green-50 focus:ring-green-600',
};

export default Alert;
