export default class Filters {
  /**
   * Converts a given attribute name into a human readable name: 'created_at' => 'Created at'
   * @param string attributeName
   */
  static convertAttributeName(attributeName: string): string {
    function capitalizeFirstLetter(str: string): string {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return capitalizeFirstLetter(attributeName).replace('_', ' ');
  }

  /**
   * Invertion if Filters.convertAttributeName()
   *
   * @param string name
   */
  static revertAttributeName(name: string): string {
    return name.replace(' ', '_').toLowerCase();
  }
}
