import * as React from 'react';

export interface BadgeProps {
  title: string;
  color?: string;
  onClose?: any;
}

const colorClasses = (color?: string) => {
  switch (color) {
    case 'blue':
      return 'bg-table-blue-2 text-elbwalker';
    case 'red':
      return 'bg-red-100 text-red-800';
    case 'gray':
      return 'bg-gray-100 text-grey-800';
    case 'green':
      return 'bg-green-100 text-gray-800';
    case 'yellow':
      return 'bg-yellow-100 text-yellow-800';
    default:
      return 'bg-table-blue-2 text-elbwalker';
  }
};

const closeButton = (onClose?: any) => {
  if (!onClose) return;

  return (
    <button
      type="button"
      className="flex-shrink-0 ml-1.5 inline-flex text-elbwalker focus:outline-none focus:text-table-blue-2"
      aria-label="Remove small badge"
      onClick={onClose}
    >
      <svg
        className="h-2 w-2"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 8 8"
      >
        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
      </svg>
    </button>
  );
};

const Badge: React.FC<BadgeProps> = (props) => {
  return (
    <span
      className={`${colorClasses(
        props.color,
      )} px-2 inline-flex text-xs leading-5 font-semibold rounded-full`}
    >
      {props.title}
      {closeButton(props.onClose)}
    </span>
  );
};

Badge.defaultProps = {};

export default Badge;
