import * as React from 'react';
import LabelInput from '../../atoms/labels/label-input.component';
import SkeletonRectangle from '../../atoms/skeletons/skeleton-rectangle.component';
import Dropdown from '../dropdown/dropdown.component';

export interface FormLabeledDropdownProps {
  id: string;
  title: string;
  value: string | undefined;
  placeholder?: string;
  required: boolean;
  onChange: (value: string) => void;
  loading: boolean;
  values: string[];
  className?: string;
}

export const FormLabeledDropdown: React.FC<FormLabeledDropdownProps> = ({
  id = 'id',
  loading = false,
  ...props
}) => {
  return (
    <div className={`${props.className ? props.className : ''}`}>
      {loading ? (
        <SkeletonRectangle height="5" width="1/6" />
      ) : (
        <LabelInput id={id} value={props.title} />
      )}

      <div className="mt-1">
        {loading ? (
          <SkeletonRectangle height="10" width="full" />
        ) : (
          <Dropdown
            values={props.values}
            onChange={props.onChange}
            value={props.value}
          />
        )}
      </div>
    </div>
  );
};

export default FormLabeledDropdown;
