import Axios from 'axios';
import API from '../../../core/service/api/api.service';
import {
  ProjectContract,
  BrowseProjectContract,
  AddProjectContract,
  EditProjectContract,
} from '../contracts/project.contract';

export default class ProjectRepository {
  public static async browse(): Promise<BrowseProjectContract> {
    return (await Axios.get(`${API.baseUrl}/projects`)).data;
  }

  public static async read(projectId: string): Promise<ProjectContract> {
    return (await Axios.get(`${API.baseUrl}/projects/${projectId}`)).data;
  }

  public static async edit(
    projectId: string,
    project: EditProjectContract,
  ): Promise<ProjectContract> {
    return (await Axios.put(`${API.baseUrl}/projects/${projectId}`, project))
      .data;
  }

  public static async add(
    project: AddProjectContract,
  ): Promise<ProjectContract> {
    return (await Axios.post(`${API.baseUrl}/projects`, project)).data;
  }

  public static async delete(
    projectId: string,
  ): Promise<BrowseProjectContract> {
    return (await Axios.delete(`${API.baseUrl}/projects/${projectId}`)).data;
  }
}
