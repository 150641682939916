import * as React from 'react';
import UserContract from '../../../user/service/contracts/user.contract';
import { classNames } from '../../service/css/class-names.service';
import Header from '../organisms/header/header.component';
import Footer from '../organisms/footer/footer.component';

export interface LayoutProps {
  currentUser: UserContract;
  className?: string;
  title: string;
  titleAddition?: React.ReactElement;
}

const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <div
      className={classNames(
        'bg-elbwalkerBackground min-h-screen relative',
        props.className,
      )}
    >
      <Header currentUser={props.currentUser} />
      <div className="py-10">
        <header>
          <div className="max-w-6xl mx-auto flex items-center justify-between px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight text-gray-800">
              {props.title}
            </h1>
            {props.titleAddition ? <div>{props.titleAddition}</div> : ''}
          </div>
        </header>
        <main>
          <div className="max-w-6xl mx-auto sm:px-6 lg:px-8">
            <div className="px-4 py-8 sm:px-0">{props.children}</div>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
};

Layout.defaultProps = {};

export default Layout;
