import * as React from 'react';
import LayoutContainer from '../../../../core/components/templates/layout-container.component';
import UserContract from '../../../../user/service/contracts/user.contract';
import ControlStepper from '../../../../core/components/molecules/controls/control-stepper.component';
import { useState } from 'react';
import ProjectCreationDetails, {
  ProjectDetails,
} from '../../organisms/project-creation-steps/project-creation-details.component';
import ProjectCreationIndustry from '../../organisms/project-creation-steps/project-creation-industry.component';
import ProjectRepository from '../../../service/api/project.repository';
import ProjectCodeSnippet from '../../organisms/project-code-snippet.component';
import ButtonPrimary from '../../../../core/components/atoms/buttons/button-primary.component';
import { routeTo } from '../../../../core/service/route/route.service';
import { Paths } from '../../../../routes';
import { useHistory } from 'react-router-dom';
import { ProjectIndustryType } from '../../../service/contracts/project.contract';
import { useLayout } from '../../../../core/context/layout.context';

export interface ProjectCreationProcessProps {
  currentUser: UserContract;
  stepIndex?: number; // Storybook.
  createdProjectIdProps?: string; // Storybook
}

enum ProjectCreationStep {
  Create,
  Industry,
  ConfirmationCodeSnippet,
}

const ProjectCreationProcess: React.FC<ProjectCreationProcessProps> = ({
  stepIndex = 0,
  createdProjectIdProps = '',
  ...props
}) => {
  const history = useHistory();
  const [currentStep, setCurrentStep] =
    useState<ProjectCreationStep>(stepIndex);
  const [creationDetails, setCreationDetails] = useState<ProjectDetails>({});
  const [creationIndustry, setCreationIndustry] = useState<
    ProjectIndustryType | undefined
  >();
  const [createdProjectId, setCreatedProjectId] = useState<string>(
    createdProjectIdProps,
  );
  const { handleError } = useLayout();

  const titleLabels = ['Project Details', 'Industry', 'Completion'];

  function renderTitle() {
    return titleLabels[currentStep];
  }

  function renderDescription() {
    switch (currentStep) {
      case ProjectCreationStep.Create:
        return 'Please tell us the name and domain of the project that you want to use elbwalker for.';
      case ProjectCreationStep.Industry:
        return 'What best describes the purpose of your website? This helps us to match elbwalker according to your needs.';
      case ProjectCreationStep.ConfirmationCodeSnippet:
        return 'Insert the walker with just one line of code . You can place it in the HTML head with no performance issues.';
    }
  }

  function renderContent() {
    switch (currentStep) {
      case ProjectCreationStep.Create:
        return (
          <ProjectCreationDetails
            initialValue={creationDetails}
            onChange={(details) => {
              setCreationDetails(details);
            }}
            onCanBeCompleted={(canBeCompleted) => {
              setStepCanBeCompleted(canBeCompleted);
            }}
            onRequestCompletion={() => setCurrentStep(currentStep + 1)}
          />
        );
      case ProjectCreationStep.Industry:
        return (
          <ProjectCreationIndustry
            selectedKey={creationIndustry}
            onChange={(selectedKey, canBeCompleted) => {
              setCreationIndustry(selectedKey as ProjectIndustryType);
              setStepCanBeCompleted(canBeCompleted);
            }}
            onRequestCompletion={() => setCurrentStep(currentStep + 1)}
          />
        );
      case ProjectCreationStep.ConfirmationCodeSnippet:
        return (
          createdProjectId && (
            <ProjectCodeSnippet projectId={createdProjectId} />
          )
        );
    }
  }

  const [stepCanBeCompleted, setStepCanBeCompleted] = useState<boolean>(false);
  const [showStepsCompletionLoading, setShowStepsCompletionLoading] =
    useState<boolean>(false);

  async function onCreateProjectClicked() {
    if (!creationDetails || !creationDetails.name || !creationDetails.domain)
      return;

    setShowStepsCompletionLoading(true);

    // @TODO check error handling
    handleError('');
    if (!creationIndustry) return;

    ProjectRepository.add({
      name: creationDetails.name,
      domain: creationDetails.domain,
      industry: creationIndustry,
    })
      .then((project) => {
        setCreatedProjectId(project.id);
        setCurrentStep(ProjectCreationStep.ConfirmationCodeSnippet);
      })
      .catch(() => {
        //TODO check Error handling
        handleError(
          'Oops an error occurred. Please reload the page and try again.',
        );
        setCreatedProjectId('1');
        setCurrentStep(ProjectCreationStep.ConfirmationCodeSnippet);
      })
      .finally(() => {
        setShowStepsCompletionLoading(false);
      });
  }

  function renderBottomControl() {
    if (currentStep == ProjectCreationStep.ConfirmationCodeSnippet) {
      return (
        <div className="flex flex-row-reverse w-full pt-5">
          <ButtonPrimary
            label={'Done'}
            loading={false}
            onClick={() => {
              history.push(routeTo(Paths.home, {}));
            }}
          />
        </div>
      );
    } else {
      return (
        <ControlStepper
          showStepsCompletionLoading={showStepsCompletionLoading}
          currentStepCanBeCompleted={stepCanBeCompleted}
          nextLabel={'Next'}
          previousLabel={'Previous'}
          steps={2}
          onStepSelected={(stepIndex) => {
            setStepCanBeCompleted(false);
            setCurrentStep(stepIndex);
          }}
          completionLabel={'Create Project'}
          currentStep={currentStep}
          onStepsCompleted={async () => {
            await onCreateProjectClicked();
          }}
        />
      );
    }
  }

  function title() {
    switch (currentStep) {
      case ProjectCreationStep.Create:
        return 'Create Project';
      case ProjectCreationStep.Industry:
        return 'Create Project: ' + creationDetails.name;
      case ProjectCreationStep.ConfirmationCodeSnippet:
        return 'Created Project: ' + creationDetails.name;
    }
  }

  return (
    <LayoutContainer title={title()} currentUser={props.currentUser}>
      <h1>{renderTitle()}</h1>
      <p className="text-sm text-gray-600">{renderDescription()}</p>
      <div style={{ minHeight: '450px' }}>{renderContent()}</div>
      {renderBottomControl()}
    </LayoutContainer>
  );
};

ProjectCreationProcess.defaultProps = {};

export default ProjectCreationProcess;
