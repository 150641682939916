import Axios from 'axios';
import API from '../../../../core/service/api/api.service';
import DimensionalFactsContract from '../../contracts/stats/dimensional-facts/dimensional-facts.contract';
import DimensionalFactsRequestParamsContract from '../../contracts/stats/dimensional-facts/dimensional-facts-request-params.contract';

export default class DimensionalFactsRepository {
  public static async read(
    projectId: string,
    dimension: string,
    params: DimensionalFactsRequestParamsContract,
  ): Promise<DimensionalFactsContract> {
    return (
      await Axios.get(
        `${API.baseUrl}/projects/${projectId}/stats/dimensional-facts/${dimension}`,
        {
          params,
        },
      )
    ).data;
  }
}
