import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { classNames } from '../../../service/css/class-names.service';
import Input from '../../atoms/inputs/input.component';

export interface AutoCompletionProps {
  id: string;
  values: string[];
  placeholder?: string;
  onChange: (value: string) => void;
  value?: string;
  disabled?: boolean;
}

export const AutoCompletion: React.FC<AutoCompletionProps> = (props) => {
  const [currentText, setCurrentText] = useState(
    props.value ? props.value : '',
  );

  const [isInputFocussed, setIsInputFocussed] = useState(false);
  const [suggestValues, setSuggestValues] = useState(['']);
  const [suggestValueIndex, setSuggestValueIndex] = useState(-1);

  useEffect(() => {
    setSuggestValues(
      props.values.filter((value) =>
        value.toUpperCase().startsWith(currentText.toUpperCase()),
      ),
    );
  }, [currentText, suggestValueIndex]);

  const handleCurrentText = (value: string) => {
    props.onChange(value);
    setCurrentText(value);
  };

  function renderOptionValue(value: string, index: number) {
    const result: (JSX.Element | string)[] = [];
    const length = currentText.length;
    const first = value.substr(0, length);
    const second = value.substr(length);
    const highlighted = <b key={`${value}-${index}`}>{first}</b>;
    result.push(highlighted);
    result.push(second);
    return result;
  }

  return (
    <Listbox key={props.id} value={currentText} onChange={handleCurrentText}>
      <div className="mt-1 relative" key={props.id}>
        <Input
          value={currentText}
          placeholder={props.placeholder}
          id={'id'}
          autoComplete={'off'}
          onFocusChange={setIsInputFocussed}
          onKeyDown={(event) => {
            if (suggestValues.length > 0) {
              switch (event.key) {
                case 'ArrowUp':
                  const upIndex = suggestValueIndex - 1;
                  const upValue =
                    upIndex < 0 ? suggestValues.length - 1 : upIndex;
                  setSuggestValueIndex(upValue);
                  return;
                case 'ArrowDown':
                  const downIndex = suggestValueIndex + 1;
                  const downValue =
                    downIndex == suggestValues.length || downIndex < 0
                      ? 0
                      : downIndex;
                  setSuggestValueIndex(downValue);
                  return;
                case 'Enter':
                  handleCurrentText(suggestValues[suggestValueIndex]);
                  setSuggestValueIndex(-1);
                  return;
              }
            }
          }}
          onChange={handleCurrentText}
          required={false}
          type={'text'}
          disabled={props.disabled}
        />

        <Transition
          show={
            suggestValues.length > 0 &&
            isInputFocussed &&
            !(suggestValues.length == 1 && suggestValues[0] == currentText)
          }
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            static
            className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md text-base ring-1 ring-gray-300 ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
            key={'options'}
          >
            {suggestValues.map((v, index) => (
              <Listbox.Option
                key={`${v}-${index}`}
                className={() =>
                  classNames(
                    suggestValueIndex == index
                      ? 'text-white bg-elbwalker'
                      : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-elbwalker hover:text-white',
                  )
                }
                value={v}
              >
                <>
                  <span className="block truncate">
                    {renderOptionValue(v, index)}
                  </span>
                </>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

AutoCompletion.defaultProps = {};

export default AutoCompletion;
