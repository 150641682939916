import Axios from 'axios';
import API from '../../../core/service/api/api.service';
import { getIcon } from '../../../core/service/icons/icon.service';
import {
  BrowseProjectDestinationsContract,
  ProjectDestination,
  ProjectDestinationInfo,
  ProjectDestinationTypes,
} from '../contracts/project-destination.contract';

export default class ProjectDestinationsRepository {
  public static async browse(
    projectId: string,
  ): Promise<BrowseProjectDestinationsContract> {
    return (
      await Axios.get(`${API.baseUrl}/projects/${projectId}/destinations`)
    ).data;
  }

  public static async edit(
    projectId: string,
    destinationId: string,
    destination: Partial<ProjectDestination>,
  ): Promise<BrowseProjectDestinationsContract> {
    return (
      await Axios.patch(
        `${API.baseUrl}/projects/${projectId}/destinations/${destinationId}`,
        {
          ...destination,
        },
      )
    ).data;
  }

  public static async add(
    projectId: string,
    destination: Partial<ProjectDestination>,
  ): Promise<BrowseProjectDestinationsContract> {
    return (
      await Axios.post(`${API.baseUrl}/projects/${projectId}/destinations`, {
        ...destination,
      })
    ).data;
  }

  public static async delete(
    projectId: string,
    destinationId: string,
  ): Promise<BrowseProjectDestinationsContract> {
    return (
      await Axios.delete(
        `${API.baseUrl}/projects/${projectId}/destinations/${destinationId}`,
      )
    ).data;
  }
}

export function getProjectDestinationInfo(
  type: ProjectDestinationTypes,
): ProjectDestinationInfo | false {
  switch (type) {
    case ProjectDestinationTypes.Collect:
      return {
        logo: getIcon('gbq') || '',
        category: 'Data Warehouse',
        docs: 'https://docs.elbwalker.com/destinations/warehouses/collect',
      };
    case ProjectDestinationTypes.FacebookPixel:
      return {
        logo: getIcon('facebook') || '',
        category: 'Marketing',
        docs: 'https://docs.elbwalker.com/destinations/overview',
      };
    case ProjectDestinationTypes.GoogleTagManager:
      return {
        logo: getIcon('google_tagmanager') || '',
        category: 'Tag Management',
        docs: 'https://docs.elbwalker.com/destinations/tag-management-tools/google-tag-manager',
      };
    case ProjectDestinationTypes.GoogleUA:
      return {
        logo: getIcon('google_ua') || '',
        category: 'Analytics',
        docs: 'https://docs.elbwalker.com/destinations/overview',
      };
    case ProjectDestinationTypes.GoogleGA4:
      return {
        logo: getIcon('google_firebase') || '',
        category: 'Analytics',
        docs: 'https://docs.elbwalker.com/destinations/overview',
      };
    case ProjectDestinationTypes.Matomo:
      return {
        logo: getIcon('matomo') || '',
        category: 'Analytics',
        docs: 'https://docs.elbwalker.com/destinations/overview',
      };
    default:
      return false;
  }
}
