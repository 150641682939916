import { useContext, useState, useEffect, createContext } from 'react';
import * as React from 'react';
import firebase from 'firebase/app';
import { auth } from '../../firebase';
import { Paths } from '../../routes';
import AuthRepository from '../service/api/auth.repository';
import API from '../service/api/api.service';
import UserContract from '../../user/service/contracts/user.contract';
import { useHistory } from 'react-router-dom';
import UserRepository from '../../user/service/api/user.repository';
import { ProjectContract } from '../../project/service/contracts/project.contract';

const AuthContext = createContext<AuthContextProps | null>(null);

export interface AuthContextProps {
  currentUser: UserContract | null;
  handleCurrentProject: (project?: ProjectContract) => null | ProjectContract;
  login: (
    email: string,
    password: string,
    remember: boolean,
  ) => Promise<firebase.auth.UserCredential>;
  signup: (
    email: string,
    password: string,
    name: string,
    newsletter: string,
  ) => Promise<firebase.auth.UserCredential>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  refreshToken: () => Promise<string>;
  // updateEmail;
  // updatePassword;
}

export function useAuth() {
  return useContext(AuthContext);
}

interface IProps {
  children: React.ReactNode;
}

export function AuthProvider({ children }: IProps) {
  const history = useHistory();
  const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>(null);
  const [currentUser, setCurrentUser] = useState<UserContract | null>(null);
  const [currentProject, setCurrentProject] =
    useState<ProjectContract | null>(null);
  const [loading, setLoading] = useState(true);

  async function signup(
    email: string,
    password: string,
    name: string,
    newsletter: string,
  ) {
    try {
      const userCredentials = await auth.createUserWithEmailAndPassword(
        email,
        password,
      );

      if (!userCredentials.user) throw 'Failed to create user';

      await UserRepository.create(userCredentials.user.uid, name, newsletter);

      return userCredentials;
    } catch (error) {
      throw error.message || 'Failed to create an account';
    }
  }

  async function login(email: string, password: string, remember: boolean) {
    const persistence = remember ? 'local' : 'session';
    await auth.setPersistence(persistence);

    return await auth.signInWithEmailAndPassword(email, password);
  }

  async function logout() {
    return auth.signOut();
  }

  async function resetPassword(email: string) {
    return auth.sendPasswordResetEmail(email, {
      url: process.env.REACT_APP_APP_BASE_URL + Paths.login,
    });
  }

  async function refreshToken() {
    return firebaseUser ? await API.refreshToken(firebaseUser) : '';
  }

  function handleCurrentProject(project?: ProjectContract) {
    if (project) setCurrentProject(project);
    return currentProject;
  }

  // function updateEmail(email: string) {
  //   return (currentUser! as firebase.User).updateEmail(email);
  // }

  // function updatePassword(password: string) {
  //   return (currentUser! as firebase.User).updatePassword(password);
  // }

  useEffect(() => {
    return auth.onAuthStateChanged(
      async (firebaseUser: firebase.User | null) => {
        setFirebaseUser(firebaseUser);

        if (firebaseUser) {
          // Check for mail verification
          if (!firebaseUser.emailVerified) {
            history.push(Paths.signup_success);
            setLoading(false);
            return;
          }

          // Set current user
          const user = await AuthRepository.init(firebaseUser.uid);
          await refreshToken();
          setCurrentUser(user);

          if (window.location.pathname.startsWith(Paths.login)) {
            history.push(Paths.home);
          }
        }

        // @TODO create a fallback solution in case of an error
        setLoading(false);
      },
    );
  }, []);

  const value = {
    currentUser,
    handleCurrentProject,
    login,
    signup,
    logout,
    resetPassword,
    refreshToken,
    // updateEmail,
    // updatePassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
