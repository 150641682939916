import * as React from 'react';

// update interface and switch to add more options

export interface SkeletonRectangleProps {
  height?:
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '5.25'
    | '6'
    | '8'
    | '10'
    | '10.5'
    | '13.5'
    | '24'
    | '64'
    | '80';
  width?:
    | '1'
    | '1/1'
    | '1/2'
    | '1/3'
    | '2/3'
    | '1/4'
    | '1/6'
    | '1/12'
    | '14'
    | 'full';
  className?: string;
}

export const SkeletonRectangle: React.FC<SkeletonRectangleProps> = (props) => {
  const heightClass = (height: string | undefined) => {
    switch (height) {
      case '1':
        return 'h-1';
      case '2':
        return 'h-2';
      case '3':
        return 'h-3';
      case '4':
        return 'h-4';
      case '5':
        return 'h-5';
      case '5.25':
        return 'h-5.25';
      case '6':
        return 'h-6';
      case '8':
        return 'h-8';
      case '10':
        return 'h-10';
      case '10.5':
        return 'h-10.5';
      case '13.5':
        return 'h-13.5';
      case '24':
        return 'h-24';
      case '64':
        return 'h-64';
      case '80':
        return 'h-80';
      default:
        return 'h-5';
    }
  };

  const widthClass = (width: string | undefined) => {
    switch (width) {
      case '1':
        return 'w-1';
      case '1/1':
        return 'w-1/1';
      case '1/2':
        return 'w-1/2';
      case '1/3':
        return 'w-1/3';
      case '2/3':
        return 'w-2/3';
      case '1/4':
        return 'w-1/4';
      case '1/6':
        return 'w-1/6';
      case '1/12':
        return 'w-1/12';
      case '14':
        return 'w-14';
      case 'full':
        return 'w-full';
      default:
        return 'w-10';
    }
  };

  return (
    <div
      className={`animate-pulse bg-gray-200 rounded-md ${heightClass(
        props.height,
      )} ${widthClass(props.width)} ${props.className} `}
    />
  );
};

export default SkeletonRectangle;
