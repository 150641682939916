import * as React from 'react';
import { Paths } from '../../../../routes';
import { ProjectEvent } from '../../../service/contracts/event.contract';
import Snippet from '../../molecules/snippet.component';
import SimpleModal from './simple-modal.component';

export interface EventModalProps {
  event: ProjectEvent;
  onCloseClick: () => void;
}

const EventCodeModal: React.FC<EventModalProps> = (props) => {
  const red = { color: 'rgb(239, 99, 114)' } as React.CSSProperties;
  const orange = { color: 'rgb(208, 145, 90)' } as React.CSSProperties;
  const grey = { color: 'rgb(170, 178, 192)' } as React.CSSProperties;
  const green = { color: 'rgb(137, 194, 110)' } as React.CSSProperties;

  const indent = { padding: '0px 0px 0px 1rem' } as React.CSSProperties;

  interface Attribute {
    key: string;
    value: string;
  }

  type Content = (string | Element)[];
  interface Element {
    element: string;
    attributes: Attribute[];
    content: Content;
    indent: React.CSSProperties;
  }

  const copyCode = (
    element: string,
    attributes: Attribute[],
    content: Content,
    indent?: React.CSSProperties,
  ) => {
    const indention = indent ? '  ' : '';
    let code = `\n${indention}`;

    code += `<${element}`;

    attributes.map((attr) => (code += ` ${attr.key}="${attr.value}"`));

    code += `>`;

    content.map(
      (elem) =>
        (code +=
          typeof elem === 'string'
            ? elem
            : copyCode(
                elem.element,
                elem.attributes,
                elem.content,
                elem.indent,
              )),
    );

    if (!indent) code += '\n';
    code += `</${element}>`;

    return code;
  };

  const formatCode = (
    element: string,
    attributes: Attribute[],
    content: Content,
    indent?: React.CSSProperties,
  ) => {
    return (
      <div style={indent}>
        <span>
          <span style={grey}>&lt;</span>
          <span style={red}>{element}</span>
        </span>

        {attributes.map((attr) => (
          <span key={attr.key}>
            &nbsp; <span style={orange}>{attr.key}</span>
            <span style={grey}>=</span>
            <span style={green}>&quot;{attr.value}&quot;</span>
          </span>
        ))}

        <span style={grey}>&gt;</span>

        {content.map((elem) =>
          typeof elem === 'string'
            ? elem
            : formatCode(
                elem.element,
                elem.attributes,
                elem.content,
                elem.indent,
              ),
        )}

        <span style={grey}>&lt;/</span>
        <span style={red}>{element}</span>
        <span style={grey}>&gt;</span>
      </div>
    );
  };

  const getCodeEntity = (event: ProjectEvent) => {
    return [
      { key: 'elb', value: event.entity },
      { key: 'elb-action', value: `${event.trigger}:${event.action}` },
    ];
  };

  const getCodeProperties = (event: ProjectEvent) => {
    const properties: Element[] = [];

    Object.entries(event.properties)
      .sort()
      .map(([name, property]) => {
        properties.push({
          element: 'div',
          attributes: [
            {
              key: `elb-${event.entity}`,
              value: `${name}:${property.example}`,
            },
          ],
          content: [property.example],
          indent: indent,
        });
      });

    return properties;
  };

  const getSnippet = (event: ProjectEvent) => {
    return (
      <div>
        <Snippet
          center={false}
          copyText={copyCode(
            'div',
            getCodeEntity(event),
            getCodeProperties(event),
          )}
          visibleText={
            <div className="overflow-hidden">
              {formatCode(
                'div',
                getCodeEntity(event),
                getCodeProperties(event),
              )}
            </div>
          }
        />
        <p className="mt-4 text-sm text-gray-600">
          Need a comprehensive example? View{' '}
          <a
            href={Paths.docs.walker_basics}
            target="_blank"
            rel="noreferrer"
            className="text-sm text-gray-600 underline"
          >
            docs
          </a>
          .
        </p>
      </div>
    );
  };

  return (
    <div>
      <SimpleModal
        icon={'CodeIcon'}
        title="Code example"
        ButtonSecondaryLabel="Close"
        ButtonSecondaryOnClick={() => {
          props.onCloseClick();
        }}
        red={false}
        loading={false}
        wide={true}
        text={getSnippet(props.event)}
      ></SimpleModal>
    </div>
  );
};

EventCodeModal.defaultProps = {};

export default EventCodeModal;
