import * as React from 'react';
import { colClasses } from '../../service/css/col.service';

export interface GridCardProps {
  cols: number;
  question?: string;
  headline?: string;
  infoBox?: JSX.Element;
}

const GridCard: React.FC<GridCardProps> = (props) => {
  const headline = () => {
    if (props.headline) {
      return (
        <div className="text-xl mb-4 flex">
          {props.headline}
          {props.infoBox}
        </div>
      );
    }

    return undefined;
  };

  const renderQuestion = () => {
    if (!props.question) {
      return undefined;
    }

    return <span className="ml-6 text-gray-700">{props.question}</span>;
  };
  return (
    <div className={`${colClasses(props.cols)} flex flex-col h-full`}>
      {renderQuestion()}
      <div className="flex-1 flex flex-col mt-4 bg-white rounded-lg w-full p-6">
        {headline()}
        <div className="min-h-widget flex-grow">{props.children}</div>
      </div>
    </div>
  );
};

GridCard.defaultProps = {};

export default GridCard;
