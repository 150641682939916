import icon_ads from '../../../core/assets/icons/icon_ads.svg';
import icon_elbwalker from '../../../core/assets/icons/icon_elbwalker.svg';
import google_bigquery from '../../../core/assets/icons/icon_google_bigquery.svg';
import google_tagmanager from '../../../core/assets/icons/icon_google_tagmanager.svg';
import icon_ga from '../../../core/assets/icons/icon_google_ua.svg';
import icon_firebase from '../../../core/assets/icons/icon_google_firebase.svg';
import icon_gbq from '../../../core/assets/icons/icon_google-bigquery.svg';
import icon_fb from '../../../core/assets/icons/icon_facebook.svg';
import icon_matomo from '../../../core/assets/icons/icon_matomo.png';
import icon_minubo from '../../../core/assets/icons/icon_minubo.png';
import icon_rakuten from '../../../core/assets/icons/icon_rakuten.png';
import icon_trbo from '../../../core/assets/icons/icon_trbo.svg';

export type GetIconType = string | undefined | GetIconType[];

export function getIcon(type: string) {
  switch (type) {
    case 'ads': {
      return icon_ads;
    }
    case 'elbwalker': {
      return icon_elbwalker;
    }
    case 'collect': {
      return google_bigquery;
    }
    case 'facebook':
    case 'facebook_pixel': {
      return icon_fb;
    }
    case 'google_ga4':
    case 'google_firebase': {
      return icon_firebase;
    }
    case 'google_ua': {
      return icon_ga;
    }
    case 'gbq': {
      return icon_gbq;
    }
    case 'google_tagmanager': {
      return google_tagmanager;
    }
    case 'matomo': {
      return icon_matomo;
    }
    case 'minubo': {
      return icon_minubo;
    }
    case 'rakuten': {
      return icon_rakuten;
    }
    case 'trbo': {
      return icon_trbo;
    }
    default: {
      return;
    }
  }
}
