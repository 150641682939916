import * as React from 'react';
import LabelInput from '../../atoms/labels/label-input.component';
import SkeletonRectangle from '../../atoms/skeletons/skeleton-rectangle.component';
import { Input } from '../../atoms/inputs/input.component';
import { XIcon } from '@heroicons/react/solid';
import { useState } from 'react';

type Parameters = string[];
export interface FormExtendableListProps {
  id: string;
  title: string;
  placeholder?: string;
  onChange: (values: Parameters) => void;
  loading: boolean;
  values: Parameters;
  className?: string;
}

export const FormExtendableList: React.FC<FormExtendableListProps> = ({
  loading = false,
  ...props
}) => {
  const [values, setValues] = useState<Parameters>(
    props.values.length ? props.values : [''],
  );

  React.useEffect(() => {
    props.onChange(values);
  }, [values]);

  function updateValues(values: Parameters) {
    if (values.length === 0) values.push('');

    setValues(values);
  }

  return (
    <div className={`${props.className ? props.className : ''}`}>
      {loading ? (
        <SkeletonRectangle height="5" width="1/6" />
      ) : (
        <LabelInput id={props.id} value={props.title} />
      )}
      <div className="mt-1">
        {values.map((value, index) => (
          <div className="flex flex-row mt-2" key={index}>
            <Input
              id={props.id}
              type="text"
              value={value}
              placeholder={props.placeholder}
              required={true}
              onChange={(newValue) => {
                updateValues(
                  values.map((item, itemIndex) => {
                    if (itemIndex == index) {
                      return newValue;
                    }
                    return item;
                  }),
                );
              }}
              disabled={false}
            />
            {value && value.length > 0 && (
              <button
                type="button"
                onClick={() => {
                  updateValues(values.filter((item) => item != value));
                }}
                className="inline-flex ml-3 items-center px-2 m-1 border border-transparent rounded-full shadow-sm text-white ring-1 ring-elbwalker focus:outline-none"
              >
                <XIcon className="text-elbwalker h-5 w-4" aria-hidden="true" />
              </button>
            )}
          </div>
        ))}
      </div>

      {values && values.length && values[values.length - 1].length > 0 && (
        <div className="mt-2">
          <a
            onClick={() => {
              updateValues([...values, '']);
            }}
            className="ml-1 font-bold text-elbwalker hover:text-elbwalker-500 focus:outline-none focus:underline transition ease-in-out duration-150"
          >
            + Add more
          </a>
        </div>
      )}
    </div>
  );
};

export default FormExtendableList;
