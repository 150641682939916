import { useState } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { AuthContextProps, useAuth } from '../../../context/auth.context';
import { Paths } from '../../../../routes';
import SignupProcess from '../../templates/auth-layout.component';
import Alert from '../../molecules/alert.component';
import FormLabeledInput from '../../molecules/forms/form-labeled-input.component';
import ButtonPrimary from '../../atoms/buttons/button-primary.component';

export interface LoginProps {
  title?: string;
}

const Login: React.FC<LoginProps> = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const { login } = useAuth() as AuthContextProps;

  const [email, changeEmail] = useState<string>('');
  const [password, changePassword] = useState<string>('');
  const [remember, changeRemember] = useState<boolean>(false);

  const handleKeyDown = async (e: any) => {
    if (e.keyCode === 13 || e?.key === 'Enter') {
      await handleLogIn();
    }
  };

  async function handleLogIn() {
    if (!email) return setError('Email required');
    if (!password) return setError('Password required');

    setError('');
    setLoading(true);

    await login(email.trim(), password, remember)
      .then(() => {
        // Do nothing. Listener redirect to Home. TODO
        // eslint-disable-next-line no-console
        console.log('Successfully logged in.');
      })
      .catch(() => {
        setError('Failed to log in');
        setLoading(false);
        // TODO Differentiation between error messages.
      })
      .finally(() => {
        // setLoading(false);
      });
  }

  const renderContent = () => {
    return (
      <div>
        <div className="mb-6">
          <div className="relative">
            <div className="relative flex justify-center text-xl font-medium">
              <span className="px-2 bg-white text-gray-700">
                Welcome back! Please log in ...
              </span>
            </div>
          </div>
        </div>

        <div className="space-y-6">
          <FormLabeledInput
            id={'email'}
            type={'text'}
            title={'Email'}
            placeholder={'you@yourcompany.com'}
            value={email}
            required={true}
            onChange={(value) => changeEmail(value)}
            onKeyDown={async (e: any) => await handleKeyDown(e)}
            loading={false}
          />
          <FormLabeledInput
            id={'password'}
            type={'password'}
            title={'Password'}
            value={password}
            required={true}
            onChange={(value) => changePassword(value)}
            onKeyDown={async (e: any) => await handleKeyDown(e)}
            loading={false}
          />
          <div className="grid grid-cols-14 gap-y-4">
            <div className="col-start-2 col-span-12">
              <div className="flex items-center justify-between text-sm">
                <div className="flex items-center">
                  <input
                    id="remember"
                    name="remember"
                    type="checkbox"
                    checked={remember}
                    onChange={(event) => changeRemember(event.target.checked)}
                    className="h-4 w-4 text-elbwalker focus:ring-elbwalker border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>
                <Link
                  to={Paths.password.reset}
                  className="ml-1 font-medium text-elbwalker hover:text-elbwalker-600 focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>
          </div>

          {/* TODO Handle loading */}
          <ButtonPrimary
            label={'Log in'}
            loading={loading}
            disabled={loading}
            wide={true}
            onClick={async () => await handleLogIn()}
          />
          {error && <Alert errorMessage={error} onClick={() => setError('')} />}
        </div>

        <div className="absolute inset-x-0 bottom-0 mt-6 py-8">
          <p className="mt-2 text-center text-sm text-gray-600 max-w">
            Don&apos;t have an account yet?&nbsp;
            <Link
              to={Paths.signup}
              className="font-medium text-elbwalker hover:text-elbwalker-600 underline"
            >
              Sign up
            </Link>
          </p>
        </div>
      </div>
    );
  };

  return <SignupProcess content={renderContent()}></SignupProcess>;
};

Login.defaultProps = {};

export default Login;
