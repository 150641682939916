import * as React from 'react';
import logo from '../../assets/elbwalker_logo_centred_blue_white.svg';
import Footer from '../organisms/footer/footer.component';

export interface AuthLayoutProps {
  content: React.ReactElement;
}

const AuthLayout: React.FC<AuthLayoutProps> = (props) => {
  return (
    <div className="bg-elbwalkerBackground min-h-screen relative">
      <div className="min-h-screen bg-elbwalkerBackground flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src={logo}
            alt="elbwalker logo"
          />
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="relative bg-white min-h-card py-8 px-4 shadow sm:rounded-lg sm:px-10 ">
            {props.content}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

AuthLayout.defaultProps = {};

export default AuthLayout;
