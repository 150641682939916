export const alignCol = (align: string) => {
  switch (align) {
    case 'left':
      return 'text-left pr-2';
    case 'right':
      return 'text-right pl-2';
    default:
      return;
  }
};
