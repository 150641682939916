import * as React from 'react';
import GroupsRadioCards, {
  RadioCardEntry,
} from '../../../../core/components/molecules/groups/groups-radio-cards.component';
import { useEffect, useState } from 'react';
import { ProjectIndustryType } from '../../../service/contracts/project.contract';

export interface ProjectCreationIndustryProps {
  onChange: (selectedKey: string, canBeCompleted: boolean) => void;
  onRequestCompletion: () => void;
  selectedKey?: string;
}

const ProjectCreationPurpose: React.FC<ProjectCreationIndustryProps> = (
  props,
) => {
  const [selectedKey, setSelectedKey] = useState<string | undefined>(
    props.selectedKey,
  );
  const purposes: RadioCardEntry[] = [
    {
      key: ProjectIndustryType.Ecom,
      title: 'e-commerce',
      description: `Your website's main goal is to sell products.`,
    },
    {
      key: ProjectIndustryType.Saas,
      title: 'SaaS',
      description: `Your website's main goal is to generate signups.`,
    },
    {
      key: ProjectIndustryType.Job,
      title: 'job/career platform',
      description: `Your website's main goal is to generate applications.`,
    },
    {
      key: ProjectIndustryType.Content,
      title: 'content website',
      description: `Your website's main goal is to publish any type of content and maybe generate leads or newsletter subscribers.`,
    },
    {
      key: ProjectIndustryType.Other,
      title: 'other',
      description: `If the purpose of your website doesn't really fit the other categories please select this option.`,
    },
  ];

  useEffect(() => {
    if (selectedKey) {
      props.onChange(
        selectedKey,
        isValidationSucessfull(selectedKey, undefined),
      );
    }
  }, [selectedKey]);

  function isValidationSucessfull(
    selectedKey: string,
    text: string | undefined,
  ) {
    return (
      purposes.find((e) => e.key === selectedKey)?.hasTextArea != true ||
      (text != undefined && text !== '')
    );
  }

  return (
    <div className="my-8">
      <form>
        <fieldset>
          <GroupsRadioCards
            selectedIndex={
              selectedKey
                ? purposes.map((e) => e.key).indexOf(selectedKey)
                : undefined
            }
            entries={purposes}
            onSelected={(selectedKey, text) => {
              setSelectedKey(selectedKey);
              props?.onChange(
                selectedKey,
                isValidationSucessfull(selectedKey, text),
              );
            }}
          />
        </fieldset>
      </form>
    </div>
  );
};

ProjectCreationPurpose.defaultProps = {};

export default ProjectCreationPurpose;
