import { useEffect, useState } from 'react';
import * as React from 'react';
import Layout from '../../../core/components/templates/layout.component';
import MemberRow from '../molecules/member-row.component';
import SimpleModal from '../organisms/modals/simple-modal.component';
import Input from '../../../core/components/atoms/inputs/input.component';
import ButtonPrimary from '../../../core/components/atoms/buttons/button-primary.component';
import Alert from '../../../core/components/molecules/alert.component';
import {
  ProjectMember,
  BrowseProjectMembersContract,
} from '../../service/contracts/project.contract';
import ProjectMembersRepository from '../../service/api/project-members.repository';
import { useParams } from 'react-router-dom';
import UserContract from '../../../user/service/contracts/user.contract';
import SkeletonRectangle from '../../../core/components/atoms/skeletons/skeleton-rectangle.component';
import SkeletonRound from '../../../core/components/atoms/skeletons/skeleton-round.component';

export interface OverviewMembersProps {
  currentUser: UserContract;
  className?: string;
  members: BrowseProjectMembersContract;
  loadingMembers: boolean;
}

const OverviewMembers: React.FC<OverviewMembersProps> = (props) => {
  const { projectId } = useParams<{ projectId: string }>();
  const [confirmRemoveMember, changeRemoveMember] = useState<
    ProjectMember | false
  >(false);
  const [email, changeEmail] = useState<string>('');
  const [error, setError] = useState('');
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [members, changeMembers] = useState<BrowseProjectMembersContract>([]);
  const [loadingMembers, setLoadingMembers] = useState(props.loadingMembers);

  const loadMembers = async (projectId: string) => {
    setError('');
    setLoadingMembers(true);
    await ProjectMembersRepository.browse(projectId)
      .then((response) => {
        changeMembers(response);
      })
      .catch(() => {
        setError('Failed to load current project data');
        // TODO Differentiation between error messages.
      })
      .finally(() => {
        setLoadingMembers(false);
      });
  };

  const removeMember = async (projectId: string, memberId: string) => {
    setError('');
    setLoadingRemove(true);
    await ProjectMembersRepository.delete(projectId, memberId)
      .then((response) => {
        changeMembers(response);
      })
      .catch(() => {
        setError('Failed to delete member');
      })
      .finally(() => {
        changeRemoveMember(false);
        setLoadingRemove(false);
      });
  };

  const inviteMember = async (projectId: string, email: string) => {
    setError('');
    setLoadingInvite(true);
    await ProjectMembersRepository.add(projectId, email)
      .then((response) => {
        changeMembers(response);
        changeEmail('');
      })
      .catch(() => {
        setError(
          'E-Mail address doesn’t exist. Please make sure the member already signed up.',
        );
      })
      .finally(() => {
        setLoadingInvite(false);
      });
  };

  useEffect(() => {
    // weird useEffect ft. async pattern...
    async function init() {
      await loadMembers(projectId);
    }
    init();
  }, [projectId]);

  const keyDownInvite = async (e: any) => {
    if (e.keyCode === 13 || e?.key === 'Enter') {
      await inviteMember(projectId, email);
    }
  };

  const renderMembers = (members: BrowseProjectMembersContract | []) => {
    return members.map((member: ProjectMember, index: number) => {
      return (
        <MemberRow
          key={index}
          member={member}
          currentUser={props.currentUser}
          remove={() => {
            changeRemoveMember(member);
          }}
        ></MemberRow>
      );
    });
  };

  const renderRemoveWindow = () => {
    if (!confirmRemoveMember) return undefined;

    return (
      <SimpleModal
        title={'Remove user'}
        text={'Are you sure you want to remove this user from your project?'}
        ButtonPrimaryLabel={'Remove'}
        red={true}
        ButtonSecondaryLabel={'Cancel'}
        loading={loadingRemove}
        ButtonPrimaryOnClick={() => {
          removeMember(projectId, confirmRemoveMember.id);
        }}
        ButtonSecondaryOnClick={() => {
          changeRemoveMember(false);
        }}
      ></SimpleModal>
    );
  };

  const content = (
    <div>
      {renderRemoveWindow()}
      <div className="flex flex-row-reverse mb-3">
        <ButtonPrimary
          label={'Invite Member'}
          loading={loadingInvite}
          margin={'ml-3'}
          onClick={() => inviteMember(projectId, email)}
        />
        <Input
          id={'email'}
          type={'text'}
          placeholder={'Email address...'}
          value={email}
          required={true}
          wide={false}
          width={'w-1/3'}
          margin={'ml-3'}
          onChange={(value) => changeEmail(value)}
          onKeyDown={async (e: any) => await keyDownInvite(e)}
        />
        {error && (
          <Alert
            errorMessage={error}
            onClick={() => setError('')}
            padding="p-2"
          ></Alert>
        )}
      </div>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="table-fixed min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="w-9/12 px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {loadingMembers ? 'Team' : `Team (${members.length})`}
                    </th>
                    <th
                      scope="col"
                      className="w-1/6 px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    ></th>
                    <th
                      scope="col"
                      className="w-1/12 px-5 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    ></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {loadingMembers ? (
                    <tr className="items-center align-middle">
                      <td className="px-5 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            <SkeletonRound size={10} />
                          </div>
                          <div className="flex-1 space-y-1 ml-4">
                            <SkeletonRectangle width={'1/3'} height={'4'} />
                            <SkeletonRectangle width={'1/2'} height={'4'} />
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                        <SkeletonRectangle width={'full'} />
                      </td>
                      <td className="px-5 py-4 whitespace-nowrap text-center text-sm font-medium">
                        <SkeletonRectangle width={'full'} />
                      </td>
                    </tr>
                  ) : (
                    renderMembers(members)
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Layout title="Project members" currentUser={props.currentUser}>
      {content}
    </Layout>
  );
};

OverviewMembers.defaultProps = { loadingMembers: true };
export default OverviewMembers;
