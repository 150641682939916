import * as React from 'react';
import { ProjectMember } from '../../service/contracts/project.contract';
import { TrashIcon, ChevronDownIcon } from '@heroicons/react/outline';
import UserContract from '../../../user/service/contracts/user.contract';
import { useState } from 'react';

export interface MemberRowProps {
  className?: string;
  currentUser: UserContract;
  member: ProjectMember;
  remove: (member: ProjectMember) => void;
}

const MemberRow: React.FC<MemberRowProps> = (props) => {
  const [showDropdownMenu, changeShowDropdownMenu] = useState<boolean>(false);

  const resend = () => {
    window.alert('resend'); // @TODO: add reinvite function
  };

  const renderDropdownMenu = () => {
    return (
      <div
        className={`z-10 origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg
        transition ease-${showDropdownMenu ? 'out' : 'in'} duration-${
          showDropdownMenu ? '100' : '75'
        } transform opacity-${showDropdownMenu ? '100' : '0'} scale-${
          showDropdownMenu ? '100' : '95'
        }
        `}
      >
        <div className="py-1 rounded-md bg-white ring-1 ring-gray-300 ring-opacity-5">
          <button
            className="block w-full text-left px-4 rounded py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
            onClick={() => {
              resend();
              changeShowDropdownMenu(false);
            }}
          >
            Resend
          </button>
        </div>
      </div>
    );
  };

  const inviteDropdown = () => {
    if (props.member.openInvite) {
      return (
        <div className="flex items-center">
          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
            Invite sent
          </span>
          <div>
            <div className="relative inline-block text-left w-full">
              <div>
                <button
                  className="focus:outline-none"
                  onClick={() => {
                    changeShowDropdownMenu(!showDropdownMenu);
                  }}
                >
                  <ChevronDownIcon className="h-4.5 w-4.5" />
                </button>
                {renderDropdownMenu()}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const removeMember = (
    user: UserContract,
    member: ProjectMember,
    removeFn: (member: ProjectMember) => void,
  ) => {
    if (user.id === member.id) return;

    return (
      <button
        className="focus:outline-none text-elbwalker hover:text-elbwalker-600"
        onClick={() => removeFn(member)}
      >
        <TrashIcon
          className={'h-4.5 w-4.5 text-elbwalker hover:text-elbwalker-600'}
        />
      </button>
    );
  };

  return (
    <tr className="items-center align-middle">
      <td className="px-5 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <div className="flex items-center justify-center h-10 w-10 bg-elbwalker-800 text-white border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out">
              <div>
                {props.member.name
                  .split(/\s/)
                  .reduce(
                    (response, word) => (response += word.slice(0, 1)),
                    '',
                  )}
              </div>
            </div>
          </div>

          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {props.member.name}
            </div>
            <div className="text-sm text-gray-500">{props.member.email}</div>
          </div>
        </div>
      </td>
      <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
        {inviteDropdown()}
      </td>
      <td className="px-5 py-4 whitespace-nowrap text-center text-sm font-medium">
        {removeMember(props.currentUser, props.member, props.remove)}
      </td>
    </tr>
  );
};

MemberRow.defaultProps = {};

export default MemberRow;
