import Axios from 'axios';
import API from '../../../core/service/api/api.service';
import UserContract from '../contracts/user.contract';

export default class UserRepository {
  public static async create(
    userId: string,
    name: string,
    newsletter?: string,
  ): Promise<UserContract> {
    return (
      await Axios.post(`${API.baseUrl}/users`, { userId, name, newsletter })
    ).data;
  }

  public static async get(userId: string): Promise<UserContract> {
    return (await Axios.get(`${API.baseUrl}/users/${userId}`)).data;
  }

  public static async edit(
    userId: string,
    user: Partial<UserContract>,
  ): Promise<UserContract> {
    return (await Axios.patch(`${API.baseUrl}/users/${userId}`, { ...user }))
      .data;
  }
}
