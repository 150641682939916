import * as React from 'react';
import DashboardModalModel from './dashboard-modal.model';
import {
  DimensionalFactsColumnContract,
  RowDataContract,
} from '../../../service/contracts/stats/dimensional-facts/dimensional-facts.contract';
import { classNames } from '../../../../core/service/css/class-names.service';
import Format from '../../../service/misc/format.service';
import WidgetRenderContract from '../../../service/contracts/widget-render.contract';
import LooseObject from '../../../../core/service/contracts/loose-object.contract';
import SkeletonRectangle from '../../../../core/components/atoms/skeletons/skeleton-rectangle.component';
import ButtonPrimary from '../../../../core/components/atoms/buttons/button-primary.component';
import { alignCol } from '../../../service/css/align.service';

interface Props {
  onClose: () => void;
  renderColumns: () => any;
  contract: WidgetRenderContract;
  columnMeta: LooseObject;
}

const DashboardModal: React.FC<Props> = (props) => {
  const { facts, rows, hasMoreData, onLoadMore, loading } = DashboardModalModel(
    props.contract,
  );

  const renderRows = () => {
    if (facts === null) {
      const lines = Array.from(Array(9).keys());
      const columns = Array.from(
        Array(props.renderColumns().props.children.length).keys(),
      );

      const renderColumns = columns.map((index: number) => {
        return (
          <td key={index}>
            <SkeletonRectangle width="full" height="6" className="mt-3" />
          </td>
        );
      });

      const renderSkeleton = lines.map((index: number) => {
        return <tr key={index}>{renderColumns}</tr>;
      });

      return renderSkeleton;
    }

    return rows.map((row: RowDataContract, index: number) => (
      <tr key={index} className="bg-white">
        {renderRowData(row)}
      </tr>
    ));
  };

  const renderRowData = (row: RowDataContract) => {
    return facts?.columns.map(
      (column: DimensionalFactsColumnContract, index: number) => {
        if (index === 0) {
          const title = row[column.id] as string;
          return (
            <td
              key={index}
              className="break-all pt-2 text-sm leading-5 font-medium text-gray-900 hover:underline cursor-pointer"
              onClick={() => {
                props.contract.addDimensionFilter({
                  dimension: column.id,
                  title: title,
                });
              }}
            >
              {title}
            </td>
          );
        }

        const value = row[column.id] as number;
        const align = column.align ? column.align : 'right';

        return (
          <td
            key={index}
            className={`${alignCol(
              align,
            )} pt-2 whitespace-nowrap text-sm leading-5 text-gray-700`}
          >
            <span
              className={classNames('block w-full h-full px-2 py-1 rounded-sm')}
            >
              {Format.number(value)}
            </span>
          </td>
        );
      },
    );
  };

  const renderBackground = () => {
    /*
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    */
    return (
      <div
        className="fixed inset-0 transition-opacity"
        aria-hidden="true"
        onClick={props.onClose}
      >
        <div className="absolute inset-0 bg-gray-500 opacity-75" />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div>
        <table className="min-w-full divide-y divide-gray-200">
          <thead>{props.renderColumns()}</thead>
          <tbody>{renderRows()}</tbody>
        </table>
      </div>
    );
  };

  function renderLoadMore() {
    if (!hasMoreData) return undefined;

    return (
      <div className="mt-4 px-4 flex items-center sm:px-0 justify-center">
        <ButtonPrimary
          label={'Load more'}
          loading={loading}
          onClick={onLoadMore}
          aria-label="Show All"
          aria-haspopup="true"
        />
      </div>
    );
  }

  const renderModal = () => {
    /*
  Modal panel, show/hide based on modal state.

  Entering: "ease-out duration-300"
    From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    To: "opacity-100 translate-y-0 sm:scale-100"
  Leaving: "ease-in duration-200"
    From: "opacity-100 translate-y-0 sm:scale-100"
    To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    */
    return (
      <div
        className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button
            type="button"
            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-elbwalker"
            onClick={props.onClose}
          >
            <span className="sr-only">Close</span>
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="flex-1 w-full">
          <div className="text-xl mb-4" id="modal-headline">
            {props.contract.title}
          </div>
          {renderContent()}
          {renderLoadMore()}
        </div>
      </div>
    );
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        {renderBackground()}

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        {renderModal()}
      </div>
    </div>
  );
};

DashboardModal.defaultProps = {};

export default DashboardModal;
