import * as React from 'react';
import { classNames } from '../../../core/service/css/class-names.service';
import TimeSeriesContract from '../../service/contracts/stats/time-series/time-series.contract';
import Format from '../../service/misc/format.service';
import GridCard from '../molecules/grid-card.component';
import SkeletonRectangle from '../../../core/components/atoms/skeletons/skeleton-rectangle.component';
import LineChartGraph from '../molecules/graphs/line-chart.component';
import WidgetRenderContract from '../../service/contracts/widget-render.contract';
import TimeSeriesRepository from '../../service/api/stats/time-series.repository';
import StatsRequestContract from '../../service/contracts/stats/stats.request.contract';
import { useEffect, useState } from 'react';
import { TrendingDownIcon, TrendingUpIcon } from '@heroicons/react/solid';
import { colClasses } from '../../service/css/col.service';
import { useLayout } from '../../../core/context/layout.context';

const TabbedLineChart: React.FC<WidgetRenderContract> = ({ ...props }) => {
  const [tabs, changeTabs] = useState<TimeSeriesContract[] | null>(null);
  const kpis = props.config?.kpi as string[];
  const [activeTab, changeTab] = useState<number>(0);
  const request = {} as StatsRequestContract;
  const { handleError } = useLayout();

  request.date_from = props.selectedDateFilter.date_from;
  request.date_to = props.selectedDateFilter.date_to;
  request.filters = props.selectedDimensionFilters;

  useEffect(() => {
    // weird useEffect ft. async pattern...
    async function init() {
      await getTabs(props.projectId, request);
    }
    init();
  }, [props.selectedDateFilter, props.selectedDimensionFilters]);

  // @TODO add datefilter and dimension filter to parameters
  const getTabs = async (projectId: string, request: StatsRequestContract) => {
    changeTabs(null);
    handleError('');

    try {
      const tabs: TimeSeriesContract[] = await Promise.all(
        kpis.map(async (kpi: string) => {
          return await TimeSeriesRepository.get(projectId, kpi, request);
        }),
      );
      changeTabs(tabs);
    } catch (error) {
      handleError(
        'Oops an error occurred. Please reload the page and try again.',
      );
    }
  };

  if (tabs === null) {
    return (
      <GridCard cols={props.width} question={props.question}>
        <div className="grid grid-cols-4 gap-4">
          <SkeletonRectangle
            width="full"
            height="24"
            className="ml-4 mr-4 mt-2"
          />
          <SkeletonRectangle
            width="full"
            height="24"
            className="ml-4 mr-4 mt-2"
          />
          <SkeletonRectangle
            width="full"
            height="24"
            className="ml-4 mr-4 mt-2"
          />
        </div>
        <SkeletonRectangle
          width="1/1" // "full" is not working
          height="80"
          className="flex flex-col ml-4 mr-4 mt-6"
        />
      </GridCard>
    );
  }

  const sumUniqueVisits = tabs[0].data.reduce(
    (prevValue, currentValue) => prevValue + currentValue.value,
    0,
  );

  if (sumUniqueVisits == 0) {
    return (
      <GridCard cols={props.width} question={props.question}>
        <div className="min-h-widget flex justify-center items-center">
          <p className="text-xl">No data</p>
        </div>
      </GridCard>
    );
  }

  const renderIconUp = (label?: string) => {
    return (
      <TrendingUpIcon
        className={`h-4.5 w-4.5 align-middle items-center mr-2 ${
          label ? 'ml-2' : ''
        }`}
      />
    );
  };

  const renderIconDown = (label?: string) => {
    return (
      <TrendingDownIcon
        className={`h-4.5 w-4.5 align-middle items-center mr-2 ${
          label ? 'ml-2' : ''
        }`}
      />
    );
  };

  const renderTabButtons = () => {
    return tabs.map((timeSeries: TimeSeriesContract, index: number) => {
      const iconColor =
        timeSeries.meta.trend <= 0 ? 'text-red-700' : 'text-green-700';

      const bg =
        index === activeTab
          ? 'bg-elbwalkerBackground'
          : 'hover:bg-elbwalkerBackground';

      return (
        <button
          key={index}
          onClick={() => changeTab(index)}
          className={classNames(
            'w-1/4 py-4 px-8 flex flex-col font-medium rounded mx-6 mt-6 focus:outline-none',
            bg,
          )}
        >
          <span className="text-gray-600">{timeSeries.meta.title}</span>
          <span className="text-gray-800 font-semibold text-2xl">
            {Format.number(timeSeries.meta.key_indicator)}
            {timeSeries.meta.unit}
          </span>
          <span className={classNames('flex items-center', iconColor)}>
            {timeSeries.meta.trend <= 0 ? renderIconDown() : renderIconUp()}
            <span>
              {Format.number(timeSeries.meta.trend)}
              {timeSeries.meta.trend_unit}
            </span>
          </span>
        </button>
      );
    });
  };

  const renderMobileTabButtons = () => {
    return tabs.map((timeSeries: TimeSeriesContract, index: number) => {
      return (
        <option key={index} selected={index === activeTab} value={index}>
          {timeSeries.meta.title}
        </option>
      );
    });
  };

  return (
    <div className={`${colClasses(props.width)} bg-white rounded-lg `}>
      {/* mobile nav */}
      <div className="sm:hidden m-2">
        <select
          aria-label="Selected tab"
          className="form-select block w-full rounded-md"
          onChange={(event) => changeTab(parseInt(event.target.value, 10))}
        >
          {renderMobileTabButtons()}
        </select>
      </div>

      {/* main nav */}
      <div className="hidden sm:block">
        <div className="">
          <nav className="-mb-px flex mx-6">{renderTabButtons()}</nav>
        </div>
      </div>

      {/* chart */}
      <div className="px-4 py-8 pr-10">
        <LineChartGraph data={tabs ? tabs[activeTab].data : []} />
      </div>
    </div>
  );
};

TabbedLineChart.defaultProps = {};

export default TabbedLineChart;
