import Axios from 'axios';
import API from '../../../../core/service/api/api.service';
import StatsRequestContract from '../../contracts/stats/stats.request.contract';
import TimeSeriesContract from '../../contracts/stats/time-series/time-series.contract';

export default class TimeSeriesRepository {
  public static async get(
    projectId: string,
    kpi: string,
    params: StatsRequestContract,
  ): Promise<TimeSeriesContract> {
    return (
      await Axios.get(
        `${API.baseUrl}/projects/${projectId}/stats/time-series/${kpi}`,
        {
          params,
        },
      )
    ).data;
  }
}
