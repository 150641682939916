import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useLayout } from '../../../core/context/layout.context';

export interface SnippetProps {
  visibleText: JSX.Element;
  copyText: string;
  center?: boolean;
}

const Snippet: React.FC<SnippetProps> = (props) => {
  const { handleSuccess } = useLayout();

  return (
    <div
      style={{ padding: '15px' }}
      className="relative mt-1 bg-gray-200 sm:rounded-lg"
    >
      <div className="flex">
        <div className="flex-grow">{props.visibleText}</div>
        <div className="flex-none">
          <CopyToClipboard
            onCopy={() => {
              handleSuccess('Copied');
            }}
            text={props.copyText}
          >
            <button
              type="button"
              aria-label="Copy to Clipboard Button"
              className={`
              ${props.center ? 'self-center' : 'self-start'}
              ml-1 focus:outline-none justify-self-end
            `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="black"
                width="18px"
                height="18px"
                className="transition duration-300 fill-current hover:text-gray-400"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M15 1H4c-1.1 0-2 .9-2 2v13c0 .55.45 1 1 1s1-.45 1-1V4c0-.55.45-1 1-1h10c.55 0 1-.45 1-1s-.45-1-1-1zm4 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm-1 16H9c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1h9c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1z" />
              </svg>
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};

Snippet.defaultProps = {};

export default Snippet;
