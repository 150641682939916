import axios from 'axios';
import API from '../../../core/service/api/api.service';
import {
  BrowseProjectEventContract,
  ProjectEvent,
  ProjectEventProperty,
  ProjectEventPropertyLite,
} from '../contracts/event.contract';

export default class ProjectEventsPropertiesRepository {
  public static async edit(
    projectId: string,
    eventProperty: ProjectEventPropertyLite,
  ): Promise<BrowseProjectEventContract> {
    return (
      await axios.put(
        `${API.baseUrl}/projects/${projectId}/events/${eventProperty.entity}/${eventProperty.action}/property/${eventProperty.property}`,
        eventProperty,
      )
    ).data;
  }

  public static async add(
    projectId: string,
    eventProperty: ProjectEventPropertyLite,
  ): Promise<BrowseProjectEventContract> {
    return (
      await axios.post(
        `${API.baseUrl}/projects/${projectId}/events/${eventProperty.entity}/${eventProperty.action}/property/${eventProperty.property}`,
        eventProperty,
      )
    ).data;
  }

  public static async delete(
    projectId: string,
    eventProperty: ProjectEventProperty,
  ): Promise<BrowseProjectEventContract> {
    return (
      await axios.delete(
        `${API.baseUrl}/projects/${projectId}/events/${eventProperty.entity}/${eventProperty.action}/property/${eventProperty.property}`,
      )
    ).data;
  }
}

export function projectEventsMapToList(
  events: BrowseProjectEventContract,
): ProjectEvent[] {
  const list: ProjectEvent[] = [];

  Object.entries(events)
    .sort()
    .map(([entity, event]) => {
      return Object.entries(event)
        .sort()
        .map(([action, settings]) => {
          list.push({ entity, action, ...settings });
        });
    });

  return list;
}
