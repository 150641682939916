import { PlusCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid';
import * as React from 'react';

export interface InfoBoxProps {
  title: string;
  link: string;
  icon: string;
  external?: true;
}

const InfoBox: React.FC<InfoBoxProps> = (props) => {
  const renderIcon = (icon: string) => {
    switch (icon) {
      case 'QuestionMarkCircleIcon':
        return (
          <QuestionMarkCircleIcon
            className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        );
      case 'PlusCircleIcon':
        return (
          <PlusCircleIcon
            className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        );
    }
  };

  return (
    <a
      href={props.link}
      target={props.external ? '_blank' : ''}
      rel="noreferrer"
      className="group inline-flex text-xs space-x-2 text-gray-500 hover:text-gray-900 bg-gray-100 rounded-full justify-between items-center px-3 ml-3"
    >
      {renderIcon(props.icon)}
      <span>{props.title}</span>
    </a>
  );
};

InfoBox.defaultProps = {};
export default InfoBox;
