import { RouteProps } from 'react-router-dom';
import Login from './core/components/pages/login/login.component';
import Signup from './core/components/pages/signup.component';
import SignupSucceeded from './core/components/pages/signup-succeeded.component';
import Dashboard from './dashboard/components/pages/dashboard.component';
import ProjectsOverview from './project/components/pages/project-overview.component';
import ProjectDetail from './project/components/pages/project-detail.component';
import ProjectSettings from './project/components/pages/project-settings.component';
import ProjectMembers from './project/components/pages/project-members.component';
import RemoteTagging from './project/components/pages/remote-tagging.component';
import ProjectEvents from './project/components/pages/project-events.component';
import ProjectDestinations from './project/components/pages/project-destinations.component';
import Snippet from './project/components/organisms/project-code-snippet.component';
import PasswordReset from './core/components/pages/password-reset.component';
import PasswordResetConfirmation from './core/components/pages/password-reset-confirmation.component';
import ProjectCreationProcess from './project/components/pages/project-creation-process/project-creation-process.component';
import DestinationCreationProcess from './project/components/pages/destination-creation-process/destination-creation-process.component';
import UserSettings from './project/components/pages/user-settings.component';

export const Paths = {
  home: '/',
  login: '/login',
  signup: '/register',
  signup_success: '/register/confirmation',
  password: {
    reset: '/password_reset',
    reset_confirmation: '/password_reset/confirmation',
  },
  user: {
    settings: '/users/:userId',
  },
  projects: {
    overview: '/projects',
    create: '/projects/create',
    detail: '/projects/:projectId',
    settings: '/projects/:projectId/settings',
    snippet: '/projects/:projectId/snippet',
    members: '/projects/:projectId/members',
    events: '/projects/:projectId/events',
    dashboards: {
      detail: '/projects/:projectId/dashboards/:dashboardId',
    },
    destinations: {
      overview: '/projects/:projectId/destinations',
      create: '/projects/:projectId/destinations/create',
      google_analytics: '/projects/:projectId/destinations/:destinationId',
    },
  },
  remote_tagging: '/projects/:projectId/remote-tagging',
  terms: 'https://www.elbwalker.com/agb',
  imprint: 'https://www.elbwalker.com/impressum-elbwalker',
  privacy: 'https://www.elbwalker.com/datenschutz',
  docs: {
    home: 'https://docs.elbwalker.com',
    walker_basics: 'https://docs.elbwalker.com/basics/the-walker-basics',
    destinations: {
      collect: 'https://docs.elbwalker.com/destinations/warehouses/collect',
      google_tagmanager:
        'https://docs.elbwalker.com/destinations/tag-management-tools/google-tag-manager',
    },
  },
};

export const Routes: RouteProps[] = [
  { path: Paths.home, component: ProjectsOverview, exact: true },

  { path: Paths.login, component: Login, exact: true },
  { path: Paths.signup, component: Signup, exact: true },
  { path: Paths.user.settings, component: UserSettings, exact: true },
  {
    path: Paths.signup_success,
    component: SignupSucceeded,
    exact: true,
  },

  { path: Paths.projects.dashboards.detail, component: Dashboard, exact: true },

  { path: Paths.projects.overview, component: ProjectsOverview, exact: true },
  {
    path: Paths.projects.create,
    component: ProjectCreationProcess,
    exact: true,
  },
  { path: Paths.projects.settings, component: ProjectSettings, exact: true },
  { path: Paths.projects.detail, component: ProjectDetail, exact: true },
  { path: Paths.projects.snippet, component: Snippet, exact: true },
  { path: Paths.projects.members, component: ProjectMembers, exact: true },
  { path: Paths.projects.events, component: ProjectEvents, exact: true },

  { path: Paths.password.reset, component: PasswordReset, exact: true },
  {
    path: Paths.password.reset_confirmation,
    component: PasswordResetConfirmation,
    exact: true,
  },
  {
    path: Paths.projects.destinations.overview,
    component: ProjectDestinations,
    exact: true,
  },
  {
    path: Paths.projects.destinations.create,
    component: DestinationCreationProcess,
    exact: true,
  },
  {
    path: Paths.remote_tagging,
    component: RemoteTagging,
    exact: true,
  },
];
export const Navigation = [{ route: Paths.home, title: 'Overview' }];
