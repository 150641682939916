export function routeTo(route: string, parameters: any): string {
  for (const parameter in parameters) {
    if (Object.prototype.hasOwnProperty.call(parameters, parameter)) {
      const key = ':' + parameter;
      route = route.replace(key, parameters[parameter]);
    }
  }

  return route;
}
