import { ReactElement } from 'react';
import loading_indicator from '../../../assets/icons/loading-indicator.svg';

export interface ButtonPrimaryProps {
  label: string;
  disabled?: boolean;
  icon?: ReactElement;
  iconRight?: boolean;
  loading: boolean;
  onClick?: () => void;
  wide?: boolean;
  red?: boolean;
  margin?: string;
  textSize?: string;
  className?: string;
}

export const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  label,
  loading = false,
  wide = false,
  red = false,
  margin,
  textSize,
  ...props
}) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      disabled={props.disabled}
      className={`
      ${props.disabled ? 'opacity-50' : 'hover:bg-elbwalker-600'}
      ${props.className ? props.className : ''}
      ${loading ? 'pl-4 pr-4' : 'px-4'}
      ${wide ? 'w-full' : ''}
      ${margin ? 'ml-3' : ''}
      ${
        red
          ? ' bg-red-600 focus:ring-red-500 hover:bg-red-700'
          : ' bg-elbwalker focus:ring-elbwalker'
      }
      ${margin}
      ${textSize ? { textSize } : 'text-sm'}
      inline-flex justify-center items-center py-2 border border-transparent shadow-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2
      `}
    >
      {loading && (
        <img
          className="animate-spin h-5 w-5 mr-2"
          src={loading_indicator}
          alt=""
        />
      )}
      {props.icon && !props.iconRight ? props.icon : ''}
      {label}
      {props.icon && props.iconRight ? props.icon : ''}
    </button>
  );
};

ButtonPrimary.defaultProps = { iconRight: false };

export default ButtonPrimary;
