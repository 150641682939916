import { ReactElement } from 'react';

export interface ButtonSecondaryProps {
  label?: string;
  disabled?: boolean;
  loading?: boolean;
  icon?: ReactElement;
  iconRight?: boolean;
  onClick?: () => void;
  className?: string;
  id?: string;
  expanded?: boolean;
  haspopup?: boolean;
  alignStart?: boolean;
}

export const ButtonSecondary: React.FC<ButtonSecondaryProps> = ({
  alignStart = false,
  loading = false,
  ...props
}) => {
  const disabled = props.disabled || loading;
  return (
    <button
      type="button"
      onClick={props.onClick}
      disabled={disabled}
      className={`
        ${props.className ? props.className : ''}
        ${disabled ? 'opacity-50' : 'hover:bg-gray-50'}
        ${loading ? 'pl-4 pr-4' : 'px-4'}
        ${
          alignStart ? '' : 'justify-center'
        } inline-flex  items-center py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-500 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-elbwalker`}
      id={props.id}
      aria-expanded={props.expanded}
      aria-haspopup={props.haspopup}
    >
      {loading && (
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-5 w-5 mr-2 border-b-2 border-gray-900"></div>
        </div>
      )}
      {props.icon && !props.iconRight ? props.icon : ''}
      {props.label ? props.label : ''}
      {props.icon && props.iconRight ? props.icon : ''}
    </button>
  );
};

ButtonSecondary.defaultProps = { iconRight: false };

export default ButtonSecondary;
