import * as React from 'react';
import { BrowserRouter, Route, RouteProps, Switch } from 'react-router-dom';
import { AuthProvider } from '../../context/auth.context';
import { Paths } from '../../../routes';
import PrivateRoute from '../molecules/private-route.component';
import NotFound from '../pages/not-found.component';
import { LayoutProvider } from '../../context/layout.context';

interface Props {
  routes: RouteProps[];
}

const BrowserRoutes: React.FC<Props> = (props) => {
  const publicRoutes = [
    Paths.signup,
    Paths.signup_success,
    Paths.login,
    Paths.password.reset,
    Paths.password.reset_confirmation,
  ];

  const routes = props.routes.map((route, index) => {
    return publicRoutes.includes(route.path as string) ? (
      <Route
        key={index}
        exact={route.exact}
        path={route.path}
        component={route.component}
      />
    ) : (
      <PrivateRoute
        key={index}
        exact={route.exact}
        path={route.path}
        component={route.component}
      />
    );
  });

  const notFound = <Route key={routes.length} component={NotFound} />;
  routes.push(notFound);

  return (
    <BrowserRouter>
      <LayoutProvider>
        <AuthProvider>
          <Switch>{routes}</Switch>
        </AuthProvider>
      </LayoutProvider>
    </BrowserRouter>
  );
};

export default BrowserRoutes;
