import { useEffect, useState } from 'react';
import WidgetRenderContract from '../../../service/contracts/widget-render.contract';
import DimensionalFactsRepository from '../../../service/api/stats/dimensional-facts.repository';
import DimensionalFactsRequestParamsContract from '../../../service/contracts/stats/dimensional-facts/dimensional-facts-request-params.contract';
import DimensionalFactsContract, {
  RowDataContract,
} from '../../../service/contracts/stats/dimensional-facts/dimensional-facts.contract';
import { useLayout } from '../../../../core/context/layout.context';

const DashboardModalModel = (contract: WidgetRenderContract) => {
  const limit = 20;

  const [requestedOffset, setRequestedOffset] = useState<number>(0);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [facts, setFacts] = useState<DimensionalFactsContract | null>(null);
  const [rows, setRows] = useState<RowDataContract[]>([]);
  const [loading, setLoading] = useState(false);
  const { handleError } = useLayout();

  useEffect(() => {
    handleError('');
    setLoading(true);

    retrieveFacts()
      .then((factsResponse) => {
        setFacts(factsResponse);

        const factRows = factsResponse?.rows ? factsResponse.rows : [];
        const combinedFactRows = rows.concat(factRows);

        setHasMoreData(factsResponse.meta.more);
        setRows(combinedFactRows);
      })
      .catch(() => {
        handleError(
          'Oops an error occurred. Please reload the page and try again.',
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [requestedOffset]);

  const retrieveFacts = async () => {
    const request = contract.config as DimensionalFactsRequestParamsContract;
    request.date_from = contract.selectedDateFilter.date_from;
    request.date_to = contract.selectedDateFilter.date_to;
    request.filters = contract.selectedDimensionFilters;

    request.offset = requestedOffset;
    request.limit = limit;

    return await DimensionalFactsRepository.read(
      contract.projectId,
      contract.config?.dimension,
      request,
    );
  };

  function onLoadMore() {
    setRequestedOffset(rows.length);
  }

  return { facts, rows, hasMoreData, onLoadMore, loading };
};

export default DashboardModalModel;
