import * as React from 'react';

export interface SkeletonRoundProps {
  size: number;
}

export const SkeletonRound: React.FC<SkeletonRoundProps> = ({ size = 12 }) => {
  return (
    <div
      className={`animate-pulse rounded-full bg-gray-200 h-${size} w-${size}`}
    />
  );
};

export default SkeletonRound;
