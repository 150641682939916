import * as React from 'react';
import Snippet from '../molecules/snippet.component';

export interface WalkerSnippetProps {
  projectId: string;
}

const WalkerSnippet: React.FC<WalkerSnippetProps> = (props) => {
  const red = { color: 'rgb(239, 99, 114)' } as React.CSSProperties;
  const orange = { color: 'rgb(208, 145, 90)' } as React.CSSProperties;
  const grey = { color: 'rgb(170, 178, 192)' } as React.CSSProperties;
  const green = { color: 'rgb(137, 194, 110)' } as React.CSSProperties;

  return (
    <Snippet
      center={true}
      copyText={`<script async class="elbwalker" data-project="${props.projectId}" src="https://walker.p.elbwalkerapis.com/walker.js"></script>`}
      visibleText={
        <div className="overflow-hidden">
          <span style={grey}>&lt;</span>
          <span style={red}>script</span>
          <span style={orange}> async</span>
          <span style={orange}> class</span>
          <span style={grey}>=</span>
          <span style={green}>&quot;elbwalker&quot;</span>
          <span style={orange}> data-project</span>
          <span style={grey}>=</span>
          <span style={green}>&quot;{props.projectId}&quot;</span>
          <span style={orange}> src</span>
          <span style={grey}>=</span>
          <span style={green}>
            &quot;https://walker.p.elbwalkerapis.com/walker.js&quot;
          </span>
          <span style={grey}>&gt;&lt;/</span>
          <span style={red}>script</span>
          <span style={grey}>&gt;</span>
        </div>
      }
    />
  );
};

WalkerSnippet.defaultProps = {};

export default WalkerSnippet;
