import Axios from 'axios';
import API from '../../../core/service/api/api.service';
import { BrowseProjectMembersContract } from '../contracts/project.contract';

export default class ProjectMembersRepository {
  public static async browse(
    projectId: string,
  ): Promise<BrowseProjectMembersContract> {
    return (await Axios.get(`${API.baseUrl}/projects/${projectId}/members`))
      .data;
  }

  public static async add(
    projectId: string,
    email: string,
  ): Promise<BrowseProjectMembersContract> {
    return (
      await Axios.post(`${API.baseUrl}/projects/${projectId}/members`, {
        email,
      })
    ).data;
  }

  public static async delete(
    projectId: string,
    memberId: string,
  ): Promise<BrowseProjectMembersContract> {
    return (
      await Axios.delete(
        `${API.baseUrl}/projects/${projectId}/members/${memberId}`,
      )
    ).data;
  }
}
