import LabelInput from '../../../../../core/components/atoms/labels/label-input.component';
import { projectEventsMapToList } from '../../../../service/api/project-events-properties.repository';
import { ProjectEventsContract } from '../../../../service/contracts/event.contract';
import { ProjectDestinationEventMapping } from '../../../../service/contracts/project-destination.contract';

export interface DestinationRowProps {
  events?: ProjectEventsContract;
  mapping?: ProjectDestinationEventMapping;
  onChange: (event: EventTarget & HTMLInputElement) => void;
}

const DestinationEventMapping: React.FC<DestinationRowProps> = (props) => {
  if (!props.events) return null;

  const rows = projectEventsMapToList(props.events);
  const mapping = props.mapping || {};

  const renderedEvents = rows.map((event) => {
    const eventId = `${event.entity}_${event.action}`;
    const checked =
      mapping[event.entity] && mapping[event.entity][event.action];

    return (
      <div key={eventId} className="relative flex items-start py-4">
        <div className="min-w-0 flex-1 text-sm">
          <label htmlFor={eventId} className="font-medium text-gray-700">
            {event.entity} {event.action}
          </label>
        </div>
        <div className="ml-3 flex items-center h-5">
          <input
            id={eventId}
            name={eventId}
            defaultChecked={checked}
            onChange={(event) => props.onChange(event.target)}
            type="checkbox"
            className="focus:ring-elbwalker h-4 w-4 text-elbwalker border-gray-300 rounded"
          />
        </div>
      </div>
    );
  });

  return (
    <fieldset className="mt-6 mb-4 w-full">
      <LabelInput id={'events'} value="Events" />
      <p className="text-sm text-gray-600 max-w">
        Select all events that should be send to this destination
      </p>
      <div className="divide-y divide-gray-200">{renderedEvents}</div>
    </fieldset>
  );
};

DestinationEventMapping.defaultProps = {};

export default DestinationEventMapping;
