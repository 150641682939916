import * as React from 'react';
import { RadioGroup } from '@headlessui/react';

export interface RadioCardEntry {
  key: string;
  title: string;
  description: string;
  detailText?: string;
  detailDescription?: string;
  hasTextArea?: boolean;
  textAreaPlaceholder?: string;
}

export interface GroupsRadioCardsProps {
  entries: RadioCardEntry[];
  selectedIndex?: number;
  onSelected: (selectedKey: string, text?: string) => void;
}

const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ');

export const GroupsRadioCards: React.FC<GroupsRadioCardsProps> = ({
  entries = [],
  ...props
}) => {
  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(
    props.selectedIndex,
  );
  const [textValues, setTextValues] =
    React.useState<{ [key: string]: string }>();

  function setTextValuesForKey(key: string, value: string) {
    let currentValues = textValues;
    if (currentValues != undefined) {
      currentValues[key] = value;
    } else {
      currentValues = {
        key: value,
      };
    }
    setTextValues(currentValues);
  }

  function renderContent() {
    return (
      <RadioGroup
        value={selectedIndex ? entries[selectedIndex] : undefined}
        onChange={(entry: RadioCardEntry) => {
          props.onSelected(entry.key, textValues ? textValues[entry.key] : '');
          setSelectedIndex(entries.indexOf(entry));
        }}
      >
        <div className="space-y-4">
          {entries.map((entry, index) => (
            <RadioGroup.Option
              key={entry.key}
              value={entry}
              className={classNames(
                index === selectedIndex
                  ? 'ring-2 ring-offset-2 ring-elbwalker'
                  : '',
                'relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 hover:border-gray-400 sm:flex sm:justify-between focus:outline-none',
              )}
            >
              <div className="grid w-full grid-cols-2">
                <div className="flex items-center">
                  <div className="text-sm">
                    <RadioGroup.Label
                      as="p"
                      className="font-medium text-gray-900"
                    >
                      {entry.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="div" className="text-gray-500">
                      <p className="sm:inline">{entry.description}</p>{' '}
                    </RadioGroup.Description>
                  </div>
                </div>
                <RadioGroup.Description
                  as="div"
                  className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right"
                >
                  {entry.detailText ? (
                    <div className="font-medium text-gray-900">
                      {entry.detailText}
                    </div>
                  ) : (
                    ''
                  )}
                  {entry.detailDescription ? (
                    <div className="ml-1 text-gray-500 sm:ml-0">
                      {entry.detailDescription}
                    </div>
                  ) : (
                    ''
                  )}
                </RadioGroup.Description>
                {entry.hasTextArea ? (
                  <div className="col-span-2 mt-3">
                    <textarea
                      id={'text-' + entry.key}
                      name={'text-' + entry.key}
                      rows={3}
                      placeholder={entry.textAreaPlaceholder}
                      onChange={(event) => {
                        setTextValuesForKey(entry.key, event.target.value);
                        props.onSelected(entry.key, event.target.value);
                      }}
                      disabled={selectedIndex !== index}
                      className={`flex w-full shadow-sm focus:ring-elbwalker focus:border-elbwalker sm:text-sm placeholder-gray-400 ${
                        selectedIndex !== index
                          ? 'border-gray-300'
                          : 'border-gray-100 text-gray-300 resize-none'
                      } rounded-md`}
                      defaultValue={''}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    );
  }

  return renderContent();
};

export default GroupsRadioCards;
