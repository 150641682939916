import * as React from 'react';
import Alert from '../../atoms/alerts/alert.component';
import { CheckCircleIcon } from '@heroicons/react/solid';

export interface AlertSuccessProps {
  text: string;
  delay?: number;
}

const AlertSuccess: React.FC<AlertSuccessProps> = (props) => {
  return (
    <Alert
      text={props.text}
      delay={props.delay}
      backgroundColor="bg-green-50"
      textColor="text-green-800"
      buttonColor="bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600"
      icon={
        <CheckCircleIcon
          className="h-5 w-5 text-green-400"
          aria-hidden="true"
        />
      }
    />
  );
};

AlertSuccess.defaultProps = {};

export default AlertSuccess;
