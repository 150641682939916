import firebase from 'firebase/app';
import axios, { AxiosRequestConfig } from 'axios';

class API {
  public static baseUrl: string = process.env.REACT_APP_API_BASE_URL as string;

  public static async refreshToken(user: firebase.User) {
    return await user.getIdToken(true);
  }

  public static async getToken() {
    const user = firebase.auth().currentUser;
    return user ? await user.getIdToken() : null;
  }

  public static async getTokenResult(user: firebase.User) {
    return await user.getIdTokenResult();
  }

  public static init() {
    axios.interceptors.request.use(
      async (req: AxiosRequestConfig) => {
        // console.log(`${req.method} ${req.url}`);
        const token = await API.getToken();
        if (token) req.headers.authorization = `Bearer ${token}`;

        return Promise.resolve(req);
      },
      (error: any) => {
        return Promise.reject(error);
      },
    );

    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 401) {
          // @TODO don't be that rude
          // firebase.auth().signOut();
          return Promise.reject({});
        } else {
          return Promise.reject(error);
        }
      },
    );
  }
}

export default API;
