import * as React from 'react';

export interface LabelInputProps {
  id: string;
  value: string;
}

export const LabelInput: React.FC<LabelInputProps> = ({
  id = 'id',
  ...props
}) => {
  return (
    <label htmlFor={id} className="block text-sm font-bold text-gray-700">
      {props.value}
    </label>
  );
};

export default LabelInput;
