import * as React from 'react';
import { useEffect, useState } from 'react';
import ButtonPrimary from '../../../core/components/atoms/buttons/button-primary.component';
import ButtonSecondary from '../../../core/components/atoms/buttons/button-secondary.component';
import { CalendarIcon } from '@heroicons/react/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

export interface DatePickerProps {
  onChangedDate: (from: Date, to: Date) => void;
}

type DayInMonth = {
  date: Date;
  isInRange: boolean;
  isInSelectedRange: boolean;
  isFuture: boolean;
  isExactDayMin: boolean;
  isExactDayMax: boolean;
  isSelectedExactDayMin: boolean;
  isSelectedExactDayMax: boolean;
};

type SidebarEntryType = {
  name: string;
  from: Date;
  to: Date;
};

const weekDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const today = new Date();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(today.getUTCMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(today.getFullYear());
  const [selectedMinDate, setSelectedMinDate] = useState<Date | undefined>(
    calculateDateMinusDays(7),
  );
  const [selectedMaxDate, setSelectedMaxDate] = useState<Date>(today);
  const [updatedMaxDate, setUpdatedMaxDate] = useState<Date | undefined>(
    undefined,
  );
  const [updatedMinDate, setUpdatedMinDate] = useState<Date | undefined>(
    undefined,
  );
  const [daysInMonth, setDaysInMonth] = useState<DayInMonth[][]>(
    defineDaysForMonth(),
  );
  //defineDaysForMonth(),

  useEffect(() => {
    setDaysInMonth(defineDaysForMonth());
  }, [
    selectedYear,
    selectedMonth,
    selectedMinDate,
    selectedMaxDate,
    updatedMinDate,
    updatedMaxDate,
  ]);

  function sameDay(d1: Date, d2: Date | undefined) {
    return (
      d2 != undefined &&
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }

  function calculateDateMinusDays(minusDays: number) {
    const date = new Date(today);
    date.setDate(date.getDate() - minusDays);
    return date;
  }

  function calculateDateMinusMonths(minusMonths: number, day: number) {
    const date = new Date(today);
    const months = day == 0 ? minusMonths - 1 : minusMonths;
    date.setMonth(date.getMonth() - months);
    date.setDate(day);
    return date;
  }

  const sidebarEntries: SidebarEntryType[] = [
    {
      name: 'Today',
      from: today,
      to: today,
    },
    {
      name: 'Yesterday',
      from: calculateDateMinusDays(1),
      to: calculateDateMinusDays(1),
    },
    {
      name: 'Last 7 Days',
      from: calculateDateMinusDays(7),
      to: today,
    },
    {
      name: 'Last 30 Days',
      from: calculateDateMinusDays(30),
      to: today,
    },
    {
      name: 'This Month',
      from: new Date(today.getFullYear(), today.getMonth(), 1),
      to: today,
    },
    {
      name: 'Last Month',
      from: calculateDateMinusMonths(1, 1),
      to: calculateDateMinusMonths(1, 0),
    },
    {
      name: 'This Year',
      from: new Date(today.getFullYear(), 0, 1),
      to: today,
    },
  ];

  function previousMonth() {
    if (selectedMonth != 1) {
      setSelectedMonth(selectedMonth - 1);
    } else {
      setSelectedMonth(12);
      setSelectedYear(selectedYear - 1);
    }
  }

  function nextMonth() {
    if (selectedMonth != 12) {
      setSelectedMonth(selectedMonth + 1);
    } else {
      setSelectedMonth(1);
      setSelectedYear(selectedYear + 1);
    }
  }

  function defineDaysForMonth(): DayInMonth[][] {
    const maxDays = new Date(selectedYear, selectedMonth, 0).getDate();
    const firstDay = new Date(selectedYear, selectedMonth - 1, 1).getUTCDay();

    let dayIndex = 0;
    const weeks: DayInMonth[][] = [];
    let week: DayInMonth[] = [];

    while (dayIndex < maxDays) {
      const weekday = (firstDay + dayIndex) % 7;
      dayIndex++;

      const date = new Date(selectedYear, selectedMonth - 1, dayIndex);
      week.push({
        date: date,
        isInRange:
          updatedMaxDate != undefined &&
          updatedMinDate != undefined &&
          date >= updatedMinDate &&
          date <= updatedMaxDate,
        isInSelectedRange:
          selectedMinDate != undefined &&
          date >= selectedMinDate &&
          date <= selectedMaxDate,
        isFuture: date > today,
        isExactDayMin:
          updatedMinDate != undefined && sameDay(updatedMinDate, date),
        isExactDayMax:
          updatedMaxDate != undefined && sameDay(updatedMaxDate, date),
        isSelectedExactDayMin:
          selectedMinDate != undefined && sameDay(selectedMinDate, date),
        isSelectedExactDayMax: sameDay(selectedMaxDate, date),
      } as DayInMonth);

      if (weekday === 6) {
        weeks.push(week);
        week = [];
      } else if (dayIndex == maxDays) {
        weeks.push(week);
      }
    }

    return weeks;
  }

  function handleSelection(date: Date) {
    if (date < today || sameDay(today, date)) {
      if (!updatedMaxDate) {
        // First selection.
        setUpdatedMaxDate(date);
      } else {
        if (date > updatedMaxDate) {
          // First selection.
          if (!updatedMinDate) {
            setUpdatedMinDate(updatedMaxDate);
            setUpdatedMaxDate(date);
          } else {
            // Reset.
            setUpdatedMinDate(undefined);
            setUpdatedMaxDate(date);
          }
        } else {
          setUpdatedMinDate(date);
        }
      }
    }
  }

  function renderContentForWeek(week: DayInMonth[]) {
    return weekDays.map((day, dayIndex) => {
      const weekDayFiltered = week.filter(
        (weekDay) => weekDay.date.getUTCDay() == dayIndex,
      );
      const weekDay =
        weekDayFiltered.length > 0 ? weekDayFiltered[0] : undefined;
      return weekDay ? (
        <div
          className={`w-full h-full z-0 bg-clip-padding
          ${
            weekDay.isInRange
              ? 'bg-table-blue-1'
              : weekDay.isInSelectedRange
              ? 'bg-gray-100'
              : ''
          }
          `}
          key={weekDay.date.getUTCDay()}
        >
          <button
            onClick={() => {
              handleSelection(weekDay.date);
            }}
            className={`z-10 ${
              weekDay.isFuture
                ? 'opacity-50'
                : 'hover:bg-elbwalker hover:ring-offset-2 hover:ring-2 hover:ring-elbwalker hover:outline-none hover:text-white'
            }
                        ${
                          (weekDay.isSelectedExactDayMax ||
                            weekDay.isSelectedExactDayMin) &&
                          !weekDay.isInRange &&
                          !(weekDay.isExactDayMax || weekDay.isExactDayMin)
                            ? 'bg-gray-300'
                            : ''
                        }
                        ${
                          weekDay.isExactDayMax || weekDay.isExactDayMin
                            ? 'bg-elbwalker text-white'
                            : ''
                        }
                        focus:outline-none
                        p-2 items-center text-center w-full h-full align-middle`}
          >
            {weekDay.date.getDate()}
          </button>
        </div>
      ) : (
        <div className="w-full h-full" />
      );
    });
  }

  function formatDate(date: Date): string {
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  function reset() {
    setUpdatedMinDate(undefined);
    setUpdatedMaxDate(undefined);
  }

  function renderSidebar() {
    return (
      <nav className="space-y-1 mr-4" aria-label="Sidebar">
        {sidebarEntries.map((entry, index) => (
          <a
            href="#"
            key={index}
            className={`${
              sameDay(entry.from, selectedMinDate) &&
              sameDay(entry.to, selectedMaxDate)
                ? 'bg-gray-100'
                : ''
            } text-gray-900 flex items-center px-3 py-2 text-sm font-medium rounded-md`}
            aria-current="page"
            onClick={() => {
              setUpdatedMinDate(entry.from);
              setUpdatedMaxDate(entry.to);
              setSelectedYear(entry.to.getFullYear());
              setSelectedMonth(entry.to.getMonth() + 1);
            }}
          >
            <span className="truncate">{entry.name}</span>
          </a>
        ))}
      </nav>
    );
  }

  function applyChanges() {
    if (!updatedMaxDate) return;

    props.onChangedDate(
      startOfDay(updatedMinDate ? updatedMinDate : updatedMaxDate),
      endOfDay(updatedMaxDate),
    );

    setSelectedMinDate(updatedMinDate);
    setSelectedMaxDate(updatedMaxDate);
    reset();

    setShowDatePicker(false);
  }

  function startOfDay(date: Date): Date {
    const start = date;
    start.setHours(0, 0, 0, 0);
    return start;
  }

  function endOfDay(date: Date): Date {
    const end = date;
    const now = new Date();
    end.setHours(23, 59, 59, 999);
    return end > now ? now : end;
  }

  function renderDatePickerPopup() {
    if (!showDatePicker) return undefined;

    const renderIconRight = (label?: string) => {
      return (
        <ChevronRightIcon
          className={`h-4.5 w-4.5 align-middle items-center ${
            label ? 'ml-2' : ''
          }`}
        />
      );
    };

    const renderIconLeft = (label?: string) => {
      return (
        <ChevronLeftIcon
          className={`h-4.5 w-4.5 align-middle items-center ${
            label ? 'ml-2' : ''
          }`}
        />
      );
    };

    return (
      <div className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 divide-gray-100 rounded-md ring-gray-300 ring-opacity-5 z-10 px-4 py-2">
        <div className="flex flex-row flex-nowrap py-2">
          {renderSidebar()}
          <div className="flex flex-col w-auto">
            <div className="flex flex-row mb-2 w-96">
              <ButtonSecondary
                icon={renderIconLeft()}
                onClick={previousMonth}
              />
              <span className="flex text-center w-full justify-center items-center text-gray-800 font-semibold text-xl">
                {new Date(selectedYear, selectedMonth, 0).toLocaleString(
                  'en-US',
                  {
                    month: 'long',
                  },
                )}{' '}
                {selectedYear}
              </span>
              {today >= new Date(selectedYear, selectedMonth, 0) ? (
                <ButtonSecondary icon={renderIconRight()} onClick={nextMonth} />
              ) : (
                ''
              )}
            </div>

            <div className="flex flex-col justify-between ">
              <div className="inline-flex flex-row justify-between items-center align-middle">
                {weekDays.map((day) => (
                  <div
                    className="text-gray-600 p-2 items-center text-center w-12 font-semibold items-center align-middle h-full"
                    key={day}
                  >
                    {day}
                  </div>
                ))}
              </div>
              {daysInMonth.map((week, weekNumber) => {
                return (
                  <div
                    key={weekNumber}
                    className="inline-flex flex-row justify-between align-middle py-1"
                  >
                    {renderContentForWeek(week)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-row py-2">
          <div className="flex-1">
            <ButtonSecondary
              label="Reset"
              onClick={reset}
              disabled={!updatedMaxDate}
            />
          </div>
          <div className="flex-row float-right self-center mr-2">
            {renderDateText(updatedMinDate, updatedMaxDate)}
          </div>
          <div className="flex-row float-right space-x-2">
            {/* TODO Handle loading */}
            <ButtonPrimary
              label="Apply"
              loading={false}
              onClick={applyChanges}
              disabled={!updatedMaxDate}
            />
          </div>
        </div>
      </div>
    );
  }

  function renderDateText(minDate?: Date, maxDate?: Date): string | undefined {
    if (!maxDate) return undefined;

    const sidebarEntry = sidebarEntries.filter(
      (e) => sameDay(e.from, minDate) && sameDay(e.to, maxDate),
    );
    if (sidebarEntry.length > 0) {
      return sidebarEntry[0].name;
    } else {
      if (sameDay(maxDate, minDate) || !minDate) {
        return formatDate(maxDate);
      } else {
        return formatDate(minDate) + ' - ' + formatDate(maxDate);
      }
    }
  }

  return (
    <div className="relative content-end">
      <button
        type="button"
        className="inline-flex w-full justify-end items-center px-4 py-2 rounded-md border border-gray-300 shadow-sm  bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-elbwalker"
        id="dashboard-date-picker"
        aria-label="Date picker switch"
        aria-haspopup="true"
        onClick={() => {
          setShowDatePicker(!showDatePicker);
        }}
      >
        {renderDateText(selectedMinDate, selectedMaxDate)}
        <CalendarIcon className="h-4.5 w-4.5 align-middle items-center ml-2 -mr-1" />
      </button>
      {renderDatePickerPopup()}
    </div>
  );
};

DatePicker.defaultProps = {};

export default DatePicker;
