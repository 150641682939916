import * as React from 'react';

import WalkerSnippet from './walker-snippet.component';

export interface ProjectCodeSnippetProps {
  projectId: string;
}

const ProjectCodeSnippet: React.FC<ProjectCodeSnippetProps> = (props) => {
  return (
    <div className="mt-8">
      <WalkerSnippet projectId={props.projectId} />
    </div>
  );
};

ProjectCodeSnippet.defaultProps = {};

export default ProjectCodeSnippet;
