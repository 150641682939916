import * as React from 'react';
import { TimeSeriesDataContract } from '../../../service/contracts/stats/time-series/time-series.contract';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import * as tailwindConfig from '../../../../symlinks/tailwind';

export interface LineChartGraphProps {
  data: TimeSeriesDataContract[];
}

const LineChartGraph: React.FC<LineChartGraphProps> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tickMargin={15}
          tick={{ fill: tailwindConfig.theme.colors.gray[500] }}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickMargin={15}
          tick={{ fill: tailwindConfig.theme.colors.gray[500] }}
          padding={{ top: 15, bottom: 5 }}
        />
        <CartesianGrid
          vertical={true}
          horizontal={false}
          stroke={tailwindConfig.theme.colors.gray[300]}
        />
        <Tooltip
          wrapperStyle={{
            boxShadow:
              '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
            borderRadius: '0.625rem',
            padding: '0.5rem',
            background: 'white',
          }}
          contentStyle={{ borderRadius: '0.625rem', border: '0' }}
        />

        <Line
          type="monotone"
          dataKey="value"
          stroke={tailwindConfig.theme.colors.elbwalker.DEFAULT}
          activeDot={{ r: 8 }}
          dot={false}
          strokeWidth={3}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

LineChartGraph.defaultProps = {};

export default LineChartGraph;
