import * as React from 'react';
import ButtonPrimary from '../../../../../core/components/atoms/buttons/button-primary.component';
import ButtonSecondary from '../../../../../core/components/atoms/buttons/button-secondary.component';
import FormLabeledInput from '../../../../../core/components/molecules/forms/form-labeled-input.component';
import {
  ProjectDestinationEventMapping,
  ProjectDestinationGoogleTagmanagerConfig,
} from '../../../../service/contracts/project-destination.contract';
import DestinationEventMapping from '../../../molecules/tables/destinations/destination-event-mapping.component';
import { ProjectDestinationModalProps } from '../../../pages/project-destinations.component';

const GoogleTagmanagerDestinationModal: React.FC<
  ProjectDestinationModalProps
> = (props) => {
  const config = props.destination
    .config as ProjectDestinationGoogleTagmanagerConfig;
  const [mapping, setMapping] = React.useState<ProjectDestinationEventMapping>(
    config.mapping || {},
  );

  function getResult(): ProjectDestinationGoogleTagmanagerConfig {
    return { mapping, variable: 'dataLayer' };
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          ​
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 mx-4 sm:text-left w-full">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Google Tag Manager Destination Settings
              </h3>
              <div className="mt-6 mb-2">
                <FormLabeledInput
                  id="variable"
                  title="Variable"
                  value="dataLayer"
                  disabled={true}
                  required={false}
                  onChange={() => {
                    // Not possible while disabled. Do nothing.
                  }}
                  loading={false}
                  type={'text'}
                />
              </div>

              <DestinationEventMapping
                events={props.events}
                mapping={config.mapping}
                onChange={(event) => {
                  const newMapping = props.onEventClick(mapping, event);
                  setMapping(newMapping);
                }}
              />
            </div>
          </div>

          <div className="mt-5 sm:mt-4 flex flex-row">
            <ButtonSecondary
              label="Cancel"
              onClick={props.onCancelClick}
              className="justify-self-end"
            />
            <div className="flex-auto " />
            <ButtonSecondary
              label="Save"
              className="justify-self-end"
              onClick={() => {
                props.onSaveClick(getResult());
              }}
              disabled={props.loadingPrimary || props.loadingSecondary}
              loading={props.loadingSecondary}
            />
            <ButtonPrimary
              label="Save &amp; Activate"
              className="ml-3"
              onClick={() => {
                props.onSaveClick(getResult(), true);
              }}
              disabled={props.loadingPrimary || props.loadingSecondary}
              loading={props.loadingPrimary}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

GoogleTagmanagerDestinationModal.defaultProps = {};

export default GoogleTagmanagerDestinationModal;
