import { useState } from 'react';

const HighlightTableModel = () => {
  const [showModal, setShowModal] = useState(false);
  const [showAll, setShowAll] = useState(false);

  function onShowAll() {
    setShowModal(true);
  }

  function onCloseAll() {
    setShowModal(false);
  }

  return { showAll, setShowAll, showModal, onShowAll, onCloseAll };
};

export default HighlightTableModel;
