import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ButtonPrimary from '../atoms/buttons/button-primary.component';

export interface NotFoundProps {
  className?: string; // not in use yet, only for storybook
}

const NotFound: React.FC<NotFoundProps> = () => {
  const [loading, setLoading] = useState(false);

  return (
    <section className="bg-gray-50 overflow-hidden">
      <div className="w-screen h-screen flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-bold text-gray-900 mb-4">
          <p>404 - Page not found</p>
        </div>
        <div className="max-w-3xl mx-auto text-center text-xl leading-9 font-medium text-gray-900">
          <p>The page you are looking for does not exist or has been moved.</p>
        </div>
        <footer className="mt-8">
          <div className="md:flex md:items-center md:justify-center">
            <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
              <Link to="/">
                <ButtonPrimary
                  label="&larr; Go back"
                  loading={loading}
                  disabled={loading}
                  onClick={() => setLoading(true)}
                />
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </section>
  );
};

NotFound.defaultProps = {};

export default NotFound;
