import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ProjectContract } from '../../../../project/service/contracts/project.contract';
import { Paths } from '../../../../routes';
import UserContract from '../../../../user/service/contracts/user.contract';
import { classNames } from '../../../service/css/class-names.service';
import { Menu, Transition } from '@headlessui/react';
import {
  AdjustmentsIcon,
  ChevronDownIcon,
  PlusCircleIcon,
  UserGroupIcon,
  CodeIcon,
} from '@heroicons/react/solid';
import ProjectRepository from '../../../../project/service/api/project.repository';
import { routeTo } from '../../../service/route/route.service';
import { AuthContextProps, useAuth } from '../../../context/auth.context';
import SkeletonRectangle from '../../atoms/skeletons/skeleton-rectangle.component';
import { useLayout } from '../../../context/layout.context';

export interface ProjectSwitchProps {
  currentUser: UserContract;
  projectId: string;
}

const ProjectMenu: React.FC<ProjectSwitchProps> = (props) => {
  const { handleCurrentProject } = useAuth() as AuthContextProps;
  const { handleError } = useLayout();
  const [loadingProject, setLoadingProject] = useState(true);
  const [project, changeProject] = useState<ProjectContract | undefined>(
    undefined,
  );

  const loadProject = async (projectId?: string) => {
    if (!projectId) return;

    // Simple caching
    const currentProject = handleCurrentProject();
    if (currentProject && currentProject.id === projectId) {
      changeProject(currentProject);
      setLoadingProject(false);
      return;
    }

    handleError('');
    setLoadingProject(true);
    await ProjectRepository.read(projectId)
      .then((response) => {
        handleCurrentProject(response);
        changeProject(response);
      })
      .catch(() => {
        handleError('Failed to load project');
        // TODO Differentiation between error messages.
      })
      .finally(() => {
        setLoadingProject(false);
      });
  };

  useEffect(() => {
    // weird useEffect ft. async pattern...
    async function init() {
      await loadProject(props.projectId);
    }
    init();
  }, [props.projectId]);

  const renderProjectLinks = (project: ProjectContract | null) => {
    if (!project) return undefined;

    const showRemoteTagging = (isAdmin?: number) => {
      if (isAdmin) {
        return (
          <Menu.Item>
            {({ active }) => (
              <Link
                to={routeTo(Paths.remote_tagging, {
                  projectId: project.id,
                })}
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex items-center px-4 py-2 text-sm outline-none focus:outline-none',
                )}
              >
                <CodeIcon
                  className="h-5 w-5 mr-3 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Remote tagging
              </Link>
            )}
          </Menu.Item>
        );
      }
    };

    return (
      <div className="py-1">
        <Menu.Item>
          {({ active }) => (
            <Link
              to={routeTo(Paths.projects.settings, {
                projectId: project.id,
              })}
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex items-center px-4 py-2 text-sm outline-none focus:outline-none',
              )}
            >
              <AdjustmentsIcon
                className="h-5 w-5 mr-3 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              Project settings
            </Link>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <Link
              to={routeTo(Paths.projects.members, {
                projectId: project.id,
              })}
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex items-center px-4 py-2 text-sm outline-none focus:outline-none',
              )}
            >
              <UserGroupIcon
                className="h-5 w-5 mr-3 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              Project members
            </Link>
          )}
        </Menu.Item>
        {showRemoteTagging(props.currentUser.admin)}
      </div>
    );
  };

  const renderProjectDropdown = (project?: ProjectContract) => {
    if (!project) return;

    return (
      <div className="z-10 relative">
        <Menu as="div" className="relative inline-block text-left">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="outline-none inline-flex justify-start rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
                  <span className="max-w-12 truncate">{project.name}</span>
                  <ChevronDownIcon
                    className="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="overflow-hidden origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-gray-300 ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                >
                  {renderProjectLinks(project)}

                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={Paths.projects.create}
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm outline-none focus:outline-none',
                        )}
                      >
                        <PlusCircleIcon
                          className="h-5 w-5 mr-3 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Create Project
                      </Link>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    );
  };

  return (
    <nav className="bg-white ml-3">
      {loadingProject ? (
        <div className="z-10 relative">
          <SkeletonRectangle height={'10.5'} className={'w-30'} />
        </div>
      ) : (
        renderProjectDropdown(project)
      )}
    </nav>
  );
};

ProjectMenu.defaultProps = {};

export default ProjectMenu;
