import * as React from 'react';
import ButtonPrimary from '../../../../core/components/atoms/buttons/button-primary.component';
import ButtonSecondary from '../../../../core/components/atoms/buttons/button-secondary.component';
import FormLabeledDropdown from '../../../../core/components/molecules/forms/form-labeled-dropdown.component';
import FormLabeledInput from '../../../../core/components/molecules/forms/form-labeled-input.component';
import { useState } from 'react';
import FormLabeledAutocompletion from '../../../../core/components/molecules/forms/form-labeled-autocompletion.component';
import {
  ProjectEvent,
  ProjectEventLite,
  ProjectEventsContract,
  ProjectEventTrigger,
} from '../../../service/contracts/event.contract';

export interface EventModalProps {
  loading: boolean;
  buttonPrimaryOnClick: (event: ProjectEventLite, isEdit: boolean) => void;
  buttonSecondaryOnClick: any;
  event?: ProjectEvent;
  events: ProjectEventsContract;
  values?: string[];
}

const EventModal: React.FC<EventModalProps> = (props) => {
  const event: Partial<ProjectEvent> = props.event || {};
  const triggers = [
    ProjectEventTrigger.Load, // Default
    ProjectEventTrigger.Click,
    ProjectEventTrigger.Submit,
    ProjectEventTrigger.Visible,
  ];
  const values = props.values ? props.values : [];
  const isEdit = props.event ? true : false;
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const [entity, setEntity] = useState<string>(event.entity || '');
  const [action, setAction] = useState<string>(event.action || '');
  const [trigger, setTrigger] = useState<ProjectEventTrigger>(
    event.trigger || triggers[0],
  );

  React.useEffect(() => {
    // weird useEffect ft. async pattern...
    async function init() {
      handleDisabled(entity, action, eventExists(entity, action));
    }

    init();
  }, []);

  function getResult(): ProjectEventLite {
    return {
      entity,
      action,
      trigger,
    };
  }

  const eventExists = (entity?: string, action?: string) => {
    return !!(
      entity &&
      action &&
      props.events[entity] &&
      props.events[entity][action]
    );
  };

  const handleEntity = (value: string) => {
    setEntity(value);
    handleDisabled(value, action, eventExists(entity, value));
  };

  const handleAction = (value: string) => {
    setAction(value);
    handleDisabled(entity, value, eventExists(entity, value));
  };

  const handleDisabled = (
    entity: string,
    action: string,
    duplicate: boolean,
  ) => {
    setIsDisabled(!isEdit && (!entity || !action || duplicate));
  };

  return (
    <div>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </div>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            ​
          </span>

          <div
            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {isEdit ? 'Edit' : 'New'} event
                </h3>
                <div className="mt-2 mb-2 w-5/6">
                  <FormLabeledAutocompletion
                    className="mt-3"
                    id={'entity'}
                    title={'Entity'}
                    value={entity}
                    placeholder={'e.g. order'}
                    required={true}
                    onChange={handleEntity}
                    loading={false}
                    values={values}
                    disabled={!!props.event}
                  />
                  <FormLabeledInput
                    className="mt-3"
                    id={'action'}
                    title={'Action'}
                    value={action}
                    placeholder={'e.g. complete'}
                    required={true}
                    onChange={handleAction}
                    loading={false}
                    type={'text'}
                    disabled={!!props.event}
                  />
                  <FormLabeledDropdown
                    className="mt-3"
                    id={'trigger'}
                    title={'Trigger'}
                    value={trigger}
                    required={true}
                    onChange={(v) => {
                      setTrigger(v as ProjectEventTrigger);
                    }}
                    loading={false}
                    values={triggers}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <ButtonPrimary
                label="Save"
                onClick={() => props.buttonPrimaryOnClick(getResult(), isEdit)}
                margin="ml-3"
                loading={props.loading}
                disabled={isDisabled}
              />
              <ButtonSecondary
                label="Cancel"
                onClick={props.buttonSecondaryOnClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EventModal.defaultProps = {};

export default EventModal;
