import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Paths } from '../../../routes';
import SignupProcess from '../templates/auth-layout.component';
import Alert from '../molecules/alert.component';
import firebase from 'firebase/app';
import ButtonPrimary from '../atoms/buttons/button-primary.component';

export interface SignupSucceededProps {
  text?: string;
}

const SignupSucceeded: React.FC<SignupSucceededProps> = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const sendVerificationMail = useCallback(async () => {
    setError('');
    setLoading(true);

    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser)
        await currentUser.sendEmailVerification({
          url: process.env.REACT_APP_APP_BASE_URL + Paths.home,
        });
    } catch {
      setError('Failed to send verification mail');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    sendVerificationMail();
  }, [sendVerificationMail]);

  const renderContent = () => {
    return (
      <div>
        <div className="mb-6">
          <div className="relative">
            <div className="relative  flex justify-center text-xl font-medium mb-6">
              <span className="px-2 bg-white text-gray-700 text-2xl font-bold">
                Thank you for your registration!
              </span>
            </div>
            <div className="relative flex justify-center text-center text-base font-medium mb-6">
              <span className="px-2 bg-white text-gray-700">
                Please check your email inbox to complete your registration with
                the link attached.
              </span>
            </div>
            <div className="mb-6">
              <ButtonPrimary
                label="Resend verification mail"
                loading={loading}
                disabled={loading}
                onClick={() => sendVerificationMail()}
                wide={true}
                textSize="text-base"
              ></ButtonPrimary>
            </div>
            {error && (
              <Alert errorMessage={error} onClick={() => setError('')}></Alert>
            )}
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 mt-6 py-8">
          <p className="mt-2 text-center text-sm text-gray-600 max-w">
            Already have an account?&nbsp;
            <Link
              to={Paths.login}
              className="font-medium text-elbwalker hover:text-elbwalker-600 underline"
            >
              Log in
            </Link>
          </p>
        </div>
      </div>
    );
  };

  return <SignupProcess content={renderContent()}></SignupProcess>;
};

SignupSucceeded.defaultProps = {};

export default SignupSucceeded;
