import * as React from 'react';
import { useEffect, useState, Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Paths } from '../../../routes';
import { routeTo } from '../../../core/service/route/route.service';
import SimpleModal from '../organisms/modals/simple-modal.component';
import Layout from '../../../core/components/templates/layout.component';
import { useLayout } from '../../../core/context/layout.context';
import SkeletonRectangle from '../../../core/components/atoms/skeletons/skeleton-rectangle.component';
import UserContract from '../../../user/service/contracts/user.contract';
import ProjectRepository from '../../service/api/project.repository';
import {
  BrowseProjectContract,
  ProjectLiteContract,
} from '../../service/contracts/project.contract';
import { Menu, Transition } from '@headlessui/react';
import {
  DotsVerticalIcon,
  TrashIcon,
  PencilAltIcon,
} from '@heroicons/react/solid';
import { classNames } from '../../../core/service/css/class-names.service';
import { ButtonPrimary } from '../../../core/components/atoms/buttons/button-primary.component';
import { DocumentAddIcon } from '@heroicons/react/outline';

export interface OverviewProjectProps {
  currentUser: UserContract;
  loadingProjects: boolean;
}

const ProjectsOverview: React.FC<OverviewProjectProps> = (props) => {
  const { handleError, handleSuccess } = useLayout();
  const history = useHistory();
  const [loadingProjects, setLoadingProjects] = useState(props.loadingProjects);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [projects, changeProjects] = useState<BrowseProjectContract>([]);
  const [confirmDeleteProject, changeDeleteProject] = useState<
    ProjectLiteContract | false
  >(false);

  const loadProjects = async () => {
    handleError('');
    setLoadingProjects(true);
    await ProjectRepository.browse()
      .then((response) => {
        changeProjects(response);
      })
      .catch(() => {
        handleError('Failed to load projects');
        // TODO Differentiation between error messages.
      })
      .finally(() => {
        setLoadingProjects(false);
      });
  };

  useEffect(() => {
    // weird useEffect ft. async pattern...
    async function init() {
      await loadProjects();
    }
    init();
  }, []);

  const deleteProject = async (projectId: string) => {
    handleError('');
    setLoadingDelete(true);
    await ProjectRepository.delete(projectId)
      .then((response) => {
        changeProjects(response);
        handleSuccess('Project deleted');
      })
      .catch(() => {
        handleError('Failed to delete project');
      })
      .finally(() => {
        changeDeleteProject(false);
        setLoadingDelete(false);
      });
  };

  const renderRemoveWindow = () => {
    if (!confirmDeleteProject) return undefined;

    return (
      <SimpleModal
        title={'Delete project'}
        text={'Are you sure you want to delete this project?'}
        ButtonPrimaryLabel={'Delete'}
        red={true}
        ButtonSecondaryLabel={'Cancel'}
        loading={loadingDelete}
        ButtonPrimaryOnClick={() => {
          deleteProject(confirmDeleteProject.id);
        }}
        ButtonSecondaryOnClick={() => {
          changeDeleteProject(false);
        }}
      ></SimpleModal>
    );
  };

  const renderProjects = (projects: BrowseProjectContract | []) => {
    if (!projects.length)
      return (
        <tr>
          <td colSpan={6} className="px-6 py-20">
            <div className="text-center">
              <DocumentAddIcon className="mx-auto h-12 w-12 text-gray-400"></DocumentAddIcon>
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No project
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Get started by creating a new project.
              </p>
              <div className="mt-6">
                <ButtonPrimary
                  label={'Create new project'}
                  loading={false}
                  onClick={() => history.push(Paths.projects.create)}
                />
              </div>
            </div>
          </td>
        </tr>
      );

    return projects.map((project: ProjectLiteContract, index: number) => {
      return (
        <tr key={index}>
          <td className="rounded-bl-lg px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <Link
              to={routeTo(Paths.projects.events, {
                projectId: project.id,
              })}
              className="block px-4 py-2 text-sm outline-none focus:outline-none truncate"
            >
              {project.name}
            </Link>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 truncate">
            {project.domain}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            Central European Time (CET)
          </td>
          <td className="rounded-br-lg px-6 py-4 whitespace-nowrap text-center text-sm">
            <div className="block px-4 py-2 text-sm outline-none focus:outline-none">
              <Menu as="div" className="relative inline-block text-left">
                {({ open }) => (
                  <>
                    <div>
                      <Menu.Button className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-elbwalker">
                        <span className="sr-only">Open options</span>
                        <DotsVerticalIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="z-50 overflow-hidden origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-gray-300 ring-opacity-5 focus:outline-none divide-y divide-gray-200"
                      >
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={routeTo(Paths.projects.settings, {
                                projectId: project.id,
                              })}
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'group flex items-center px-4 py-3 text-sm outline-none focus:outline-none',
                              )}
                            >
                              <PencilAltIcon
                                className="h-5 w-5 mr-3 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                              Edit
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => changeDeleteProject(project)}
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-red-500'
                                  : 'text-red-400',
                                'group flex items-center px-4 py-3 text-sm w-full text-left outline-none focus:outline-none',
                              )}
                            >
                              <TrashIcon
                                className="h-5 w-5 mr-3 text-red-400 group-hover:text-red-500"
                                aria-hidden="true"
                              />
                              Delete
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </td>
        </tr>
      );
    });
  };

  const content = (
    <div>
      {renderRemoveWindow()}
      <div className="flex flex-col">
        <div className="-my-2 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow border-b border-gray-200 sm:rounded-lg">
              <table className="rounded-lg bg-white table-fixed w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="w-6/20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider rounded-tl-lg">
                      Project Name
                    </th>
                    <th className="w-6/20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Domain
                    </th>
                    <th className="w-6/20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Reporting timezone
                    </th>
                    <th className="w-2/20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider rounded-tr-lg">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {loadingProjects ? (
                    <tr>
                      <td className="rounded-bl-lg px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <SkeletonRectangle width="full" />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <SkeletonRectangle width="full" />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <SkeletonRectangle width="full" />
                      </td>
                      <td className="rounded-br-lg px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <SkeletonRectangle width="full" />
                      </td>
                    </tr>
                  ) : (
                    renderProjects(projects)
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Layout title="Your Projects" currentUser={props.currentUser}>
      {content}
    </Layout>
  );
};

ProjectsOverview.defaultProps = { loadingProjects: true };

export default ProjectsOverview;
