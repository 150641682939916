import * as React from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../../core/components/templates/layout.component';
import UserContract from '../../../user/service/contracts/user.contract';

export interface ProjectProps {
  currentUser: UserContract;
  className?: string;
}

const Project: React.FC<ProjectProps> = (props) => {
  const { projectId } = useParams<{ projectId: string }>();
  const content = (
    <div className={props.className}>
      <div>Id: #{projectId}</div>
    </div>
  );

  return (
    <Layout title="Project" currentUser={props.currentUser}>
      {content}
    </Layout>
  );
};

Project.defaultProps = {};

export default Project;
