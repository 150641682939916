import * as React from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ControlStepper from '../../../../core/components/molecules/controls/control-stepper.component';
import LayoutContainer from '../../../../core/components/templates/layout-container.component';
import { useLayout } from '../../../../core/context/layout.context';
import { routeTo } from '../../../../core/service/route/route.service';
import { Paths } from '../../../../routes';
import UserContract from '../../../../user/service/contracts/user.contract';
import ProjectDestinationsRepository from '../../../service/api/project-destinations.repository';
import { ProjectDestinationTypes } from '../../../service/contracts/project-destination.contract';
import DestinationCreationName from '../../organisms/destination-creation-steps/destination-creation-name.component';
import DestinationCreationType from '../../organisms/destination-creation-steps/destination-creation-type.component';

export interface DestinationCreationProcessProps {
  currentUser: UserContract;
  stepIndex?: number; // Storybook
}

enum DestinationCreationStep {
  Type,
  Name,
}

const DestinationCreationProcess: React.FC<DestinationCreationProcessProps> = ({
  stepIndex = 0,
  ...props
}) => {
  const { projectId } = useParams<{ projectId: string }>();
  const { handleError, handleSuccess, changeDestinations } = useLayout();
  const history = useHistory();
  const [currentStep, setCurrentStep] =
    useState<DestinationCreationStep>(stepIndex);

  const [stepCanBeCompleted, setStepCanBeCompleted] = useState<boolean>(false);
  const [showStepsCompletionLoading, setShowStepsCompletionLoading] =
    useState<boolean>(false);

  const [destinationName, setDestinationName] = useState<string>('');
  const [destinationType, setDestinationType] = useState<
    ProjectDestinationTypes | false
  >(false);

  const contentDestinationType = (
    <DestinationCreationType
      initialValue={destinationType}
      onChange={(type) => setDestinationType(type)}
      onCanBeCompleted={(canBeCompleted) => {
        setStepCanBeCompleted(canBeCompleted);
      }}
      onRequestCompletion={() => setCurrentStep(currentStep + 1)}
    />
  );

  const contentDestinationName = (
    <DestinationCreationName
      initialValue={destinationName}
      onChange={(details) => {
        setDestinationName(details);
      }}
      onCanBeCompleted={(canBeCompleted) => {
        setStepCanBeCompleted(canBeCompleted);
      }}
      onStepsCompleted={async () => {
        await onCreateDestinationClicked();
      }}
    />
  );

  async function onCreateDestinationClicked() {
    if (showStepsCompletionLoading) return;

    if (destinationType && destinationName) {
      setShowStepsCompletionLoading(true);
      handleError('');

      const destination = {
        name: destinationName,
        type: destinationType,
      };

      ProjectDestinationsRepository.add(projectId, destination)
        .then((destinations) => {
          handleSuccess('Destination successfully created.');
          changeDestinations(destinations);

          history.push(
            routeTo(Paths.projects.destinations.overview, {
              projectId,
            }),
          );
        })
        .catch(() => {
          //TODO check Error handling
          handleError(
            'Oops an error occurred. Please reload the page and try again.',
          );
        });
    } else {
      handleError('Enter all required information.');
    }
  }

  function getStep(currentStep: DestinationCreationStep) {
    let headline = '';
    let description = '';
    let content: JSX.Element;
    switch (currentStep) {
      case DestinationCreationStep.Type:
        headline = 'Choose a destination';
        description = 'You can choose between the destinations listed below.';
        content = contentDestinationType;
        break;
      case DestinationCreationStep.Name:
        headline = 'Name your destination';
        description = 'Pick a name to describe this destination.';
        content = contentDestinationName;
        break;
    }
    return { headline, description, content };
  }
  const step = getStep(currentStep);

  return (
    <LayoutContainer title="Create Destination" currentUser={props.currentUser}>
      <h1>{step.headline}</h1>
      <p className="text-sm text-gray-600">{step.description}</p>
      <div style={{ minHeight: '450px' }}>{step.content}</div>
      <ControlStepper
        showStepsCompletionLoading={showStepsCompletionLoading}
        currentStepCanBeCompleted={stepCanBeCompleted}
        nextLabel={'Next'}
        previousLabel={'Previous'}
        onStepSelected={(stepIndex) => {
          setStepCanBeCompleted(false);
          setCurrentStep(stepIndex);
        }}
        completionLabel={'Create destination'}
        steps={2}
        currentStep={currentStep}
        onStepsCompleted={async () => {
          await onCreateDestinationClicked();
        }}
        cancel={true}
        cancelLink={() => {
          history.push(
            routeTo(Paths.projects.destinations.overview, {
              projectId: projectId,
            }),
          );
        }}
      />
    </LayoutContainer>
  );
};

DestinationCreationProcess.defaultProps = {};

export default DestinationCreationProcess;
