import * as React from 'react';

export type InputType =
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

export interface InputProps {
  id: string;
  value: string;
  type: InputType;
  placeholder?: string;
  autoComplete?: string;
  required: boolean;
  onChange: (value: string) => void;
  onKeyDown?: (event: any) => void;
  onFocusChange?: (focussed: boolean) => void;
  wide?: boolean;
  width?: string;
  margin?: string;
  disabled?: boolean;
}

export const Input: React.FC<InputProps> = ({
  id = 'id',
  /**
   * What kind of type does the input have?
   */
  type = 'text',
  required = true,
  wide = true,
  disabled = false,
  ...props
}) => {
  return (
    <input
      onFocus={() => (props.onFocusChange ? props.onFocusChange(true) : '')}
      onBlur={() => (props.onFocusChange ? props.onFocusChange(false) : '')}
      data-lpignore="true"
      id={id}
      name={id}
      type={type}
      required={required}
      placeholder={props.placeholder}
      autoComplete={props.autoComplete}
      value={props.value}
      onChange={(event) => props.onChange(event.target.value)}
      onKeyDown={(e: any) => (props.onKeyDown ? props.onKeyDown(e) : '')}
      disabled={disabled}
      className={`
      ${wide ? 'w-full' : ''}
      ${props.width ? props.width : ''}
      ${props.margin ? props.margin : ''}
      ${disabled ? 'bg-gray-100' : ''}
      appearance-none block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-elbwalker focus:border-elbwalker sm:text-sm
      `}
    />
  );
};

export default Input;
