import * as React from 'react';
import { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Paths } from '../../../../routes';
import { AuthContextProps, useAuth } from '../../../context/auth.context';
import { classNames } from '../../../service/css/class-names.service';
import { Menu, Transition } from '@headlessui/react';
import {
  MailIcon,
  InformationCircleIcon,
  ShieldCheckIcon,
  LogoutIcon,
  DocumentTextIcon,
  OfficeBuildingIcon,
  CogIcon,
} from '@heroicons/react/solid';
import UserContract from '../../../../user/service/contracts/user.contract';
import { routeTo } from '../../../service/route/route.service';
import { useLayout } from '../../../context/layout.context';

export interface SettingsMenuProps {
  currentUser: UserContract;
}

const SettingsMenu: React.FC<SettingsMenuProps> = (props) => {
  const { logout } = useAuth() as AuthContextProps;
  const { handleError } = useLayout();
  const history = useHistory();

  async function handleLogout() {
    handleError('');

    try {
      await logout();
      history.push(Paths.login);
    } catch (error) {
      handleError(
        'Oops an error occurred. Please reload the page and try again.',
      );
    }
  }

  const renderSettingsMenu = () => {
    return (
      <Menu.Items
        static
        className="overflow-hidden origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-gray-300 ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
      >
        <div className="py-1">
          <Menu.Item>
            {({ active }) => (
              <Link
                to={routeTo(Paths.user.settings, {
                  userId: props.currentUser.id,
                })}
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex items-center px-4 py-2 text-sm outline-none focus:outline-none',
                )}
              >
                <CogIcon
                  className="h-5 w-5 mr-3 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Account settings
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <a
                href="mailto:hello@elbwalker.com"
                rel="noreferrer noopener"
                target="_blank"
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex items-center px-4 py-2 text-sm outline-none focus:outline-none',
                )}
              >
                <MailIcon
                  className="h-5 w-5 mr-3 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Contact us
              </a>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <a
                href={Paths.docs.home}
                rel="noreferrer noopener"
                target="_blank"
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex items-center px-4 py-2 text-sm outline-none focus:outline-none',
                )}
              >
                <InformationCircleIcon
                  className="h-5 w-5 mr-3 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Help center
              </a>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <a
                href={Paths.terms}
                rel="noreferrer noopener"
                target="_blank"
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex items-center px-4 py-2 text-sm outline-none focus:outline-none',
                )}
              >
                <DocumentTextIcon
                  className="h-5 w-5 mr-3 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Terms
              </a>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <a
                href={Paths.privacy}
                rel="noreferrer noopener"
                target="_blank"
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex items-center px-4 py-2 text-sm outline-none focus:outline-none',
                )}
              >
                <ShieldCheckIcon
                  className="h-5 w-5 mr-3 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Privacy policy
              </a>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <a
                href={Paths.imprint}
                rel="noreferrer noopener"
                target="_blank"
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex items-center px-4 py-2 text-sm outline-none focus:outline-none',
                )}
              >
                <OfficeBuildingIcon
                  className="h-5 w-5 mr-3 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Imprint
              </a>
            )}
          </Menu.Item>
        </div>
        <div className="py-1">
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => handleLogout()}
                className={classNames(
                  active ? 'bg-gray-100 text-red-500' : 'text-red-400',
                  'group flex items-center px-4 py-2 text-sm w-full text-left outline-none focus:outline-none',
                )}
              >
                <LogoutIcon
                  className="h-5 w-5 mr-3 text-red-400 group-hover:text-red-500"
                  aria-hidden="true"
                />
                Log out
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    );
  };

  const renderSettingsDropdownButton = () => {
    return (
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <Menu.Button className="focus:outline-none">
              <div className="flex-shrink-0 h-10 w-10">
                <div className="flex items-center justify-center h-10 w-10 bg-elbwalker-800 text-white border-2 border-transparent rounded-full transition duration-150 ease-in-out">
                  <div>
                    {props.currentUser?.name
                      .split(/\s/)
                      .reduce(
                        (response, word) => (response += word.slice(0, 1)),
                        '',
                      ) || 'Me'}
                  </div>
                </div>
              </div>
            </Menu.Button>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              {renderSettingsMenu()}
            </Transition>
          </>
        )}
      </Menu>
    );
  };

  return (
    <div className="z-10 ml-3 relative">{renderSettingsDropdownButton()}</div>
  );
};

SettingsMenu.defaultProps = {};

export default SettingsMenu;
