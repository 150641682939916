import { Route, Redirect } from 'react-router-dom';
import { AuthContextProps, useAuth } from '../../context/auth.context';
import { Paths } from '../../../routes';
import { RouteProps } from 'react-router-dom';

interface PrivateRouteProps extends RouteProps {
  component: any;
}

export default function PrivateRoute({
  component: Component,
  ...rest
}: PrivateRouteProps) {
  const { currentUser } = useAuth() as AuthContextProps;

  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser ? (
          <Component {...props} currentUser={currentUser} />
        ) : (
          <Redirect to={Paths.login} />
        );
      }}
    ></Route>
  );
}
