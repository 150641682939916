import * as React from 'react';
import LooseObject from '../../../../core/service/contracts/loose-object.contract';
import { classNames } from '../../../../core/service/css/class-names.service';
import DimensionalFactsRepository from '../../../service/api/stats/dimensional-facts.repository';
import DimensionalFactsContract, {
  DimensionalFactsColumnContract,
  RowDataContract,
} from '../../../service/contracts/stats/dimensional-facts/dimensional-facts.contract';
import DimensionalFactsRequestParamsContract from '../../../service/contracts/stats/dimensional-facts/dimensional-facts-request-params.contract';
import WidgetRenderContract from '../../../service/contracts/widget-render.contract';
import Format from '../../../service/misc/format.service';
import GridCard from '../grid-card.component';
import HighlightTableModel from './highlight-table.model';
import DashboardModal from '../../organisms/dashboard-modal/dashboard-modal.component';
import SkeletonRectangle from '../../../../core/components/atoms/skeletons/skeleton-rectangle.component';
import { useEffect, useState } from 'react';
import { ArrowNarrowDownIcon } from '@heroicons/react/solid';
import { alignCol } from '../../../service/css/align.service';
import { useLayout } from '../../../../core/context/layout.context';
import InfoBox from '../info-box.component';
import { routeTo } from '../../../../core/service/route/route.service';
import { Paths } from '../../../../routes';

const HighlightTable: React.FC<WidgetRenderContract> = (props) => {
  const { showAll, setShowAll, showModal, onShowAll, onCloseAll } =
    HighlightTableModel();

  const { handleError } = useLayout();
  const request = props.config as DimensionalFactsRequestParamsContract;
  const [facts, changeFacts] = useState<DimensionalFactsContract | null>(null);
  let sort: string | undefined = undefined;
  const changeSort = async (newSort: string) => {
    sort = newSort;
    await getFacts(props.projectId, request);
  };

  request.date_from = props.selectedDateFilter.date_from;
  request.date_to = props.selectedDateFilter.date_to;
  request.filters = props.selectedDimensionFilters;

  useEffect(() => {
    // weird useEffect ft. async pattern...
    async function init() {
      await getFacts(props.projectId, request, sort);
    }
    init();
  }, [props.selectedDateFilter, props.selectedDimensionFilters, sort]);

  const entriesForInitialRequest = 8;

  const getFacts = async (
    projectId: string,
    request: DimensionalFactsRequestParamsContract,
    sort?: string,
  ) => {
    handleError('');
    changeFacts(null);

    if (sort) request.sort = sort;
    request.offset = 0;
    request.limit = entriesForInitialRequest;

    await DimensionalFactsRepository.read(
      projectId,
      props.config?.dimension,
      request,
    )
      .then((facts) => {
        setShowAll(facts.meta.more);
        changeFacts(facts);
      })
      .catch(() => {
        handleError(
          'Oops an error occurred. Please reload the page and try again.',
        );
      });
  };

  if (facts === null) {
    return (
      <GridCard cols={props.width} question={props.question}>
        <SkeletonRectangle width="1/2" height="10" />
        <div className="grid grid-cols-3 gap-4 mt-6">
          <SkeletonRectangle width="full" height="8" className="mt-1" />
          <SkeletonRectangle
            width="full"
            height="8"
            className="mt-1 col-start-3 col-span-1"
          />
        </div>

        <SkeletonRectangle width="full" height="1" className="mt-3" />
        <SkeletonRectangle width="full" height="6" className="mt-3" />
        <SkeletonRectangle width="full" height="6" className="mt-2" />
        <SkeletonRectangle width="full" height="6" className="mt-2" />
        <SkeletonRectangle width="full" height="6" className="mt-2" />
        <SkeletonRectangle width="full" height="6" className="mt-2" />
        <SkeletonRectangle width="full" height="6" className="mt-2" />
      </GridCard>
    );
  }

  if (facts.rows.length == 0) {
    return (
      <GridCard cols={props.width} question={props.question}>
        <div className="min-h-widget flex justify-center items-center">
          <p className="text-xl">No data</p>
        </div>
      </GridCard>
    );
  }

  // calculate min and max for each column
  ////

  const columnMeta: LooseObject = {};
  for (const column of facts.columns) {
    columnMeta[column.id] = {
      min: Number.POSITIVE_INFINITY,
      max: Number.NEGATIVE_INFINITY,
    };
  }

  let tmp;
  for (const row of facts.rows) {
    for (const column of facts.columns) {
      tmp = row[column.id];
      if (tmp < columnMeta[column.id].min) columnMeta[column.id].min = tmp;
      if (tmp > columnMeta[column.id].max) columnMeta[column.id].max = tmp;
    }
  }

  const normalize = (val: number, min: number, max: number) => {
    if (max - min === 0) return 1;
    return Math.round(((val - min) / (max - min)) * 10);
  };

  const renderColumns = () => {
    const th = facts.columns.map((column, index) => {
      let align = index === 0 ? 'text-left' : 'text-right';
      if (column.align) align = 'text-' + column.align;

      const renderIconDown = (label?: string) => {
        return (
          <ArrowNarrowDownIcon
            className={`h-4 w-4 align-middle items-center ${
              label ? 'ml-2' : ''
            }`}
          />
        );
      };

      const cssClasses = classNames(
        'py-3 text-sm text-gray-900 font-medium cursor-pointer hover:underline',
        align,
      );

      if (facts.meta.sort === column.id) {
        return (
          <th key={index} className={cssClasses}>
            {column.title}
            {renderIconDown()}
          </th>
        );
      } else {
        return (
          <th
            key={index}
            className={cssClasses}
            onClick={() => changeSort(column.id)}
          >
            {column.title}
          </th>
        );
      }
    });

    return <tr>{th}</tr>;
  };

  const renderRows = () => {
    return facts.rows.map((row: RowDataContract, index: number) => (
      <tr key={index} className="bg-white">
        {renderData(row)}
      </tr>
    ));
  };

  const renderData = (row: RowDataContract) => {
    return facts.columns.map(
      (column: DimensionalFactsColumnContract, index: number) => {
        if (index === 0) {
          const title = row[column.id] as string;
          return (
            <td
              key={index}
              className="break-all pt-2 text-sm leading-5 font-medium text-gray-900 hover:underline cursor-pointer"
              onClick={() =>
                props.addDimensionFilter({
                  dimension: column.id,
                  title: title,
                })
              }
            >
              {title}
            </td>
          );
        }

        const value = row[column.id] as number;
        const normalization = normalize(
          value,
          columnMeta[column.id].min,
          columnMeta[column.id].max,
        );

        const align = column.align ? column.align : 'right';

        const bgColor = (index: number, facts: DimensionalFactsContract) => {
          if (index === facts.columns.length - 1) {
            switch (normalization) {
              case 0:
                return 'bg-table-blue-0';
              case 1:
                return 'bg-table-blue-1';
              case 2:
                return 'bg-table-blue-2';
              case 3:
                return 'bg-table-blue-3';
              case 4:
                return 'bg-table-blue-4';
              case 5:
                return 'bg-table-blue-5';
              case 6:
                return 'bg-table-blue-6';
              case 7:
                return 'bg-table-blue-7';
              case 8:
                return 'bg-table-blue-8';
              case 9:
                return 'bg-table-blue-9';
              case 10:
                return 'bg-table-blue-10';
              default:
                return;
            }
          } else {
            switch (normalization) {
              case 0:
                return 'bg-table-gray-0';
              case 1:
                return 'bg-table-gray-1';
              case 2:
                return 'bg-table-gray-2';
              case 3:
                return 'bg-table-gray-3';
              case 4:
                return 'bg-table-gray-4';
              case 5:
                return 'bg-table-gray-5';
              case 6:
                return 'bg-table-gray-6';
              case 7:
                return 'bg-table-gray-7';
              case 8:
                return 'bg-table-gray-8';
              case 9:
                return 'bg-table-gray-9';
              case 10:
                return 'bg-table-gray-10';
              default:
                return;
            }
          }
        };

        return (
          <td
            key={index}
            className={`${alignCol(
              align,
            )} pt-2 whitespace-nowrap text-sm leading-5 text-gray-700`}
          >
            <span
              className={`${bgColor(
                index,
                facts,
              )} block w-full h-full px-2 py-1 rounded-sm`}
            >
              {Format.number(value)}
            </span>
          </td>
        );
      },
    );
  };

  const renderNav = () => {
    if (!showAll) return undefined;

    return (
      <div className="mt-auto px-4 flex items-center sm:px-0 justify-center">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 rounded-md border border-gray-300 shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-elbwalker"
          aria-label="Show All"
          aria-haspopup="true"
          onClick={onShowAll}
        >
          Show All
        </button>
      </div>
    );
  };

  function renderModal() {
    if (!showModal) return undefined;

    return (
      <DashboardModal
        onClose={onCloseAll}
        renderColumns={renderColumns}
        contract={props}
        columnMeta={columnMeta}
      />
    );
  }

  const renderInfoBox = (id: string, projectId: string) => {
    switch (id) {
      case 'events': {
        return (
          <InfoBox
            title="Add more events"
            link={routeTo(Paths.projects.events, { projectId })}
            icon="PlusCircleIcon"
          />
        );
      }
      case 'sources':
        return (
          <InfoBox
            title="Where is my paid Google traffic?"
            link="https://docs.elbwalker.com/questions-and-answers/analyzing-google-ads-campaigns"
            icon="QuestionMarkCircleIcon"
            external={true}
          />
        );
    }
  };

  return (
    <GridCard
      cols={props.width}
      question={props.question}
      headline={props.title}
      infoBox={renderInfoBox(props.id, props.projectId)}
    >
      <div className="h-full flex flex-col">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>{renderColumns()}</thead>
          <tbody>{renderRows()}</tbody>
        </table>
        {renderNav()}
      </div>
      {renderModal()}
    </GridCard>
  );
};

HighlightTable.defaultProps = {};

export default HighlightTable;
