export type BrowseProjectDestinationsContract = Record<
  string,
  ProjectDestination
>;

export interface ProjectDestination {
  type: ProjectDestinationTypes;
  name: string;
  active: boolean;
  isValid: boolean;
  config: ProjectDestinationConfig;
}

export interface ProjectDestinationInfo {
  category: string;
  docs: string;
  logo: string;
}

export enum ProjectDestinationTypes {
  Collect = 'collect',
  FacebookPixel = 'facebook_pixel',
  GoogleTagManager = 'google_tagmanager',
  GoogleUA = 'google_ua',
  GoogleGA4 = 'google_ga4',
  Matomo = 'matomo',
}

export type ProjectDestinations = Record<string, ProjectDestination>;
export type ProjectDestinationConfig =
  | ProjectDestinationCollectConfig
  | ProjectDestinationFacebookPixelConfig
  | ProjectDestinationGoogleGA4Config
  | ProjectDestinationGoogleTagmanagerConfig
  | ProjectDestinationGoogleUAConfig
  | ProjectDestinationMatomoConfig;

export interface ProjectDestinationEventMapping {
  [entity: string]: { [action: string]: boolean };
}

export interface ProjectDestinationConfigGenerics {
  mapping?: ProjectDestinationEventMapping;
}

export interface ProjectDestinationCollectConfig
  extends ProjectDestinationConfigGenerics {
  projectId: boolean;
  crossDomainTracking?: boolean;
  exclusionParameters?: string[];
}

export interface ProjectDestinationFacebookPixelConfig
  extends ProjectDestinationConfigGenerics {
  pixelId: string;
}

export interface ProjectDestinationGoogleTagmanagerConfig
  extends ProjectDestinationConfigGenerics {
  variable: string;
}

export interface ProjectDestinationGoogleUAConfig
  extends ProjectDestinationConfigGenerics {
  propertyId: string;
  transport_url: string;
}

export interface ProjectDestinationGoogleGA4Config
  extends ProjectDestinationConfigGenerics {
  measurementId: string;
  transport_url: string;
}

export interface ProjectDestinationMatomoConfig
  extends ProjectDestinationConfigGenerics {
  siteId: string;
}
