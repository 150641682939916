import * as React from 'react';
import ButtonPrimary from '../../../../core/components/atoms/buttons/button-primary.component';
import ButtonSecondary from '../../../../core/components/atoms/buttons/button-secondary.component';
import FormLabeledDropdown from '../../../../core/components/molecules/forms/form-labeled-dropdown.component';
import FormLabeledInput from '../../../../core/components/molecules/forms/form-labeled-input.component';
import { useState } from 'react';
import {
  ProjectEventProperty,
  ProjectEventPropertyLite,
} from '../../../service/contracts/event.contract';

export interface EventPropertyModalProps {
  loading: boolean;
  buttonPrimaryOnClick: (
    event: ProjectEventPropertyLite,
    isEdit: boolean,
  ) => void;
  buttonSecondaryOnClick: any;
  eventProperty: ProjectEventProperty;
}

const EventPropertyModal: React.FC<EventPropertyModalProps> = (props) => {
  const propertyName = props.eventProperty.property || '';
  const property = props.eventProperty.properties[propertyName] || undefined;

  const [name, setName] = useState<string>(propertyName);
  const isEdit = propertyName ? true : false;

  const propertyTypes = ['Text', 'Number', 'Currency'];
  const [propertyType, setPropertyType] = useState<string>(propertyTypes[0]);

  const [exampleValue, setExampleValue] = useState<string>('');

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  React.useEffect(() => {
    // weird useEffect ft. async pattern...
    async function init() {
      handleDisabled(name, propertyExists(name));
      if (property) setExampleValue(property.example);
    }

    init();
  }, []);

  function getResult(): ProjectEventPropertyLite {
    return {
      entity: props.eventProperty.entity,
      action: props.eventProperty.action,
      property: name,
      type: propertyType.toLowerCase(),
      example: exampleValue,
    };
  }

  const propertyExists = (value: string) => {
    return !!props.eventProperty.properties[value];
  };

  const handleProperty = (value: string) => {
    setName(value);
    handleDisabled(value, propertyExists(value));
  };

  const handleDisabled = (name: string, duplicate: boolean) => {
    setIsDisabled(!isEdit && (!name || duplicate));
  };

  return (
    <div>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </div>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            ​
          </span>

          <div
            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {isEdit ? `Edit` : 'New'} property for{' '}
                  {props.eventProperty.entity} {props.eventProperty.action}
                </h3>
                <div className="mt-2 mb-2 w-5/6">
                  <FormLabeledInput
                    className="mt-3"
                    id={'name'}
                    title={'Name'}
                    value={name}
                    placeholder={'e.g. category'}
                    required={false}
                    onChange={handleProperty}
                    loading={false}
                    type={'text'}
                    disabled={!!props.eventProperty.property}
                  />
                  <FormLabeledDropdown
                    className="mt-3"
                    id={'type'}
                    title={'Type'}
                    value={propertyType}
                    required={false}
                    onChange={setPropertyType}
                    loading={false}
                    values={propertyTypes}
                  />
                  <FormLabeledInput
                    className="mt-3"
                    id={'example'}
                    title={'Example value'}
                    value={exampleValue}
                    placeholder={'e.g. jeans'}
                    required={false}
                    onChange={(v) => setExampleValue(v)}
                    loading={false}
                    type={'text'}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <ButtonPrimary
                label="Save"
                onClick={() => props.buttonPrimaryOnClick(getResult(), isEdit)}
                margin="ml-3"
                loading={props.loading}
                disabled={isDisabled}
              />
              <ButtonSecondary
                label="Cancel"
                onClick={props.buttonSecondaryOnClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EventPropertyModal.defaultProps = {};

export default EventPropertyModal;
