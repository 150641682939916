export type ClassNameType = string | undefined | ClassNameType[];

export function classNames(...names: ClassNameType[]) {
  return names
    .map((value) => {
      if (typeof value === 'string') return value;

      if (Array.isArray(value)) return value.join(' ');
    })
    .join(' ')
    .trim();
}
