import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import * as React from 'react';
import ButtonPrimary from '../../atoms/buttons/button-primary.component';
import ButtonSecondary from '../../atoms/buttons/button-secondary.component';
import Steps from '../../atoms/steps/steps.component';

export interface ControlStepperProps {
  steps: number;
  previousLabel: string;
  nextLabel: string;
  completionLabel: string;
  currentStepCanBeCompleted: boolean;
  currentStep: number;
  onStepSelected?: (step: number) => void;
  onStepsCompleted: () => Promise<void>;
  showStepsCompletionLoading: boolean;
  cancel?: boolean;
  cancelLink?: any;
  secondPrimaryLabel?: string;
  onSecondPrimary?: () => void;
}

const renderIconRight = (label?: string) => {
  return (
    <ChevronRightIcon
      className={`h-4.5 w-4.5 align-middle items-center ${label ? 'ml-2' : ''}`}
    />
  );
};

const renderIconLeft = (label?: string) => {
  return (
    <ChevronLeftIcon
      className={`h-4.5 w-4.5 align-middle items-center ${label ? 'ml-2' : ''}`}
    />
  );
};

/**
 * Stepped Card. (e.g. Onboarding)
 */
const ControlStepper: React.FC<ControlStepperProps> = ({
  currentStepCanBeCompleted = false,
  currentStep = 0,
  showStepsCompletionLoading = false,
  ...props
}) => {
  async function onComplete() {
    // Prevent unmounted state update
    await props.onStepsCompleted();
  }

  const previousStepButton = () => {
    if (props.cancel && currentStep == 0) {
      return <ButtonSecondary label={'Cancel'} onClick={props.cancelLink} />;
    } else if (currentStep !== 0) {
      return (
        <ButtonSecondary
          icon={renderIconLeft()}
          label={props.previousLabel}
          onClick={() => props.onStepSelected?.(currentStep - 1)}
        />
      );
    }
  };

  const nextStepOrCompletionButton = () => {
    return currentStep === props.steps - 1 ? (
      <div className="space-x-3">
        {props.secondPrimaryLabel && (
          <ButtonSecondary
            label={props.secondPrimaryLabel}
            disabled={!currentStepCanBeCompleted || showStepsCompletionLoading}
            onClick={props.onSecondPrimary}
          />
        )}

        <ButtonPrimary
          label={props.completionLabel}
          loading={showStepsCompletionLoading}
          disabled={!currentStepCanBeCompleted || showStepsCompletionLoading}
          onClick={async () => {
            await onComplete();
          }}
        />
      </div>
    ) : (
      <ButtonPrimary
        label={props.nextLabel}
        icon={renderIconRight()}
        iconRight={true}
        loading={false}
        disabled={!currentStepCanBeCompleted}
        onClick={() => props.onStepSelected?.(currentStep + 1)}
      />
    );
  };

  return (
    <div className="pt-5">
      <div className="flex">
        <div className="flex flex-none w-80">{previousStepButton()}</div>
        <div className="flex-1 items-center justify-center align-middle text-center">
          <Steps currentStep={currentStep} stepCount={props.steps} />
        </div>
        <div className="flex flex-none w-80 justify-end">
          {nextStepOrCompletionButton()}
        </div>
      </div>
    </div>
  );
};

ControlStepper.defaultProps = {};

export default ControlStepper;
