import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import ButtonPrimary from '../../../core/components/atoms/buttons/button-primary.component';
import ButtonSecondary from '../../../core/components/atoms/buttons/button-secondary.component';
import FormLabeledInput from '../../../core/components/molecules/forms/form-labeled-input.component';
import LayoutContainer from '../../../core/components/templates/layout-container.component';
import { useLayout } from '../../../core/context/layout.context';
import { Paths } from '../../../routes';
import UserRepository from '../../../user/service/api/user.repository';
import UserContract from '../../../user/service/contracts/user.contract';

export interface UserSettingsProps {
  currentUser: UserContract;
}

const UserSettings: React.FC<UserSettingsProps> = (props) => {
  const { handleError, handleSuccess } = useLayout();
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const [loadingChanges, setLoadingChanges] = useState(false);
  const [name, changeName] = useState<string>(props.currentUser.name);
  const [email, changeEmail] = useState<string>(props.currentUser.email);

  const handleUserSettings = async () => {
    handleError('');

    if (!name) return handleError('Enter your full name');
    if (!email) return handleError('Enter your email');

    setLoadingChanges(true);

    await UserRepository.edit(userId, { name, email })
      .then(() => {
        handleSuccess('Your changes have been saved');
      })
      .catch(() => {
        handleError('Failed to edit account settings');
      })
      .finally(() => {
        setLoadingChanges(false);
      });
  };

  const handleKeyDown = async (e: any) => {
    if (e.keyCode === 13 || e?.key === 'Enter') {
      await handleUserSettings();
    }
  };

  const renderContent = () => {
    return (
      <form>
        <div>
          <div>
            <div className="mt-8 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <div className="mt-1">
                  <FormLabeledInput
                    id={'user_fullname'}
                    type={'text'}
                    title={'Full name'}
                    value={name}
                    placeholder={'Your name'}
                    autoComplete={'off'}
                    required={true}
                    onChange={(value) => changeName(value)}
                    onKeyDown={async (e: any) => await handleKeyDown(e)}
                    loading={false}
                  />
                </div>
              </div>
              <div className="sm:col-span-6">
                <div>
                  <FormLabeledInput
                    id={'email'}
                    type={'email'}
                    title={'Email'}
                    value={email}
                    placeholder={'you@yourcompany.com'}
                    autoComplete={'off'}
                    required={true}
                    onChange={(value) => changeEmail(value)}
                    onKeyDown={async (e: any) => await handleKeyDown(e)}
                    disabled={true}
                    loading={false}
                  />
                  <p className="pl-2 pt-1 block text-xs font-normal text-gray-700">
                    The Email can&apos;t be changed at the moment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <ButtonSecondary
              label={'Cancel'}
              onClick={() => history.push(Paths.home)}
            />
            <ButtonPrimary
              label={'Save changes'}
              loading={loadingChanges}
              disabled={loadingChanges}
              onClick={() => handleUserSettings()}
              className={'ml-3'}
            />
          </div>
        </div>
      </form>
    );
  };

  return (
    <LayoutContainer title="Account settings" currentUser={props.currentUser}>
      {renderContent()}
    </LayoutContainer>
  );
};

UserSettings.defaultProps = {};
export default UserSettings;
